import sdk from '@/api-sdk'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { mpApp_$t } from '@/mpApp.js'

// Initial state
const coreState = {
  profile: {},
}

const getters = {
  position: state => {
    return state.profile.position
  },
}

const actions = {
  syncProfile(context) {
    sdk.instance
      .employee()
      .get()
      .then(res => {
        context.commit('setProfile', res)
      })
      .catch(e => {
        captureSentryError(e)
      })
  },
  postProfile(context, payload) {
    if (payload === null || typeof payload !== 'object') {
      return
    }

    return new Promise((resolve, reject) => {
      sdk.instance
        .employee(payload.partyId)
        .update(payload)
        .then(() => {
          context.commit('setProfile', payload)
          context.commit(
            'Notifications/notifyMessage',
            {
              title: mpApp_$t('general.success-updating', {
                thing: mpApp_$t('users.profile'),
              }),
              message: mpApp_$t('users.user-name', {
                firstName: payload.firstName,
                lastName: payload.lastName,
              }),
              type: 'success',
            },
            { root: true },
          )

          return resolve()
        })
        .catch(err => {
          captureSentryError(err)
          // TODO: Improve error processing
          context.commit(
            'Notifications/notifyMessage',
            {
              title: mpApp_$t('general.error-updating', {
                thing: mpApp_$t('users.profile'),
              }),
              message: err.data?.message || err || this.$t('general.unknown-error'),
              type: 'error',
            },
            { root: true },
          )
          return reject(err)
        })
    })
  },
  postPassword(context, payload) {
    if (payload === null || typeof payload !== 'object') {
      return
    }

    return new Promise((resolve, reject) => {
      sdk.instance
        .profile()
        .changePassword(payload)
        .then(() => {
          context.commit(
            'Notifications/notifyMessage',
            {
              title: mpApp_$t('general.success-updating', {
                thing: mpApp_$t('general.password'),
              }),
              message: mpApp_$t('general.updated-thing', {
                thing: mpApp_$t('general.password'),
              }),
              type: 'success',
            },
            { root: true },
          )

          return resolve()
        })
        .catch(err => {
          // TODO: Improve error processing
          context.commit(
            'Notifications/notifyMessage',
            {
              title: mpApp_$t('general.error-updating', {
                thing: mpApp_$t('general.password'),
              }),
              message: err.data?.message || err || this.$t('general.unknown-error'),
              type: 'error',
            },
            { root: true },
          )
          return reject(err)
        })
    })
  },
}

const mutations = {
  logout(state) {
    state.profile = {}
  },
  setProfile(state, payload) {
    state.profile = payload
  },
}

export default {
  namespaced: true,
  state: coreState,
  getters,
  actions,
  mutations,
}
