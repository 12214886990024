export default {
  productNames: {
    port: `${import.meta.env.VITE_EN_PRODUCTNAME_PORT}`,
    ports: `${import.meta.env.VITE_EN_PRODUCTNAME_PORTS}`,
    mcr: `${import.meta.env.VITE_EN_PRODUCTNAME_MCR}`,
    mcrs: `${import.meta.env.VITE_EN_PRODUCTNAME_MCRS}`,
    mcrLong: `${import.meta.env.VITE_EN_PRODUCTNAME_MCR_LONG}`,
    mve: `${import.meta.env.VITE_EN_PRODUCTNAME_MVE}`,
    mveLong: `${import.meta.env.VITE_EN_PRODUCTNAME_MVE_LONG}`,
    vxc: `${import.meta.env.VITE_EN_PRODUCTNAME_VXC}`,
    vxcs: `${import.meta.env.VITE_EN_PRODUCTNAME_VXCS}`,
    ix: `${import.meta.env.VITE_EN_PRODUCTNAME_IX}`,
    ixs: `${import.meta.env.VITE_EN_PRODUCTNAME_IXS}`,
    megaIx: `${import.meta.env.VITE_EN_PRODUCTNAME_MEGA_IX}`,
    lag: `${import.meta.env.VITE_EN_PRODUCTNAME_LAG}`,
    lags: `${import.meta.env.VITE_EN_PRODUCTNAME_LAGS}`,
    lagLong: `${import.meta.env.VITE_EN_PRODUCTNAME_LAG_LONG}`,
    marketplace: `${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE}`,
    marketplaceShort: `${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    transitVxc: `${import.meta.env.VITE_EN_PRODUCTNAME_TRANSIT_VXC}`,
    mves: `${import.meta.env.VITE_EN_PRODUCTNAME_MVES}`,
  },
  companyInfo: {
    name: `${import.meta.env.VITE_EN_COMPANYINFO_NAME}`,
    portalUrl: `${import.meta.env.VITE_EN_COMPANYINFO_PORTAL_URL}`,
    salesEmail: `${import.meta.env.VITE_EN_COMPANYINFO_SALES_EMAIL}`,
    arGlobalEmail: `${import.meta.env.VITE_EN_COMPANYINFO_AR_GLOBAL_EMAIL}`,
    portal: `${import.meta.env.VITE_EN_COMPANYINFO_PORTAL}`,
  },
  services: {
    'loading-services': `Loading Services...`,
    'diversity-zone': `Diversity Zone`,
    'create-diverse-pair': `Create Diverse Pair`,
    'red-zone': `Red Zone`,
    'blue-zone': `Blue Zone`,
    'invoice-reference': `Service Level Reference`,
    'invoice-reference-explanation': `Service Level reference will appear at an Invoice line level for the associated Product / Service`,
    'rate-limit': `Rate Limit`,
    'select-rate-limit': `Select Rate Limit`,
    'max-rate-limit': `Max Rate Limit`,
    'service-key': `Service Key`,
    'service-deleted': `This service has been removed from the data. Redirecting to services`,
    'configuration-details': `Configuration Details`,
    'service-logs': `Service Logs`,
    reloading: `The service information is being reloaded. Please wait a moment.`,
    'initial-bgp-state': `Initial BGP State`,
    saved: `Your configuration has been saved.`,
    'service-id': `Service ID`,
    'provisioning-status': `Provisioning Status`,
    'loading-status': `Loading service status...`,
    'unknown-status': `Unable to determine the status of this service`,
    media: `Media`,
    speed: `Speed`,
    allocated: `Allocated`,
    demarcation: `Demarcation`,
    up: `Service is up`,
    down: `Service is down`,
    'check-logs': `Check logs for more information`,
    'product-name': `Product name`,
    'rate-limit-fixed': `Note: Rate limit cannot be modified for this type of service after deployment.`,
    'fixed-rate': `(fixed rate)`,
    'service-status': `Service Status`,
    'service-information': `Service Information`,
    'loading-resources': `Loading resources. Please wait.`,
    'loading-issue': `There is an issue loading the data. Please contact support`,
    'identifier-copy': `Service Identifier - click to copy`,
    decommissioned: `This service is already decommissioned`,
    filter: `Filter Services`,
    'expand-collapse': `Expand or collapse all services`,
    'some-speeds-disabled': `Note: Some speeds may be disabled because a service attached is above that speed.`,
    'diversity-option': `Diversity Option`,
    'loading-state-info': `Loading updated service information.`,
    'waiting-on-deploy': `Service deployment in progress`,
    'no-company': `No available company.`,
    'cancelled-when': `Cancelled service will terminate on {when}`,
    'click-restore': `Click to restore the service`,
    'cancel-unknown': `Cancelled service will be terminated at an unknown date.`,
    'pending-internal-speed-approval': `Waiting for your approval on a speed change request from {where}`,
    'pending-internal-connection-approval': `Waiting for your approval on a connection from {where}`,
    'pending-external-connection-approval': `Waiting for external approval on a connection from {where}`,
    'pending-3rd-party-speed-approval': `Waiting for external approval on a speed change from {where}`,
    'pending-3rd-party-connection-approval': `Waiting for external approval on a connection from {where}`,
    'gci-waiting-configure': `Waiting for incoming BGP session.`,
    'waiting-for-resources': `This service is waiting to come up for the first time.`,
    'in-design-not-deployed': `Not deployed yet. Edit the details and complete deployment.`,
    'in-design-not-ordered': `Not ordered yet. Order using sidebar on the left.`,
    'approve-connections': `Approve Connections`,
    'approve-connections-list': `You have the following connections awaiting approval:`,
    'best-route': `Best Route`,
    'next-hop-vxc': `Next Hop ${import.meta.env.VITE_EN_PRODUCTNAME_VXC}`,
    bgp: `BGP`,
    'type-locked': `This {type} is locked`,
    'type-being-deployed': `This {type} is being deployed`,
    'type-unlock': `Click to unlock this {type} and allow editing`,
    'type-unlock-notlive': `This {type} is not live and cannot be unlocked`,
    'type-locked-admin': `This {type} is locked by an admin and is not editable`,
    'type-lock': `Click to lock this {type} and prevent editing and deletion`,
    'type-lock-notlive': `This {type} is not live and cannot be locked`,
    'type-unlocked-admin': `This {type} is unlocked and can only be locked by an admin`,
    'type-locked-megaport': `This {type} has been locked by ${import.meta.env.VITE_EN_COMPANYINFO_NAME} and is not editable. Contact ${import.meta.env.VITE_EN_COMPANYINFO_NAME} Support for more information.`,
    'type-cancelled': `This {type} has been cancelled`,
    'terminate-count': `N/A | Yes, Terminate 1 Service | Yes, Terminate {count} Services`,
    'service-locked': `Note: This service is locked by ${import.meta.env.VITE_EN_COMPANYINFO_NAME} and cannot be edited or deleted. Contact ${import.meta.env.VITE_EN_COMPANYINFO_NAME} Support for more information.`,
    'service-admin-locked': `Note: This service is locked and cannot be edited or deleted. Only a Company Admin can unlock the service.`,
    'edit-permission-denied': `Note: You do not have permission to edit this service.`,
    'ip-address': `IP Address`,
    'peer-ip-address': `Peer IP Address`,
    'creation-date': `Creation Date`,
    'replace-configured': `Replace Configured Services`,
    'replace-confirmation': `This will remove your currently configured items and replace them with this saved set. Continue?`,
    'delete-configuration': `Delete Saved Configuration`,
    // The name is the name of the port/mcr/mve and the productType is the type of port-like thing that was updated (Port, MCR, MVE)
    'port-like-updated-title': `{name} {productType} updated`,
    'port-like-updated-message': `Updates saved for {productType} '{name}'`,
    'click-setup': `Click to set up`,
    'new-mve-message': `Attention! Internet connectivity is mandatory for the ${import.meta.env.VITE_EN_PRODUCTNAME_MVE} image/software to be validated. You can choose to enable ${import.meta.env.VITE_EN_PRODUCTNAME_TRANSIT_VXC}, or connect your own Internet access.`,
    'connection-denied': `${import.meta.env.VITE_EN_PRODUCTNAME_VXC} to {destination} was denied`,
    'connection-approved': `${import.meta.env.VITE_EN_PRODUCTNAME_VXC} to {destination} was approved`,
    'amsix-contact-phone': `A phone number is required for the {contactType} for an AMS-${import.meta.env.VITE_EN_PRODUCTNAME_IX} service.`,
    'gbps-ix': `Gbps ${import.meta.env.VITE_EN_PRODUCTNAME_IX}`,
    'gbps-vxc': `Gbps ${import.meta.env.VITE_EN_PRODUCTNAME_VXC}`,
    'ibm-request-fail': `Unable to retrieve rate limits for this port, please try again.`,
    'type-state': `{type} State`,
    'shutdown-dialog-title': `Are you sure you want to shut down this service?`,
    'shutdown-dialog-message': `Traffic will not flow through this service if it is shut down. It will behave as if it was down on the ${import.meta.env.VITE_EN_COMPANYINFO_NAME} network.`,
    'shutdown-dialog-note': `Note: Billing for this service will remain active and you will still be charged for this connection.`,
    'sub-uid': `Sub-UID`,
    'type-move-notlive': `This {type} is not live and cannot be moved`,
    'port-move-notlive': `This {type} is not live and cannot have its connections moved`,
    'no-movable-type': `No movable connections on this {type}`,
    'move-permission-denied': `You don't have permission to move connections`,
    'cant-move-no-ports': `No other ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} in the same metro`,
    'cant-move-no-mcrs': `No other ${import.meta.env.VITE_EN_PRODUCTNAME_MCRS} in the same metro`,
    'cant-move-no-mves': `No other ${import.meta.env.VITE_EN_PRODUCTNAME_MVES} in the same metro`,
    'awaiting-approval': `Awaiting approval or rejection of pending changes`,
    'shutdown-confirm-message': `To shut down, please click 'Confirm' and then 'Save'.`,
    'cant-move-multiple-vnics': `Cannot move connections from an ${import.meta.env.VITE_EN_PRODUCTNAME_MVE} with multiple vNICs`,
    'view-additional-filters': `View Additional Filters`,
    'clear-filters': `Clear Filters`,
    'service-type': `Service Type`,
    'contract-term': `Contract Term`,
    'change-sort-direction': `Change Sorting Direction`,
    'matching-services': `0 matching services ({total} total) | 1 matching service ({total} total) | {count} matching services ({total} total)`,
    'no-matching': `No matching services found.`,
    'diversity-location-tooltip': `Filter locations by available Diversity Zones. If left on 'Auto', we will select a Diversity Zone for you. For more information, refer to our {link}`,
    'diversity-config-tooltip': `Diversity Zone this service will be allocated to.`,
    'auto-zone': `Auto Zone`,
    'download-inventory-report': `Download Inventory Report`,
    'expand-all': `Expand All Services`,
    'collapse-all': `Collapse All Services`,
    'extra-actions': `Extra Actions`,
    'type-status': `Type & Status`,
    'has-ongoing-maintenance': `Ongoing maintenance event.`,
    'has-ongoing-outage': `Ongoing service outage event.`,
  },
  app: {
    'environment-unrecognized': `Environment not recognized`,
    'unrecognized-url': `Access from ${import.meta.env.VITE_EN_COMPANYINFO_PORTAL_URL} or a recognized development URL`,
  },
  general: {
    'delete-type': `Delete {type}`,
    'edit-type': `Edit {type}`,
    'remove-type': `Remove {type}`,
    'new-type': `New {type}`,
    'add-type': `Add {type}`,
    'update-type': `Update {type}`,
    'type-details': `{type} Details`,
    'type-configuration': `{product} Configuration`,
    'edit-configured-type': `Edit Configured {type}`,
    'select-type-location': `Select {type} Location`,
    'delete-permission-denied': `You don't have permission to delete this {type}`,
    'type-not-found': `{type} not found`,
    'type-not-found-redirect': `This {type} was not found in the data. Redirecting to services`,
    'success-count': `0 successes | 1 success | {count} successes`,
    'warning-count': `0 warnings | 1 warning | {count} warnings`,
    'error-count': `0 errors | 1 error | {count} errors`,
    'count-months': `0 Months | 1 Month | {count} Months`,
    'locations-count': `0 Locations | 1 Location | {count} Locations`,
    'last-log': `Last log received {last}`,
    'max-rate-limit': `MAX: {maxRateLimit} Mbps`,
    'speed-gbps': `{speed} Gbps`,
    'missing-billing-market': `Missing billing market "{country}".`,
    'create-thing': `Create {thing}`,
    marketplace: `${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE}`,
    welcome: `Welcome to ${import.meta.env.VITE_EN_COMPANYINFO_NAME}`,
    save: `Save`,
    revert: `Revert`,
    close: `Close`,
    cancel: `Cancel`,
    back: `Back`,
    next: `Next`,
    done: `Done`,
    loading: `Loading...`,
    services: `Services`,
    service: `Service`,
    price: `Price`,
    summary: `Summary`,
    copy: `Copy`,
    'copy-to-clipboard': `Copy to Clipboard`,
    'characters-copied': `0 characters copied | 1 character copied | {count} characters copied`,
    version: `Version`,
    'first-name': `First Name`,
    'last-name': `Last Name`,
    phone: `Phone`,
    email: `Email`,
    password: `Password`,
    ok: `OK`,
    'details-help': `For details, see this help topic:`,
    documentation: `Documentation`,
    saving: `Saving...`,
    public: `Public`,
    private: `Private`,
    unspecified: `Not Specified`,
    'x-end': `{end}-End`,
    hide: `hide`,
    show: `show`,
    'technical-contact': `Technical Contact`,
    'noc-contact': `NOC contact`,
    'contact-phone': `contact phone number`,
    'select-type': `Select Type`,
    size: `Size`,
    'step-unavailable': `This option is not available until you have completed the previous steps.`,
    'not-available': `Not available`,
    'configure-thing': `Configure {thing}`,
    'modification-delay': `Note: Applied modifications may take up to 2 minutes to appear.`,
    billing: `Billing`,
    'processing-approval': `Processing approval...`,
    'processing-denial': `Processing request denial...`,
    unknown: `Unknown`,
    yes: `Yes`,
    no: `No`,
    untitled: `Untitled`,
    'no-location': `No Location Selected`,
    'select-location': `Select Location`,
    'unknown-location': `Unknown Location`,
    configure: `Configure`,
    'no-matching-countries': `No matching countries`,
    'no-matching-locations': `No matching locations`,
    'country-filter': `Country Filter`,
    search: `Search`,
    'pre-order': `Pre-order`,
    details: `Details`,
    name: `Name`,
    success: `Success`,
    disabled: `Disabled`,
    enabled: `Enabled`,
    'shut-down': `Shut Down`,
    term: `Term`,
    red: `Red`,
    blue: `Blue`,
    none: `None`,
    new: `New`,
    general: `General`,
    status: `Status`,
    'status-unknown': `Status is unknown`,
    'created-by': `Created By`,
    'privacy-policy': `Privacy Policy`,
    'site-terms': `Site Terms`,
    gsa: `Global Services Agreement`,
    'acceptable-use': `Acceptable Use Policy`,
    'please-select': `Select`,
    'important-information': `Important Information`,
    'agree-terms': `I agree to the terms and conditions`,
    'view-details': `View Details`,
    'action-confirmation': `Are you sure you want to do this?`,
    'unknown-error': `Unknown error`,
    edit: `Edit`,
    remove: `Remove`,
    'request-failed': `Request Failed`,
    'company-name': `Company Name`,
    active: `Active`,
    inactive: `Inactive`,
    na: `N/A`,
    disable: `Disable`,
    pricing: `Pricing`,
    note: `Note`,
    website: `Website`,
    'click-to-visit': `Click to visit`,
    all: `All`,
    ipv4: `IPv4`,
    ipv6: `IPv6`,
    you: `you`,
    'your-company-admin': `your company admin`,
    date: `Date`,
    mbps: `Mbps`,
    start: `Start`,
    end: `End`,
    duration: `Duration`,
    'loading-details': `Loading details...`,
    'details-load-failed': `Failed to load details`,
    prefix: `Prefix`,
    'next-hop': `Next Hop`,
    'load-failed': `Load Failed`,
    to: `To`,
    from: `From`,
    message: `Message`,
    send: `Send`,
    sort: `Sort`,
    'hide-details': `Click to hide details`,
    'show-details': `Click to show details`,
    'logs-label': `Logs`,
    'usage-label': `Usage`,
    'configuration-label': `Configuration`,
    actions: `Actions`,
    confirm: `Confirm`,
    delete: `Delete`,
    'reload-data': `Reload Data`,
    network: `Network`,
    location: `Location`,
    selection: `Selection`,
    'choose-file': `Choose file`,
    label: `Label`,
    'single-use': `Single Use`,
    'multi-use': `Multi-Use`,
    add: `Add`,
    'select-date': `Select Date`,
    'loading-data': `Loading data`,
    description: `Description`,
    'click-copy': `Click to copy`,
    token: `Token`,
    notice: `Notice`,
    'end-date': `End Date`,
    existing: `existing`,
    'no-info': `No further information available`,
    'error-updating': `Error updating {thing}.`,
    'click-enable': `Click to enable`,
    'updating-details': `Updating details...`,
    approve: `Approve`,
    view: `View`,
    'save-as': `Save As`,
    order: `Order`,
    'show-actions': `Show actions`,
    'hide-actions': `Hide actions`,
    'items-per-page': `{count} items per page`,
    'show-all': `Show all items`,
    origin: `Origin`,
    communities: `Communities`,
    updated: `Updated`,
    'all-types': `All Types`,
    static: `Static`,
    connected: `Connected`,
    local: `Local`,
    file: `File`,
    clear: `Clear`,
    'no-match-found': `No match found`,
    'list-not-available': `No list available`,
    'list-select': `Select a list`,
    'password-toggle': `Toggle to display password`,
    'exact-label': `Exact`,
    'position-label': `Position`,
    'min-label': `Min`,
    'max-label': `Max`,
    'operations-label': `Operations`,
    'drag-position-label': `Click and drag to position`,
    'example-abbr': `E.g.`,
    star: `star`,
    'standard-label': `Standard`,
    'advanced-label': `Advanced`,
    'filters-label': `Filters`,
    'on-label': `On`,
    'off-label': `Off`,
    'default-label': `Default`,
    'deny-label': `Deny`,
    'permit-label': `Permit`,
    'required-field-label': `Required field`,
    'warning-label': `Warning`,
    'type-name': `{type} Name`,
    'save-error': `Could not save your changes: `,
    'copy-thing-to-clipboard': `Copy {thing} to Clipboard`,
    'error-creating': `Error creating {thing}.`,
    'error-deleting': `Error deleting {thing}.`,
    'success-creating': `{thing} created successfully.`,
    'success-updating': `{thing} updated successfully.`,
    'success-deleting': `{thing} deleted successfully.`,
    'created-thing': `Successfully created {thing}`,
    'updated-thing': `Successfully updated {thing}`,
    'word-counter': `{count} words of {maxWords}`,
    user: `User`,
    load: `Load`,
    update: `Update`,
    'server-error': `Error communicating with API server`,
    'context-switching-title': `Are you sure you want to switch accounts?`,
    'context-switching-message': `You are about to change from your {accountTypeFrom} <strong>{fromCompany}</strong> to {accountTypeTo} <strong>{toCompany}</strong>`,
    'feature-flag-access-error': `The {pageName} cannot be accessed from a Managed Account. Select your Partner Account to access the {pageName}.`,
    'open-sidebar': `Open sidebar`,
    'learn-megaport': `Learn more about ${import.meta.env.VITE_EN_COMPANYINFO_NAME} services or visit the ${import.meta.env.VITE_EN_COMPANYINFO_CUSTOMER_PORTAL}.`,
    'visit-documentation': `Go to the ${import.meta.env.VITE_EN_COMPANYINFO_DOCUMENTATION_PORTAL}`,
    'visit-portal': `Visit the ${import.meta.env.VITE_EN_COMPANYINFO_PORTAL}`,
    'login-trouble': `Having trouble with log in? {link}`,
    'site-disclaimer': `By accessing the ${import.meta.env.VITE_EN_COMPANYINFO_PORTAL}/landing page you are bound by the ${import.meta.env.VITE_EN_COMPANYINFO_NAME} Privacy Policy, Site Terms and Acceptable Use Policy.`,
    'partner-deals': `Partner Deals`,
    'cancel-reterm': `Click Cancel if you wish to take no action and roll over to no contract term at the list price at the end of the contract term.`,
    'deleted-thing': `Successfully deleted {thing}`,
    'unexpected-error': `An unexpected error has occurred. Try again later. If this error persists contact ${import.meta.env.VITE_EN_COMPANYINFO_SUPPORT_EMAIL}`,
    'unsaved-changes-confirm-message': `All unsaved changes will be lost`,
    'unsaved-changes-confirm-title': `Leave page without saving?`,
    'unsaved-changes-confirm-cancel': `Stay on page`,
    'unsaved-changes-confirm-ok': `Leave page`,
    'not-now': `Not now`,
    up: `Up`,
    down: `Down`,
    beta: `Beta`,
    languages: `Languages`,
    expand: `Expand`,
    collapse: `Collapse`,
    here: `here`,
    true: `true`,
    false: `false`,
    'get-help': `Get Help`,
    'type-shutdown-state': `Note: This {type} is in shut down state`,
    continue: `Continue`,
    accept: `Accept`,
    decline: `Decline`,
    'terms-and-conditions': `Terms and Conditions`,
    'lag-count': `0 ${import.meta.env.VITE_EN_PRODUCTNAME_LAGS} | 1 ${import.meta.env.VITE_EN_PRODUCTNAME_LAG} | {count} ${import.meta.env.VITE_EN_PRODUCTNAME_LAGS}`,
    'mcr-count': `0 ${import.meta.env.VITE_EN_PRODUCTNAME_MCRS} | 1 ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} | {count} ${import.meta.env.VITE_EN_PRODUCTNAME_MCRS}`,
    'mve-count': `0 ${import.meta.env.VITE_EN_PRODUCTNAME_MVES} | 1 ${import.meta.env.VITE_EN_PRODUCTNAME_MVE} | {count} ${import.meta.env.VITE_EN_PRODUCTNAME_MVES}`,
    connection: `Connection`,
    connections: `Connections`,
    'move-to': `Move To`,
    'not-submitted': `Not Submitted`,
    processing: `Processing`,
    failure: `Failure`,
    move: `Move`,
    'select-all': `Select All`,
    'deselect-all': `Deselect All`,
    'non-prod': `This is a non-production environment`,
    auto: `Auto`,
    'preparing-download': `Preparing download...`,
    'download-failed-title': `Download failed`,
    'download-failed': `File could not be downloaded, please try again later.`,
    mobile: `Mobile`,
    activate: `Activate`,
    deactivate: `Deactivate`,
    valid: `Valid`,
    invalid: `Invalid`,
    type: `Type`,
    expired: `Expired`,
    comments: `Comments`,
    refresh: `Refresh`,
    'pre-release': `Pre-Release`,
    'logs-count': `1 log entry received in the last 24 hours | {count} log entries received in the last 24 hours`,
    'most-recent-n-events': `{count} most recent events shown`,
    'no-logs': `No logs for this service`,
  },
  tour: {
    'start-tour': `Start Tour`,
    'find-location': `Find a Location`,
    'location-selected': `Location Selected`,
    'add-a-service': `Add a Service`,
    'actions-panel': `Actions Panel`,
    'live-chat': `24/7 Live Chat`,
    'next-steps': `Next Steps`,
    start: `This is where you can build, deploy, and manage services such as ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS}, ${import.meta.env.VITE_EN_PRODUCTNAME_MCRS}, ${import.meta.env.VITE_EN_PRODUCTNAME_VXCS}, and ${import.meta.env.VITE_EN_PRODUCTNAME_IXS}.<br/><br/>Click Next to continue.`,
    'intro-port': `A ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} is your connection to the ${import.meta.env.VITE_EN_COMPANYINFO_NAME} infrastructure and gateway to a multitude of Cloud, Internet Exchange, and other endpoints.<br/><br/>Click Next to create a ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}.`,
    'select-port': `Your first step is selecting your ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}’s location.<br/><br/>Click Next to select a location.`,
    'location-selected-content': `With a location selected, configure the ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}.<br/><br/>Click Next to configure the ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}.`,
    'port-configuration': `${import.meta.env.VITE_EN_PRODUCTNAME_PORT} Configuration`,
    'configure-port': `Configure your ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} by entering the required information.<br/><br/>Click Next to add information for the ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}.`,
    price: `With your ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} information provided, the price displays.<br/><br/>Click Next to go to the next screen.`,
    summary: `This is the final step to configuring your ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}. You won't be billed until you order it.<br/><br/>Click Next to add the ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}.`,
    'port-configured': `${import.meta.env.VITE_EN_PRODUCTNAME_PORT} Configured`,
    'configured-port': `Your configured ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} will appear on the Services page where you can edit the configuration by clicking the ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} or the gear icon on the right.<br/><br/>Click Next to go to the next screen.`,
    'add-service': `The next step is adding a service to your newly configured ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}. Click the +Connection icon on the right of the ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} or choose from below.`,
    'actions-panel-content': `During this demo, your configured ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} appears here along with any other actions required to get up and running.<br/><br/>If you have any pending actions to complete, they appear in the sidebar.`,
    chat: `Need help? Click here to start a real time conversation with a ${import.meta.env.VITE_EN_COMPANYINFO_NAME} specialist.`,
    'next-steps-no-kb': `You made it to the end of the tour!<br/><br/>Now, you're ready to visit the Services page and create your first ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}.`,
    'next-steps-kb-html': `You made it to the end of the tour!<br/><br/>Don't forget to visit our <a href="https://docs.megaport.com/" target="_blank">Documentation</a> or <a href="https://www.youtube.com/channel/UCdVx98wdrhcTCIuw8UE5hIA" target="_blank">YouTube channel</a> for further help.<br/><br/>Now, you're ready to visit the Services page and create your first ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}.`,
    'next-steps-partial-html': `You made it to the end of the tour!<br/><br/>Don't forget to visit our <a href="https://docs.megaport.com/" target="_blank">Documentation</a> or <a href="https://www.youtube.com/channel/UCdVx98wdrhcTCIuw8UE5hIA" target="_blank">YouTube channel</a> for further help.<br/><br/>Now, you're ready to create your first ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}.`,
    exit: `Exit Tour`,
  },
  about: {
    why: `Why ${import.meta.env.VITE_EN_COMPANYINFO_NAME}?`,
    provisioning: `Provision connections in 59 seconds on the ${import.meta.env.VITE_EN_COMPANYINFO_NAME} Network.`,
    scalable: `Scalable Connectivity`,
    'scalable-content': `Increase and decrease your bandwidth at any time to suit your business demands.`,
    'real-time': `Real-Time Provisioning`,
    api: `Powerful API Integration`,
    'api-content': `Manage your connectivity autonomously and optimise your workflow by utilising our powerful API.`,
    'about-megaport': `About ${import.meta.env.VITE_EN_COMPANYINFO_NAME}`,
    'about-megaport-video': `About ${import.meta.env.VITE_EN_COMPANYINFO_NAME} video`,
    'megaport-features': `${import.meta.env.VITE_EN_COMPANYINFO_NAME} features`,
  },
  authentication: {
    'about-company': `About ${import.meta.env.VITE_EN_COMPANYINFO_NAME}`,
    'password-reset': `Your account requires a password reset. Set a new password here to login.`,
    'setting-up': `Setting up your account...`,
    'password-strength': `Password Strength`,
    'very-weak': `Very Weak`,
    weak: `Weak`,
    moderate: `Moderate`,
    good: `Good`,
    excellent: `Excellent`,
    'good-password': `This is a good password`,
    'excellent-password': `This is an excellent password`,
    agreement: `Agreement`,
    'agree-to': `Agree to `,
    'create-account': `Create Account`,
    google: `Sign in with Google`,
    'have-account': `Already have an account?`,
    'log-in': `Log In`,
    'one-time-token': `One Time Token`,
    'new-password': `Your New Password`,
    'target-username': `Target Username`,
    forgot: `Forgot Password`,
    register: `Register Now`,
    'processing-reset': `Processing reset request...`,
    'send-reset': `Send Reset`,
    resetting: `Resetting Password...`,
    reset: `Reset Password`,
    'trying-login': `Trying to log in...`,
    'please-login': `Log In`,
    'failed-login': `Failed to log you in with your existing credentials. Log in again.`,
    'password-reset-title': `Password Reset`,
    'missing-reset-token': `Missing password reset token. Go to the Login page to send a new Forgot Password request.`,
    'logging-in': `Logging in...`,
    'need-to-reset': `You will need to reset your password. The form has been updated with the required field.`,
    failed: `Login Failed`,
    'error-registering': `Error Registering`,
    'unknown-error-registering': `Unknown error when attempting to register`,
    'reset-email-sent': `An email has been sent to you to reset your password.`,
    'reset-request-failed': `Password Reset - Request Failed`,
    'unknown-error-reset': `Unknown error when attempting to reset password`,
    'password-change-successful': `Password has been changed successfully, you can now log in with your new password.`,
    'password-reset-failed': `Failed to reset password. To reset again, go to the Login page and send a new Forgot Password request.`,
    'auth-failed': `Authentication Failed`,
    'auth-failed-message': `Failed to authenticate your account`,
    'confirm-email-label': `Confirming Email Address`,
    confirming: `Confirming. Please wait`,
    success: `Your email has been confirmed`,
    'failed-confirm': `Failed to confirm your email address. Try again later.`,
    'return-portal': `Return to Portal`,
    'registered-company': `Registered company: {name}`,
    'updated-company': `Updated company {name}`,
    'login-success-title': `Successful Login!`,
    'login-success-message': `{name} has logged in`,
    'login-failed-token': `Failed to log you in with your existing token. Please log in again.`,
    'use-security-code': `Use security code`,
    'temp-password': `Your temporary Password`,
    'confirm-password': `Confirm Password`,
    'login-error': `Login Error`,
    'login-error-msg': `We are unable to log in using your credentials. Having trouble with login? <a href="https://www.megaport.com/contact/support/" target="_blank">Get Help</a>`,
    submit: `Submit`,
    'vendor-onboarding': {
      cisco: {
        'intro-header': `Welcome to Cisco SD-WAN Cloud Interconnect with ${import.meta.env.VITE_EN_COMPANYINFO_NAME}`,
        'intro-content': `We are so glad to see you here and hope you will have a great experience using the product. You are just a few steps away from being able to create dedicated connections to your cloud environments through vManage, and take advantage of Cisco SD-WAN overlayed on top of ${import.meta.env.VITE_EN_COMPANYINFO_NAME}’s highly available underlay network.`,
        'temp-password-info': `<b>Enter your email and temporary password.</b> After changing your password you can enter your credentials into Cisco vManage version 20.5 or later to place orders for MVE, to create VXCs from MVEs to other destinations on the Megaport network, and to delete the same, instead of using the ${import.meta.env.VITE_EN_COMPANYINFO_CUSTOMER_PORTAL}.`,
        'new-password-info': `<b>Enter your new password.</b> After changing your password you can enter your credentials into Cisco vManage version 20.5 or later to place orders for MVE, to create VXCs from MVEs to other destinations on the Megaport network, and to delete the same, instead of using the ${import.meta.env.VITE_EN_COMPANYINFO_CUSTOMER_PORTAL}.`,
        'password-changed-info': `What next? Enter these credentials into Cisco vManage version 20.5 or later to provision ${import.meta.env.VITE_EN_PRODUCTNAME_MVES}, or to create ${import.meta.env.VITE_EN_PRODUCTNAME_VXCS} between ${import.meta.env.VITE_EN_PRODUCTNAME_MVES} and cloud service providers.`,
        'temp-password-intro': `Enter your email and temporary password. `,
        'new-password-intro': `Enter your new password. `,
        'password-changed-intro': `Your password was changed! `,
        'password-info': `After changing your password you can enter your credentials into Cisco vManage version 20.5 or later to place orders for ${import.meta.env.VITE_EN_PRODUCTNAME_MVE}, to create ${import.meta.env.VITE_EN_PRODUCTNAME_VXCS} from ${import.meta.env.VITE_EN_PRODUCTNAME_MVES} to other destinations on the ${import.meta.env.VITE_EN_COMPANYINFO_NAME} network, and to delete the same, instead of using the ${import.meta.env.VITE_EN_COMPANYINFO_CUSTOMER_PORTAL}.`,
      },
    },
    'verify-email-heading': `Please check your email`,
    'verify-email-subheading': `We've sent you a link to confirm your account`,
    'verify-email-description': `Haven't received an email? Please check your spam folder to make sure it's not in there.`,
    'verify-email-button': `Resend Email`,
    'verify-email-resent-successful': `Confirmation email resent successfully.`,
    'verify-email-resent-unsuccessful': `Something went wrong with resending your confirmation email.`,
    'password-must-include': `Your password must`,
    'at-least-8-char': `Be at least 8 characters`,
    'at-least-one-number': `Have at least one number`,
    'at-least-one-symbol': `Have at least one symbol`,
    'at-least-one-lower-case-letter': `Have at least one lower case letter`,
    'at-least-one-upper-case-letter': `Have at least one upper case letter`,
    'create-an-account': `Create an Account`,
    or: `Or`,
    'signup-email': `Sign up with Email`,
    'signup-google': `Sign up with Google`,
    'login-to-your-account': `Login to your Account`,
    'signup-success': `Signup was successful!`,
    'signup-failed': `Signup failed, please try again.`,
    login: `Login`,
    'login-google': `Login with Google`,
    'reset-message': `If this account exists, an email has been sent to reset your password.`,
    'signup-google-instructions': `Please enter a company trading name and review the terms below to continue.`,
    signup: `Sign up`,
    'create-account-email': `Create Account with email`,
    'confirm-new-password': `Confirm New Password`,
    'new-password-no-your': `New Password`,
    'reset-expired-dialog-title': `The reset link used is no longer valid.`,
    'reset-expired-dialog-desc': `The link used in the e-mail has expired or is not valid. Click "Resend Link" or contact ${import.meta.env.VITE_EN_COMPANYINFO_SUPPORT_EMAIL}`,
    'resend-link': `Resend Link`,
    'unexpected-error-dialog-title': `An unexpected error has occurred. Try again later.`,
    'unexpected-error-dialog-desc': `If this error persists contact ${import.meta.env.VITE_EN_COMPANYINFO_SUPPORT_EMAIL}`,
    'login-megaport': `Log in to ${import.meta.env.VITE_EN_COMPANYINFO_NAME}`,
    'email-verified': `Your email has been verified`,
    'invalid-link': `This link is no longer valid`,
    'request-link': `Click the button to receive a new link to your registered email.`,
    'verified-account': `This account is already verified`,
    'verified-email': `Your email has already been verified`,
    'continue-to-megaport': `Continue to ${import.meta.env.VITE_EN_COMPANYINFO_PORTAL}`,
    'change-email-desc': `Enter your new email address and click Get Verification Code. A verification code will be sent to your new email address to enter below. Do not close this window.`,
    'change-email-warning': `Please note you will need to login again after changing your email.`,
    'verification-code': `Verification Code`,
    'get-verification-code': `Get Verification Code`,
    'verify-email-taken': `This email is already being used by another user.`,
    'verification-code-expired': `Verification code has expired, please request a new verification email`,
    'verification-code-sent': `We've sent you a unique verification code`,
    'verification-code-failed': `Something went wrong when retrieving a verification code`,
    'verification-code-wrong': `The verification code is wrong`,
    'setup-mfa-cancel-confirm': `Cancelling will return you to the login screen. You will not be able to login until MFA is set-up.`,
    'setup-mfa-reset': `Your Company Admin has reset and disabled your Multi-Factor Authentication (MFA) code. Please set up MFA again to log in.`,
    'setup-mfa-enforced': `Your Company Admin has enforced Multi-Factor Authentication (MFA). You will be unable to login until this has been set up.`,
    'enabling-mfa': `Enabling MFA`,
    'mfa-token-invalid': `One time code {code} is invalid. After 5 consecutive unsuccessful attempts, your account will be locked.`,
    'mfa-long': `Multi-Factor Authentication`,
    'mfa-enter-token': `MFA is enabled for your account. Please enter a token to log in.`,
    verify: `Verify`,
    'mfa-verify-token': `Verifying token`,
    'mfa-instructions': `Scan the image above with the multi-factor authentication app on your phone. If you can’t use a barcode, enter ~ this text code ~ in the app instead.`,
    'enable-mfa-failed': `Failed to enable multi-factor authentication`,
    'mfa-token-required': `One time multi-factor authentication token required. The form has been updated with the required field.`,
    'login-cognito-unexpected-failure': `An unexpected error occurred during authentication. Please try again, or contact your organisation for assistance.`,
    'login-sso-assistance': `If you require assistance {docs} our support team.`,
    contact: `contact`,
    'docs-portal': `Docs Portal`,
    'create-one': `create one`,
    'login-sso-help-reset-password': `If you are having issues logging in with your email and password, try resetting your password using the "Forgot Password" link below.`,
    'login-sso-help-idp-config': `If you have Single Sign-On (SSO) enabled but can't log in, there might be an IdP configuration error or your IdP is not responding. You might be able to log in directly using your ${import.meta.env.VITE_EN_COMPANYINFO_NAME} username and password if you are a Company Admin or if SSO is set to Optional.`,
    'login-help': `Get help signing in`,
    'login-temp-password-expired': `Your Temporary Password has expired, please contact our support team at ${import.meta.env.VITE_EN_COMPANYINFO_SUPPORT_EMAIL} to arrange a Temporary Password reset.`,
    continue: `Continue`,
    'create-new-account': `Create a new account`,
    'join-company-account': `Join your company account`,
    'error-resetting-password': `Error resetting password`,
    'reset-password-sent-if-confirmed': `If that email address is in our system, and the account has previously been confirmed, we will send you an email to reset your password.`,
    'sso-first-login': `Your Company Administrator has enabled SSO on your account. You will now be redirected to your company's Identity Provider to authenticate.`,
    'sso-enforced-no-access': `A Company Administrator has enforced Single Sign-On and you must only login via SSO. Please contact your Company Administrator if you need assistance with SSO access.`,
    'sso-email-mapping-error': `There is an issue with the email attribute mapping of your SSO configuration. To resolve this, we recommend reaching out to your Company Administrator and ask them to review the email attribute mapping. For help correcting this issue, please refer to our SSO FAQ page in the <a href="https://docs.megaport.com/troubleshooting/sso-faq/" target="_blank">document portal</a>`,
    'email-query-missing': `Email query parameter is missing`,
    'email-not-sso': `Email is not configured for SSO`,
  },
  validations: {
    required: `{thing} is required`,
    failed: `Validation Failed`,
    'correct-issues': `Correct the indicated issues.`,
    'ix-selection-required': `${import.meta.env.VITE_EN_PRODUCTNAME_IX} selection is required`,
    'asn-validation': `${import.meta.env.VITE_EN_PRODUCTNAME_MCR} ASN must be between 2 and {maxAsn} (default value is {defaultAsn})`,
    'asn-validation-plain': `ASN must be between {minAsn} and {maxAsn}`,
    'asn-validation-range': `ASN range {minAsn}-{maxAsn} or {minAsn2}-{maxAsn2}`,
    'mbps-value-range': `Enter a value between {min} and {max} Mbps`,
    'value-too-high': `Greater than maximum allowed. Enter a value less than or equal to {max}`,
    asn: `ASNs start with "AS" or "ASN" or are numeric`,
    'min-length': `Minimum length 0 characters | Minimum length 1 character | Minimum length {min} characters`,
    'max-length': `Maximum length 0 characters | Maximum length 1 character | Maximum length {max} characters`,
    'min-max-length': `Must be {min} to {max} characters`,
    'weak-password': `Your password is not strong enough`,
    'invalid-mac': `Enter a valid MAC address`,
    'terms-conditions': `You need to agree to the terms and conditions`,
    'new-password': `Enter your new password`,
    'password-different': `New password must be different from the old one`,
    'email-invalid': `Enter a valid email address`,
    'key-invalid': `Key failed validation`,
    'service-key-invalid': `Enter a valid service key`,
    'use-service-key': `You must use a service key to create a connection`,
    'nutanix-key-format': `Format: optional three characters and hyphen, then UUID`,
    'azure-key-format': `Azure service keys are 32-36 characters`,
    'invalid-service-key': `Invalid service key`,
    'destination-type': `Select the destination type`,
    'aws-connection-type': `You must select the AWS connection type`,
    'destination-provider': `Select the destination provider`,
    'connection-name': `Enter a name for the connection`,
    'connection-name-min': `The name must be one or more words starting with a letter`,
    'select-peering': `Select whether to peer with route servers`,
    'please-select': `Please select the {thing}`,
    'please-enter': `Enter the {thing}`,
    'phone-format': `International format e.g. +61 4321 1234`,
    'aws-account': `AWS accounts are 12 digit numbers`,
    'ip-cidr-format': `Invalid IP address format, for example 192.0.1.0/30`,
    'ip-list': `IP list, for example 191.0.2.0/24,191.0.2.0/30`,
    'select-peer-type': `Select at least one peer type`,
    'pairing-key-format-invalid': `Pairing keys must match the Google specification.`,
    'virtual-circuit-format-invalid': `Enter a valid virtual circuit ID`,
    'pairing-key-invalid': `Invalid pairing key`,
    'virtual-circuit-invalid': `Invalid virtual circuit ID`,
    'google-pairing-key': `To complete an order of a Google connection, enter a valid pairing key.`,
    'invalid-key': `Invalid Key`,
    'enter-valid-key': `Enter a valid key`,
    'vlan-required': `VLAN value is required`,
    'rate-limit': `The rate limit can not be above the indicated maximum.`,
    'select-location': `You must select a location`,
    'enter-name': `Enter a name`,
    'select-rate-limit': `Select the rate limit`,
    ge: `Enter a value greater than or equal to {value}`,
    'mve-final-check': `Final check for ${import.meta.env.VITE_EN_PRODUCTNAME_MVE} VLAN will be performed during deployment`,
    'vlan-unavailable': `VLAN unavailable`,
    'vlan-available': `VLAN is available`,
    'vlan-auto-assign': `Leaving the VLAN blank results in a randomly assigned VLAN`,
    'vlan-no-untagged': `This VLAN can not be set to untagged`,
    'vlan-untagged-warning': `Untagging a VLAN limits the ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} to a single connection`,
    'vlan-range': `VLAN values range from {minVlan} to {maxVlan}`,
    'vlan-in-use': `There is already a designed connection on this ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} using this VLAN`,
    'agree-terms': `You must agree to the terms`,
    'insufficient-privileges': `Insufficient privileges`,
    'bio-too-long': `Too many words in the company bio`,
    'url-format': `Enter a valid {site} URL`,
    'valid-company': `Valid company name is required`,
    'description-too-long': `Too many words in the description`,
    'ip-in-list': `This IP is already in the list`,
    'invalid-network': `The address is not a valid IPv4, IPv6, or network address`,
    'not-valid-network': `The address is not a valid network address`,
    'no-subnet': `IP address must contain a subnet mask`,
    'no-file-selected': `No file selected`,
    'wrong-file-type': `Wrong file type`,
    'select-text-file': `Select a text file to upload`,
    'file-too-long': `File Too Long`,
    'file-max-length': `Encoded file length must be less than {max}kb long.`,
    'file-read-error': `Error Reading File`,
    'new-password-retype': `Retype your new password`,
    'new-password-different': `New password and retyped password must match`,
    'fill-details': `Complete these details`,
    'characters-not-allowed': `The following characters are not allowed: {chars}`,
    'minimum-payment': `Minimum payment amount is {value}`,
    'payment-description-none': `Payment description must have at least one alphabetic character`,
    'payment-enter-amount': `Enter a valid amount`,
    'type-used': `{thing} already in use`,
    'cannot-validate-type': `Can't validate value of type: {thing}`,
    'ip-family-required': `You must provide IP address family value`,
    'ip-address-used': `This address is already in use`,
    'ip-address-subnet-required': `IP address must contain a subnet mask`,
    'ip-address-subnet-not-required': `Subnet mask is not required`,
    'ip-address-overlap': `Overlapping IPs are not allowed on the interface`,
    'network-broadcast-exception': `Address is a broadcast address; a host address is expected`,
    'network-address-exception': `Address is a network address; a host address is expected`,
    'local-peer-ip-same-network': `Local IP address and Peer IP address are not in the same IP network`,
    'peer-network-address': `Peer IP address cannot be the network address`,
    'peer-broadcast-address': `Peer IP address cannot be the broadcast address`,
    'peer-same-local-address': `Peer IP address cannot be the same as the Local IP address`,
    'ipv4-mismatch': `IPv6 addresses cannot be added to IPv4 filter list`,
    'ipv6-mismatch': `IPv4 addresses cannot be added to IPv6 filter list`,
    'number-required': `Value should be a number`,
    'range-min': `Range should start from {min}`,
    'range-max': `Range should end at {max}`,
    'range-min-exceed-max': `Min value cannot be greater than Max`,
    'range-max-exceed-min': `Max value cannot be less than Min`,
    'range-min-exceeds-cidr': `Min value cannot be less than CIDR`,
    'thing-between-mix-max': `{thing} must be between {min} and {max}`,
    'value-min': `Minimum value is {min}`,
    'value-max': `Maximum value is {max}`,
    'value-required': `You must provide a value`,
    'value-integer': `Value must be an integer`,
    'value-positive': `A positive value is required`,
    'value-decimal-places': `A value can have {places} decimal places`,
    'broadcast-address': `Next hop cannot be a broadcast address`,
    'next-hop-same-ip-family': `Next hop must be in the same IP family as the prefix`,
    'next-hop-same-network': `Next hop must be in the same network as one of the interface IPs`,
    'next-hop-not-interface-address': `Next hop must not be listed in interface IP addresses`,
    'next-hop-network-address': `Next hop is a network address. A host address is expected`,
    'bgp-password': `Password can only include alphanumeric and special ({'!@#$%^&*+-=_'}) characters`,
    'bpg-password-length': `Password must be between 0 and 25 characters`,
    'min-max-range': `Values must be between {mix} and {max}`,
    'bpg-no-connections': `No BGP connections were found`,
    'bfd-transmit': `Transmit interval must be between 300 and 9000`,
    'bfd-receive': `Receive interval must be between 300 and 9000`,
    'bfd-multiplier': `Minimum multiplier of 3 is required`,
    'name-already-used': `This name is being used`,
    'address-family-required': `Address family is required`,
    'vlan-unique': `VLAN ID must be unique and no greater than {max}`,
    'invalid-address-domain': `Please enter a valid IP address or domain`,
    'vendor-image': `Please select the vendor image`,
    'email-invalid-separators': `Enter a single email address or a comma-separated list of email addresses`,
    'no-approval-price-title': `You do not have permission to approve price changes`,
    'no-approval-price-message': `Only users with ordering permission can make changes that affect the price of a service`,
    'enter-valid-auth-key': `Enter valid {thing}`,
    '128-chars': `Must be less than 128 characters.`,
    'confirm-password-mismatch': `The password does not match the provided password above`,
    'min-number': `Must have at least 1 number | Must have at least {min} numbers`,
    'min-upper-case': `Must have at least 1 upper case character | Must have at least {min} upper case characters`,
    'min-lower-case': `Must have at least 1 lower case character | Must have at least {min} lower case characters`,
    'min-symbol': `Must have at least 1 symbol character | Must have at least {min} symbol characters`,
    'as-path-prepend': `Enter AS Path Prepend count from 0 to 10`,
    'api-keys-expiry': `Enter a number from 5 to 1440`,
    'api-keys-expiry-decimal': `Expiry must be an integer`,
    'min-max-alphanumeric': `Must be {min} to {max} alphanumeric characters`,
    'mfa-token': `Enter your MFA token`,
    vnic: `vNIC Plane selection is required`,
    'bgp-password-valid-characters': `Valid characters are: a-z A-Z 0-9 ! {'@'} # ? . $ % ^ & * + = -`,
    'ix-peer-invalid-characters': `Letters, numbers, colons, underscores, and dashes only`,
    'ix-peer-invalid-format': `Please enter a colon-separated AS-prefixed ASN set`,
    'asn-validation-range-triple': `ASN range {minAsn}-{maxAsn}, {minAsn2}-{maxAsn2} or {minAsn3}-{maxAsn3} (default is {defaultAsn})`,
    'required-pattern': `Value must be in pattern {thing}`,
    'rfc-number-example': `Must be {total} characters ({start} alphanumerics then 6 numerals then 3 alphanumerics)`,
    'idp-name-invalid-characters': `The provider name must only contain lowercase letters, numbers and dashes`,
    alphanumeric: `{thing} must be alphanumeric characters only`,
    'reverse-dns-invalid-characters': `Valid characters are: a-z A-Z 0-9 . -`,
    'invalid-field': `Please enter a valid {thing}`,
  },
  connections: {
    'choose-type': `Choose Destination Type`,
    cloud: `Cloud`,
    'internet-exchange': `Internet Exchange`,
    csp: `${import.meta.env.VITE_EN_PRODUCTNAME_VXC} connection to a Cloud Service Provider`,
    private: `${import.meta.env.VITE_EN_PRODUCTNAME_VXC} connection to your own ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}, ${import.meta.env.VITE_EN_PRODUCTNAME_MCR}, or ${import.meta.env.VITE_EN_PRODUCTNAME_MVE}`,
    'private-vxc': `Private ${import.meta.env.VITE_EN_PRODUCTNAME_VXC}`,
    ix: `Connect to a ${import.meta.env.VITE_EN_COMPANYINFO_NAME} ${import.meta.env.VITE_EN_PRODUCTNAME_IX}`,
    marketplace: `${import.meta.env.VITE_EN_PRODUCTNAME_VXC} connection to a partner ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}`,
    'enter-service-key': `Enter Service Key`,
    'service-key': `Select this option if you have been given a ${import.meta.env.VITE_EN_COMPANYINFO_NAME} Service Key`,
    'select-destination-port': `Select the destination ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}`,
    'awaiting-deployment': `The connection is awaiting deployment. If deployment is taking an unusually long time, contact ${import.meta.env.VITE_EN_COMPANYINFO_NAME} Support.`,
    'approval-error': `${import.meta.env.VITE_EN_PRODUCTNAME_VXC} Approval Error.`,
    'deny-error': `${import.meta.env.VITE_EN_PRODUCTNAME_VXC} Deny Connection Error.`,
    'untag-vlan-tooltip': `Configure this service without a VLAN ID. This will limit the ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} to a single virtual service.`,
    'used-vlan': `There is already a designed connection on this ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} using this VLAN. You will be assigned a VLAN randomly if you continue.`,
    'asn-from-mcr': `ASN automatically uses ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} ASN`,
    'azure-ports': `Choose from available Azure ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS}`,
    'select-destination-port-title': `Select Destination ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}`,
    'no-available-target-ports': `No available target ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS}`,
    'please-select-port': `Please select ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}`,
    'no-available-ports-key': `There are no ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} available on this service key. Please try another.`,
    'all-ports-used-key': `All ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} associated with this service key have been consumed by other services. Please try another.`,
    'port-in-use-title': `${import.meta.env.VITE_EN_PRODUCTNAME_PORT} in Use`,
    'port-in-use-message': `This ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} is not available for selection because it is already in use.`,
    'google-ports': `Choose from available Google ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS}`,
    'no-google-services': `There are no Google Cloud services available in the region of the selected ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}.`,
    'no-google-ports': `There are no ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} available on this pairing key. Please try another.`,
    'nutanix-ports': `Choose from available Nutanix ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS}`,
    'oracle-ports': `Choose from available Oracle ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS}`,
    'no-oracle-ports': `There are no ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} available on this virtual circuit. Please try another.`,
    'all-oracle-ports-taken': `All ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} on this virtual circuit have been consumed by other services. Try another.`,
    'list-all-oracle-ports': `For a list of all available Oracle ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS}, refer to their ~ ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE} Profile`,
    'ix-details': `Connection details for ${import.meta.env.VITE_EN_PRODUCTNAME_IX} Service`,
    'ix-asn-message': `Note: ASN cannot be modified for ${import.meta.env.VITE_EN_PRODUCTNAME_MEGA_IX} services after deployment.`,
    'mcr-config': `${import.meta.env.VITE_EN_PRODUCTNAME_MCR} {end}-End Connection Details`,
    'mcr-override-asn': `Override ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} ASN`,
    'mcr-override-asn-tooltip': `Local ASN may be used to override the ASN of the ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} for this BGP peer. In most cases, it should be left as the default.`,
    'mcr-setup-tooltip': `For each ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} connected to this ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} you can configure one or more interfaces, IP addresses, BGP connections, or static routes. Most ${import.meta.env.VITE_EN_PRODUCTNAME_VXCS} will use one interface, however, you can configure multiple using "Q-in-Q" by selecting an "inner" VLAN tag for each interface. The TPID will be set to 0x8100.`,
    'mcr-ip-address-tooltip': `IP address and associated subnet mask to be configured on this interface.<br /><br />Multiple addresses are permitted. Both IPv4 and IPv6 are supported.<br /><br />You can leave this field blank to defer configuration of the interface until after the ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} is provisioned.`,
    'aws-diversity-tooltip': `Order ${import.meta.env.VITE_EN_PRODUCTNAME_VXCS} to different colour zones if you want device diversity.`,
    'no-matching-ports': `No matching target ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS}`,
    'service-key-id': `${import.meta.env.VITE_EN_COMPANYINFO_NAME} Service Key ID`,
    'service-key-unavailable': `The service key is for the current source {product} and cannot be used.`,
    'port-asn': `${import.meta.env.VITE_EN_PRODUCTNAME_PORT} ASN`,
    'port-ip': `${import.meta.env.VITE_EN_PRODUCTNAME_PORT} IP Address`,
    'aws-default-asn': `Default AWS Peer ASN. The ASN will be overridden if this ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} is attached to a Direct Connect Gateway.`,
    'vxc-is-down': `This ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} service is down`,
    'vxc-is-up': `This ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} service is up`,
    'vxc-status-unknown': `The status of this ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} service is unknown`,
    'ix-is-down': `This ${import.meta.env.VITE_EN_PRODUCTNAME_IX} service is down`,
    'ix-is-up': `This ${import.meta.env.VITE_EN_PRODUCTNAME_IX} service is up`,
    'ix-status-unknown': `The status of this ${import.meta.env.VITE_EN_PRODUCTNAME_IX} service is unknown`,
    'speed-req-external-approval': `Note: This ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} is owned by another company and any speed changes must be approved by them.`,
    'ix-service': `${import.meta.env.VITE_EN_PRODUCTNAME_IX} service`,
    'new-vxc': `New ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} Connection`,
    'preferred-end-vlan': `Preferred {title} VLAN`,
    'amsix-contact-phone': `A phone number is required for the {contactType} for an AMS-IX service.`,
    'cancel-a-end': `Note that ${import.meta.env.VITE_EN_COMPANYINFO_NAME} can only cancel the A-End of this service. To prevent ongoing charges from <b>{companyName}</b>, be sure to cancel your service directly with <b>{companyName}</b>.`,
    'no-matching-source-port': `No matching source ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS}`,
    'no-selected-source-port': `Select the source ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}.`,
    'non-edit-reason': `Note: The connection details are not editable as ~ {reason}`,
    'pending-approval': `The connection is pending external approval from {approvalFrom}.`,
    'pending-speed-approval': `The connection is pending external approval from {approvalFrom} for a speed change.`,
    'pending-speed-approval-to': `Pending rate limit change to {newSpeed} Mbps is awaiting approval from {approvalFrom}.`,
    'connection-denied': `VXC to {destination} was denied`,
    'connection-approved': `VXC to {destination} was approved`,
    'peer-config': `{type} Peer Configuration`,
    'min-bfg-tx-interval': `Minimum transmit interval is {min}ms`,
    'min-bfg-rx-interval': `Minimum receive interval is {min}ms`,
    'min-bfg-multiplier': `Minimum multiplier is {min}`,
    'key-expired': `This service key is no longer valid. Contact {company} to resolve this issue`,
    'key-used': `This single use service key has already been used. Contact {company} to resolve this issue`,
    'key-invalid': `This service key is not valid for use. Contact {company} to resolve this issue`,
    'ix-rate-limit-help': `${import.meta.env.VITE_EN_PRODUCTNAME_IX} rate limit is capped at the ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} aggregate speed for metro connections, and 10Gbps for non-metro.`,
    'vxc-rate-limit-help': `The maximum rate limit for a ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} is set by the lowest speed at each end and network capacity on the route.`,
    'max-ip-addresses': `Maximum of {max} IP addresses`,
    'max-static-routes': `Maximum of {max} static routes`,
    'max-bgp-connections': `Maximum of {max} BGP connections`,
    'mcr-end-visibility': `Click to {operation} ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} {end}-End details`,
    'mcr-end-details': `${import.meta.env.VITE_EN_PRODUCTNAME_MCR} {end}-End Details`,
    'mcr-end': `${import.meta.env.VITE_EN_PRODUCTNAME_MCR} {end}-End`,
    'missing-azure-service-key': `This Azure ExpressRouter service is missing the service key. To order this service, provide the service key on the "Select ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}" screen.`,
    'missing-google-pairing-key': `This Google service is missing the pairing key. To order this service, provide the service key on the "Select ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}" screen.`,
    'new-bgp-state': `Newly created BGP connections will be {state}.`,
    'google-key-format': `Google Keys are of the format:`,
    'bgp-peer-asn': `Peer ASN`,
    'connection-details': `Connection Details`,
    'connection-name': `Connection Name`,
    'vlan-fixed': `Note: VLAN cannot be modified for this type of service after deployment.`,
    'configure-azure-vlan': `Configure single Azure peering VLAN`,
    'azure-vlan': `Azure peering VLAN`,
    'enter-vlan': `Enter VLAN`,
    'preferred-vlan': `Preferred VLAN`,
    asn: `ASN`,
    'asn-full': `Autonomous System Number`,
    'mac-address': `MAC Address`,
    'mac-address-format': `Enter a MAC Address in one of the following formats - 3c22.fb80.749c or 3c:22:fb:80:74:9c`,
    'bgp-password': `BGP Password`,
    'graph-visibility': `Graph Visibility`,
    untagged: `Untagged`,
    shutdown: `shutdown`,
    enabled: `enabled`,
    'end-not-configured': `{end}-End has not been configured yet.`,
    'interface-vlan': `Interface VLAN`,
    'ip-addresses': `IP Addresses`,
    'static-routes': `Static Routes`,
    'bgp-connections': `BGP Connections`,
    'nat-ip-addresses': `NAT IP Addresses`,
    'b-vlan-service-key': `B-End VLAN (from Service Key)`,
    'hide-cloud-details': `Click to hide cloud details`,
    'show-cloud-details': `Click to show cloud details`,
    'cloud-details': `Cloud Details`,
    'peering-policy': `Peering Policy`,
    'policy-url': `Policy URL`,
    'peering-email': `Peering Email`,
    'company-url': `Company URL`,
    'company-email': `Company Email`,
    'street-address': `Street Address`,
    city: `City`,
    'state-province': `State or Province`,
    'postcode-zip': `Postcode or ZIP`,
    country: `Country`,
    type: `type`,
    'aws-connection-name': `AWS Connection Name`,
    'aws-owner-acct-id': `AWS owner account ID`,
    'customer-ip': `Customer IP Address`,
    'amazon-ip': `Amazon IP Address`,
    'alibaba-account': `Alibaba account ID`,
    'pairing-key': `Pairing key`,
    'virtual-circuit-id': `Virtual Circuit ID`,
    deleted: `Connection Deleted`,
    'select-destination': `Select Destination`,
    'configure-a-end': `Configure A-End`,
    'configure-b-end': `Configure B-End`,
    'vlan-value': `VLAN value`,
    save: `Save Connection`,
    'approve-prompt': `Do you want to approve this connection?`,
    'approve-connection': `Approve Connection`,
    'deny-connection': `Deny Connection Request`,
    configuration: `Connection Configuration`,
    'awaiting-approval': `This connection is pending your approval.`,
    'approve-question': `Are you sure you want to approve this request?`,
    'approve-title': `Approve Request`,
    'deny-question': `Are you sure you want to deny this request?`,
    'deny-title': `Deny Request`,
    'cancel-question': `Are you sure you want to cancel this request?`,
    'cancel-title': `Cancel Request`,
    'change-approved-title': `Speed Change Approved`,
    'change-approved-message': `The new rate limit has been approved.`,
    'change-denied-title': `Speed Change Denied Successfully`,
    'change-denied-message': `The rate limit change request has been denied.`,
    'change-cancelled-title': `Speed Change Cancelled Successfully`,
    'change-cancelled-message': `The rate limit change request has been cancelled.`,
    'approved-title': `Connection Approved`,
    'denied-title': `Connection Denied Successfully`,
    untag: `Untag`,
    'auto-assigned': `Auto Assigned`,
    'auto-assign': `Auto Assign VLAN`,
    'vlan-checking': `Checking VLAN availability...`,
    'add-connection': `Add Connection`,
    open: `Open`,
    closed: `Closed`,
    vlan: `VLAN`,
    'valid-service-key': `Valid Service Key`,
    'verifying-key': `Verifying key`,
    'select-provider': `Select Provider`,
    'no-matching-providers': `No matching providers`,
    'select-connection-type': `Select Connection Type`,
    'bgp-statuses': `BGP Statuses`,
    'end-vlan': `{end}-End VLAN`,
    'route-servers': `Route Servers`,
    'bgp-down': `BGP is Down`,
    'bgp-up': `BGP is Up`,
    'bgp-unknown': `BGP status is Unknown`,
    interface: `Interface`,
    'old-speed': `Old Speed`,
    'new-speed': `New Speed`,
    'approve-speed-question': `Do you want to approve this speed change?`,
    'deny-speed-change': `Deny Speed Change Request`,
    'approve-speed-change': `Approve Speed Change`,
    'cancel-speed-request': `Cancel Speed Change Request`,
    'connections-html': `For details, see the {link}`,
    'speed-change-pending-your-approval': `Pending Your Approval: A speed change was requested for this connection.`,
    'speed-change-pending-company-approval': `Pending Company Approval: A speed change was requested for this connection (requires your company's approval).`,
    connection: `Connection`,
    'lag-full': `This ${import.meta.env.VITE_EN_PRODUCTNAME_LAG} cannot accept any new connections`,
    'port-full': `This ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} cannot accept any new connections`,
    'show-connections': `Click to show connections`,
    'hide-connections': `Click to hide connections`,
    'service-key-vlan-range': `VLAN 2-4094 (disabled for multi-use service keys)`,
    'third-party-owner': `third-party owner`,
    'b-end': `You are at the B-End of this connection owned by another company`,
    'shut-down-by-user': `shut down by user`,
    'b-end-no-delete': ` end of connection cannot delete connection`,
    'speed-change-from': `Speed change from`,
    'new-connection-from': `New connection from`,
    'best-route-explanation': `A check mark indicates that ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} prefers this route when choosing between two routes to the same destination.`,
    'as-path': `AS path`,
    'local-preference': `Local Preference`,
    med: `Multi-Exit Discriminator`,
    metric: `Metric`,
    protocol: `Protocol`,
    distance: `Distance`,
    'gateway-ipv4': `Gateway IP Address (IPv4)`,
    'gateway-ipv6': `Gateway IP Address (IPv6)`,
    'match-label': `Match`,
    'dont-match-label': `Don't match`,
    'delete-route-label': `Delete route`,
    'connected-port-removed': `This ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} or ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} this service was connected to has been removed from the data. Redirecting to services`,
    approving: `approving`,
    denying: `denying`,
    'approval-issues': `Issues {action} ${import.meta.env.VITE_EN_PRODUCTNAME_VXC}: {errorStr}`,
    'confirm-bgp-state-change': `This will change the BGP State to {newState} and commit any unsaved changes made to this service. Changes may take up to two minutes to reflect.`,
    'bgp-state-change-prompt': `Confirm change BGP State Change?`,
    'awaiting-approval-or-denial': `Awaiting for you to approve/reject this connection`,
    'azure-key-unused-key': `Available at this location`,
    'azure-key-used-key': `Can be used multiple times by specifying a unique Azure Peering VLAN`,
    'azure-key-unused': `This ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} has not been used with this service key`,
    'azure-key-used': `This ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} may be used multiple times by specifying a unique Azure Peering VLAN`,
    'outscale-connection-name': `Outscale Connection Name`,
    'outscale-account': `Outscale owner account ID`,
    'outscale-ip': `Outscale IP Address`,
    transit: `Transit Gateway Access via a ${import.meta.env.VITE_EN_COMPANYINFO_NAME} provided connection`,
    'vxc-name-default': `{aEnd} to {bEnd}`,
    'customer-asn': `Customer ASN`,
    vnic: `vNIC`,
    'reverse-dns': `Reverse DNS`,
    'virtual-interfaces-vnics': `Virtual Interfaces (vNICs)`,
    'vnic-plane': `vNIC-{index} {plane} Plane`,
    'end-vnic': `{end}-End vNIC`,
    'create-ip-transit-vxc': `Create ${import.meta.env.VITE_EN_PRODUCTNAME_TRANSIT_VXC}`,
    'mcr-full': `This ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} cannot accept any new connections`,
    'mve-full': `This ${import.meta.env.VITE_EN_PRODUCTNAME_MVE} cannot accept any new connections`,
    'provider-ip': `Provider IP Address`,
    'ibm-account': `IBM Cloud Account ID`,
    'mve-untag-vlan-tooltip': `Configure this service without a VLAN ID.`,
    'no-transit-port-title': `Failed to locate ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} for ${import.meta.env.VITE_EN_PRODUCTNAME_TRANSIT_VXC}`,
    'no-transit-port-message': `A ${import.meta.env.VITE_EN_PRODUCTNAME_TRANSIT_VXC} ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} could not be found. Please contact ${import.meta.env.VITE_EN_COMPANYINFO_SUPPORT_EMAIL}`,
    'a-end-vnic-help': `Initiation service virtual interface`,
    'b-end-vnic-help': `Terminating service virtual interface`,
    'no-description': `No description`,
    'select-connections': `Select One or More Connections`,
    'move-connection': `Move Connection`,
    'select-new-end': `Select New End`,
    'move-connection-confirm': `Please confirm you wish to proceed and move the selected connection(s) from {currentEnd} to {newEnd}.`,
    'move-connection-diversity': `Note: Diversity zone is changing from {currentDiv} to {newDiv}. This may affect your new diversity.`,
    'move-connection-disclaimer': `During the move, the connection(s) will be unavailable. The move may take up to 2 minutes to complete.`,
    'no-valid-connections': `No valid connections found`,
    'no-valid-destinations': `No valid destinations found`,
    'invalid-move-existing-end': `Target {type} already attached to selected connection`,
    'invalid-move-too-small': `Insufficient capacity at target {type}`,
    'invalid-move-new-untagged': `Target {type} cannot accept untagged connection`,
    'invalid-move-untagged': `Target {type} has an untagged connection`,
    'invalid-move-vlan-taken': `VLAN not available on target {type}`,
    'search-for-providers': `View more Providers`,
    'termed-delete-etf': `Early Termination Fees (ETF) apply when deleting a termed connection in accordance with the terms of your agreement with Megaport.`,
    'avoid-etf': `To avoid ETF, select ‘No Minimum Term’ until the configuration details are accurate. You can update the connection at any time to opt into a term.`,
    'rate-limit-change-disallowed': `This is a termed connection, rate limit changes are not allowed unless the connection is re-termed and rate limit is increased.`,
    'rate-limit-change-term': `This is a termed connection, rate limit cannot be decreased below {termSpeed} Mbps, the selected speed at time of terming.`,
    'private-vxc-no-valid-target': `You do not have any valid services to establish a private connection with`,
    'vnic-details': `vNIC Details`,
    'new-vnic-details': `New vNIC Details`,
    'new-end-vnic': `New {end}-End vNIC`,
    'new-preferred-end-vlan': `New preferred {title} VLAN`,
    'move-connection-vnic-warning': `Note: The vNIC and VLAN on the destination ${import.meta.env.VITE_EN_PRODUCTNAME_MVE} will also change to the specified values.`,
    'confirm-move': `Confirm Move`,
    'speed-req-external-approval-a-end': `Note: This ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} is owned by another company and you are not permitted to make speed changes to their ${import.meta.env.VITE_EN_PRODUCTNAME_VXC}.`,
    'speed-req-external-approval-b-end': ` Note: This ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} is connected to another company and any speed changes must also be approved by them.`,
  },
  ports: {
    'port-deleted': `${import.meta.env.VITE_EN_PRODUCTNAME_PORT} Deleted`,
    'select-port': `Select ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}`,
    'port-speed': `${import.meta.env.VITE_EN_PRODUCTNAME_PORT} Speed`,
    'select-port-speed': `Select ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} Speed`,
    'mcr-explanation': `A ${import.meta.env.VITE_EN_PRODUCTNAME_MCR_LONG} (${import.meta.env.VITE_EN_PRODUCTNAME_MCR}) is a Layer 3 capable virtual routing instance provided and hosted by ${import.meta.env.VITE_EN_COMPANYINFO_NAME} in cloud hotspot locations worldwide. It does not require a physical ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}, however you can connect to one if you want.`,
    'port-main-tooltip': `A physical ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} is required if you are connecting your equipment to ours. We support 1G, 10G and 100G fiber interfaces (generally duplex SMOF - check your LOA retrieved during ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} deployment for site specific requirements) in ${import.meta.env.VITE_EN_COMPANYINFO_NAME} connected locations.`,
    'diverse-from-existing': `Diverse from Existing ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}`,
    'ports-count': `0 ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} | 1 ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} | {count} ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS}`,
    'ports-type-count': `0 {type} ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} | 1 {type} ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} | {count} {type} ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS}`,
    'pluralize-ports': `${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} | ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} | ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS}`,
    'services-attached-count': `0 services attached | 1 service attached | {count} services attached`,
    'percent-allocated': `{percent}% allocated`,
    'awaiting-resources': `This {type} is waiting for resource allocation.`,
    'add-connection-to': `Add new connection to this {type}`,
    'no-mcr-speed-change': `The rate limit of the ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} is fixed for the life of the service`,
    'marketplace-visibility': `${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE} Visibility`,
    'lacp-ports': `LACP & ${import.meta.env.VITE_EN_PRODUCTNAME_LAG} ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS}`,
    'lacp-on-port': `LACP on ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}`,
    'add-to-lag': `${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} to add to ${import.meta.env.VITE_EN_PRODUCTNAME_LAG}`,
    'ports-in-lag': `Number of ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} in ${import.meta.env.VITE_EN_PRODUCTNAME_LAG}`,
    'mcr-asn': `${import.meta.env.VITE_EN_PRODUCTNAME_MCR} ASN`,
    'summary-adding-to-lag': `Adding ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} to ${import.meta.env.VITE_EN_PRODUCTNAME_LAG}`,
    'summary-ports-in-lag': `${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} in ${import.meta.env.VITE_EN_PRODUCTNAME_LAG}`,
    'no-slow-ports-in-lag': `No ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} speeds < 10Gbps in ${import.meta.env.VITE_EN_PRODUCTNAME_LAG}`,
    'mcr-rate-limit': `${import.meta.env.VITE_EN_PRODUCTNAME_MCR} Rate Limit`,
    'marketplace-short-visibility': `${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE_SHORT} Visibility`,
    'public-port-info': `Setting a ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} to Public will publicly display the ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} on ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE}.`,
    'public-port-tooltip': `Customers can request ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} connections to your ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}`,
    'private-port-tooltip': `Your ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} will be private and not available to connection targets on the ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE}`,
    'allocated-tooltip': `The ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} speed and the total cumulative rate limit of its services`,
    'port-removed': `The ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} has been removed from the data. Redirecting to services`,
    'contract-terms': `Contract terms will display when the ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} is live`,
    statuses: `${import.meta.env.VITE_EN_PRODUCTNAME_PORT} Statuses`,
    'configure-details': `Configure details for the added ${import.meta.env.VITE_EN_PRODUCTNAME_LAG} ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS}`,
    'add-ports-to-lag': `Add new ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} to this ${import.meta.env.VITE_EN_PRODUCTNAME_LAG}`,
    'lag-full': `This ${import.meta.env.VITE_EN_PRODUCTNAME_LAG} cannot accept any more ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS}`,
    'max-speed-tooltip': `Rate Limit in Mbps. Must be equal to or lower than {speed}. Leaving this blank will limit to maximum available {productType} speed.`,
    'vxc-speed-positive-integer': `${import.meta.env.VITE_EN_PRODUCTNAME_VXC} speed must be a positive integer`,
    'vxc-speed-max': `Maximum ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} speed is {speed} Mbps`,
    'lag-primary-deletion-locked': `Primary ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} of ${import.meta.env.VITE_EN_PRODUCTNAME_LAG} cannot be deleted while there are locked ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} in the ${import.meta.env.VITE_EN_PRODUCTNAME_LAG}`,
    'deletion-insufficient-capacity': `Deleting this ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} would leave insufficient capacity to support the current connections on the ${import.meta.env.VITE_EN_PRODUCTNAME_LAG}`,
    'link-aggregation': `Link Aggregation for {portName}`,
    'attached-vxc-locked': `Attached ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} is locked`,
    'attached-ix-locked': `Attached ${import.meta.env.VITE_EN_PRODUCTNAME_IX} is locked`,
    'terminate-date': `This service will terminate on {date}`,
    'autoconfig-mcr': `The ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} connection configuration will be generated automatically. Once the service is live you can modify the configuration.`,
    'count-port-lag': `- | (1 ${import.meta.env.VITE_EN_PRODUCTNAME_LAG} ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}) | ({count} ${import.meta.env.VITE_EN_PRODUCTNAME_LAG} ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS})`,
    'public-port-warning': `By selecting this option, you understand and agree that information about the ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} (such as its speed and location) will be publicly accessible. You can change the setting for this ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} back to Private at any time.`,
    'lag-explanation': `Creates this ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} with the LACP protocol enabled so it can be used on a ${import.meta.env.VITE_EN_PRODUCTNAME_LAG_LONG} (${import.meta.env.VITE_EN_PRODUCTNAME_LAG}) and lets you specify the number of ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} to include in your ${import.meta.env.VITE_EN_PRODUCTNAME_LAG} group.`,
    'add-lag-ports-disclaimer': `This will add multiple ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} on this ${import.meta.env.VITE_EN_PRODUCTNAME_LAG_LONG} (${import.meta.env.VITE_EN_PRODUCTNAME_LAG}). Pricing displayed is for all the ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} being added to the ${import.meta.env.VITE_EN_PRODUCTNAME_LAG}.`,
    'port-diversity': `${import.meta.env.VITE_EN_PRODUCTNAME_PORT} Diversity`,
    'diversity-unavailable-location': `Diversity is not currently available for this configuration. Contact <a href="mailto:${import.meta.env.VITE_EN_COMPANYINFO_SALES_EMAIL}">${import.meta.env.VITE_EN_COMPANYINFO_SALES_EMAIL}</a> to discuss your diversity requirements.`,
    'diversity-explanation-html': `${import.meta.env.VITE_EN_PRODUCTNAME_PORT} diversity allows ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} at the same location to be in different diversity zones. Creating redundant ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} in diverse zones reduces the risk of service interruption due to an impacting event on a single device. For details, see {link}`,
    'select-lag-speed': `Minimum ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} speed to enable LACP is 10Gbps`,
    'select-speed-diversity': `Select a ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} speed to view diversity options.`,
    'diversity-lag-add': `- | The new ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} will be added to the diversity zone of the ${import.meta.env.VITE_EN_PRODUCTNAME_LAG}. | The new ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} will be added to the diversity zone of the ${import.meta.env.VITE_EN_PRODUCTNAME_LAG}.`,
    'diverse-pair': `Two {things} will be configured and added to different diversity zones.`,
    'diverse-from': `Diverse from : {port}`,
    'port-diverse-from': `${import.meta.env.VITE_EN_PRODUCTNAME_PORT} to be diverse from`,
    'first-port-name': `First {thing} Name`,
    'second-port-name': `Second {thing} Name`,
    'delete-diverse-from': `- | The following ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} is configured to be diverse from the ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} you are terminating: | The following ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} are configured to be diverse from the ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} you are terminating:`,
    'reset-diversity': `- | If you terminate the ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}, the ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} will lose its diversity setting and will no longer have a specified diversity zone. | If you terminate the ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}, the listed ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} will lose their diversity setting and will no longer have a specified diversity zone.`,
    'mcr-prefix-filter': `The ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} Prefix Filter feature supports inbound and outbound prefix-based BGP route filters. <br/><br/> <strong>You can add and configure prefix lists when your service is configured.</strong>`,
    'bgp-default-state-tooltip': `Set whether newly created BGP connections are enabled or shut down by default. Select Enabled (the default) if you want any new BGP sessions you configure to be live as soon as you save the configuration. Select Shut Down if you want any new BGP sessions you configure to be left in a shut down state when you save the configuration.`,
    'mve-software': `${import.meta.env.VITE_EN_PRODUCTNAME_MVE} Software`,
    'new-prefix-filter-list': `New`,
    'open-port': `Open ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}`,
    'prefix-filter-list': `Prefix Filter Lists`,
    'prefix-list-learn-more': `Learn more about Prefix Filter Lists`,
    'prefix-list-loaded-success': `Successfully loaded the selected prefix list`,
    'prefix-list-loaded-fail': `Failed to load the selected prefix list`,
    'lacp-enabled': `LACP Enabled`,
    'diverse-pair-label': `Diverse Pair`,
    'diverse-from-label': `Diverse From`,
    'download-loa': `Download LOA`,
    failed: `Failed to update ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}`,
    vendor: `Vendor`,
    'mve-file-upload': `File Upload`,
    'cloud-init-vmanage': `Generate and upload a cloud init file from your vManage system`,
    'mve-init': `Initialization file`,
    waiting: `Waiting for cross connect.`,
    'key-valid-until': `Valid Until`,
    'service-keys': `Service Keys`,
    'lag-up': `This ${import.meta.env.VITE_EN_PRODUCTNAME_LAG} is up`,
    'lag-down': `This ${import.meta.env.VITE_EN_PRODUCTNAME_LAG} is down`,
    'key-saved': `Service key saved successfully.`,
    'key-added': `Service key added successfully.`,
    'key-saved-message': `Service key [{description}] has been saved.`,
    'key-add-error': `Error creating service key.`,
    'key-update-error': `Error updating service key.`,
    'minimum-term': `Minimum Term`,
    // cannotBeEdited section is in bold, will reference cannot-edit string
    'uneditable-asn-html': `You can configure the Autonomous System Number (ASN) of this ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} or use the ${import.meta.env.VITE_EN_COMPANYINFO_NAME} supplied public ASN {defaultAsn}. The ASN will be used for BGP peering sessions on any ${import.meta.env.VITE_EN_PRODUCTNAME_VXCS} connected to this ${import.meta.env.VITE_EN_PRODUCTNAME_MCR}. It {cannotBeEdited} once the ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} has been ordered. Note that some public cloud services require the use of a public ASN. Consult the documentation relating to your cloud provider before overriding this default value.`,
    'bgp-default-state': `BGP Default State`,
    'site-deployment': `This location will soon go LIVE. Pre-order now to receive your LOA as soon as it becomes available. For more information or to discuss further, contact your account manager.`,
    terms: `Contract Terms`,
    'end-date-display': `Contract end date will be displayed once the {productName} is live`,
    'change-term': `Change Contract Term`,
    'term-info': `Find out more about {productName} contract terms`,
    monthly: `Month to Month`,
    'existing-lag': `existing ${import.meta.env.VITE_EN_PRODUCTNAME_LAG}`,
    'thing-enabled': `{thing} Enabled`,
    'thing-disabled': `{thing} Disabled`,
    'address-family-label': `Address Family`,
    'local-ip-label': `Local IP`,
    'peer-ip-label': `Peer IP`,
    'local-asn-label': `Local ASN`,
    'bgp-state-label': `BGP State`,
    'bgp-peer-filter-label': `BGP Peer Filter`,
    'bgp-prefix-filter-import-label': `Import Prefix Filter`,
    'bgp-prefix-filter-export-label': `Export Prefix Filter`,
    'bgp-confirm-delete': `This IP address is in use in BGP connections or NAT IP addresses. Deleting it will remove those configurations. Continue?`,
    'permit-list-label': `Permit List`,
    'deny-list-label': `Deny List`,
    'new-list-label': `New List`,
    'duplicate-list-label': `Duplicate List`,
    'delete-list-label': `Delete List`,
    'delete-list-confirm': `Are you sure you want to delete this list?`,
    'filter-advertise-default-label': `Advertise by Default`,
    'filter-advertise-exception-label': `Advertise by Exception`,
    'bfd-switch-label': `Use BFD`,
    'bfd-label': `BFD - Bidirectional Forwarding Detection`,
    'inbound-label': `Inbound`,
    'outbound-label': `Outbound`,
    'med-label': `MED (Metric) - Multi-Exit Discriminator`,
    'bgp-prefix-filter-label': `BGP Prefix Filter`,
    'bgp-connection-filter-label': `BGP Connection Filter`,
    'no-connection-found': `No {thing} connections were found`,
    'address-placeholder': `Enter an IP address (IPv4/IPv6)`,
    'network-address-placeholder': `Enter an {thing} address and CIDR`,
    'subnet-mask-range': `Subnet Mask Range`,
    'select-address': `Select address`,
    'prefix-list-label': `Prefix List`,
    'mve-explanation': `A ${import.meta.env.VITE_EN_PRODUCTNAME_MVE_LONG} (${import.meta.env.VITE_EN_PRODUCTNAME_MVE}) is a BYOL instance of a Partner SD-WAN gateway provided and hosted by ${import.meta.env.VITE_EN_COMPANYINFO_NAME} adjacent to cloud hotspot locations worldwide. It allows controlled access to ${import.meta.env.VITE_EN_COMPANYINFO_NAME} network resources via the Internet. It does not require a physical ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} but can connect to one if you wish. All licensing and management is handled via Partner solutions.`,
    'ssh-key': `SSH Key`,
    'ssh-key-rsa-help': `2048-bit RSA SSH public key`,
    'mve-appliance-licence': `Appliance Licence`,
    'mve-activation-code': `Activation Code`,
    'mve-address': `Orchestrator Address`,
    // FQDN - Fully Qualified Domain Name
    'mve-address-placeholder': `IPv4/IPv6 or FQDN`,
    'mve-select-vendor': `Select Vendor / Product`,
    'mve-cloud-init-file-upload': `Cloud-init File`,
    'mve-cloud-init-file-upload-help': `Refer to {title} product documentation to generate a cloud-init file.`,
    'mve-appliance-licence-help': `Appliance Licence`,
    'key-valid': `Valid`,
    'prefix-list-count': `{count} List | {count} Lists`,
    'mve-director-address': `Director Address`,
    'mve-controller-address': `Controller Address`,
    'mve-local-auth': `Local Auth`,
    'mve-local-auth-help': `Local id string or email`,
    'mve-remote-auth': `Remote Auth`,
    'mve-remote-auth-help': `Remote id string or email`,
    'serial-number': `Serial Number`,
    'prefix-list-save-confirm': `Do you want to save the prefix list changes?`,
    'how-to-create-port-text': `how to create a ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}`,
    'how-to-create-port-paragraph': `For instructions on {0}, visit ${import.meta.env.VITE_EN_COMPANYINFO_NAME} documentation.`,
    'aruba-orchestrator-name': `Orchestrator Account Name`,
    'aruba-orchestrator-name-tt': `The account name associated with the Aruba Orchestrator instance`,
    'aruba-account-key': `Account Key`,
    'aruba-account-key-tt': `The account key linked to the above account name`,
    'aruba-system-tag': `System Tag`,
    'aruba-system-tag-tt': `Used when registering with the Aruba Cloud Portal and the Aruba Orchestrator`,
    'as-path-prepend-label': `AS Path Prepend`,
    'unbundled-mve-size-info': `This size will include {cpuCount} vCPUs, {ram}GB of RAM, and {storage}GB of storage.`,
    'mve-appliance-mode': `Appliance Mode`,
    'mve-appliance-mode-tooltip': `<p>Appliance Mode will determine how you configure and manage your deployment.</p>
      <ul>
      <li><strong>Autonomous Mode</strong>: via Command Line Interface (CLI)</li>
      <li><strong>SD-WAN Mode</strong>: via vManage</li>
      </ul>
      <p>${import.meta.env.VITE_EN_COMPANYINFO_NAME} does not support a change to appliance modes post-deployment - this may result in a loss of configuration.</p>
      `,
    'mve-license-data': `License Data`,
    'mve-admin-password': `Admin Password`,
    'mve-admin-password-tooltip': `<p>You will need to create an admin password to access the virtual device. The admin password must contain:</p>
      <ul>
      <li>a minimum of eight characters</li>
      <li>at least one uppercase letter</li>
      <li>at least one lowercase letter</li>
      <li>at least one number</li>
      <li>at least one symbol</li>
      </ul>
      <p>Please ensure you store the admin password safely and don't share it with anyone. If needed, the admin password can be reset at the first login into the configured device.</p>
      <p><strong>NOTE</strong>: ${import.meta.env.VITE_EN_COMPANYINFO_NAME} does not store customer passwords.</p>
      `,
    'mve-vnic-prompt-info': `${import.meta.env.VITE_EN_COMPANYINFO_NAME} will prompt you to order a ${import.meta.env.VITE_EN_PRODUCTNAME_TRANSIT_VXC} ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} for initial booting & configuration once the base configuration of your ${import.meta.env.VITE_EN_PRODUCTNAME_MVE} has been finalised.`,
    'mve-vendor-vnic-count': `{vendor} ${import.meta.env.VITE_EN_PRODUCTNAME_MVES} have one vNIC. | {vendor} ${import.meta.env.VITE_EN_PRODUCTNAME_MVES} have two vNICs (vNIC 0 & vNIC 1). | {vendor} ${import.meta.env.VITE_EN_PRODUCTNAME_MVES} have multiple vNICs.`,
    'mve-ip-transit-vxc-prompt-success': `You have successfully configured the compute component of your {vendorName} ${import.meta.env.VITE_EN_PRODUCTNAME_MVE}, which includes CPU, RAM & storage.`,
    'mve-ip-transit-vxc-prompt-recommend': `To finalise the configuration of your {vendorName} ${import.meta.env.VITE_EN_PRODUCTNAME_MVE}, we recommend you create a ${import.meta.env.VITE_EN_PRODUCTNAME_TRANSIT_VXC} connection.`,
    'mve-ip-transit-vxc-prompt-docs': `For more information, refer to our {link}.`,
    'mcr-prefix-filter-support': `The ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} Prefix Filter feature supports inbound and outbound prefix-based BGP route filters.`,
    'mcr-prefix-filter-configure': `You can add and configure prefix lists when your service is configured.`,
    'cannot-edit': `cannot be edited`,
    'move-connections': `Move Connections`,
    'megaport-internet-restricted-location-reason': `You have one or more ${import.meta.env.VITE_EN_PRODUCTNAME_TRANSIT_VXC} ${import.meta.env.VITE_EN_PRODUCTNAME_VXCS} associated to this {productType}. This {productType} and its associated ${import.meta.env.VITE_EN_PRODUCTNAME_TRANSIT_VXC} ${import.meta.env.VITE_EN_PRODUCTNAME_VXCS} must be configured within the same region.`,
    'megaport-internet-restricted-location-workaround': `If you wish to configure your {productType} in a different region, please delete its associated ${import.meta.env.VITE_EN_PRODUCTNAME_TRANSIT_VXC} ${import.meta.env.VITE_EN_PRODUCTNAME_VXCS} first.`,
    'mve-manage-locally': `Manage Locally`,
    'mve-manage-locally-tooltip': `Indicates if this ${import.meta.env.VITE_EN_PRODUCTNAME_MVE} instance phones home to the Firewall Management Center or not.`,
    'mve-fmc-ip-address': `FMC IP Address`,
    'mve-fmc-ip-address-tooltip': `Cisco Secure FMC network address. This can be one of: hostname, IPv4 or IPv6 address.`,
    'mve-fmc-registration-key': `FMC Registration Key`,
    'mve-nat-id': `NAT ID`,
    'mve-nat-id-tooltip': `Provide the NAT ID if the Firewall Management Controller is not directly addressable.`,
    'mve-ion-key': `ION Key`,
    'mve-secret-key': `Secret Key`,
    'unbundled-mve-size-info-no-storage': `This size will include {cpuCount} vCPUs and {ram}GB of RAM.`,
    'service-key-name': `{name} Service Keys`,
    'mve-cisco-vmanage': `vManage`,
    'mve-aviatrix-secure-edge': `Aviatrix Secure Edge`,
    'mve-select-version': `Select Version`,
    'mve-version': `Version {version}`,
    'mve-service-details': `{vendor} Service Details`,
  },
  'port-term': {
    'funky-1': `The term for this service is unable to be updated automatically.`,
    'funky-2': `Please contact your ${import.meta.env.VITE_EN_COMPANYINFO_NAME} Sales representative.`,
    title: `Set {productType} Contract Term`,
    choose: `Choose {productType} Contract Term`,
    'update-failure': `Failed to update ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} term`,
    'update-error': `An error occurred when trying to update the ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} term`,
    'update-unavailable': `The selected ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} term is not available.`,
    'update-success': `Updated {productType} Terms`,
    'reterm-success': `- | Set the term for ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} '{portName}' to 1 month | Set the term for ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} '{portName}' to {count} months`,
    reterm: `The new contract term for this service (the Minimum Term) is effective immediately and the new term discount will be applied to the next calendar month's bill run. Early termination of the Minimum Term will incur early termination fees in accordance with the agreement between us.`,
    'confirm-new-term': `Start New Contract Term`,
    'no-change': `No Change`,
    'funky-3': `Please contact your ${import.meta.env.VITE_EN_COMPANYINFO_NAME} Partner.`,
    'no-minimum-term': `No Minimum Term`,
    'minimum-term-tooltip-ports': `Minimum term will commence once service goes live or 14 days after service configuration`,
    'minimum-term-tooltip': `Minimum term will commence upon ordering`,
    'reterm-immediate': `The new contract term for this service (the Minimum Term) is effective immediately. The Minimum Term will begin on {start} and end on {end}. Early termination of the Minimum Term will incur early termination fees in accordance with the agreement between us.`,
    'option-price': `- | Monthly: {cost} per month | {count} Months: {cost} per month`,
  },
  dashboard: {
    navigation: `Learn the basics of navigating around the ${import.meta.env.VITE_EN_COMPANYINFO_PORTAL}, and learn how to create your first ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}.`,
    'visit-marketplace': `Visit ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    'deep-dive': `Learn about our services and discover how to establish networking, cloud connectivity, and ${import.meta.env.VITE_EN_PRODUCTNAME_IX} solutions with technical documents and articles.`,
    'ql-overview': `An introduction to ${import.meta.env.VITE_EN_COMPANYINFO_NAME}`,
    'ql-vxc': `How to set up a connection with a ${import.meta.env.VITE_EN_PRODUCTNAME_VXC}`,
    'ql-cloud': `Connecting to Cloud Service Providers`,
    'ql-mcr': `${import.meta.env.VITE_EN_PRODUCTNAME_MCR} - Virtual routing for private Layer 3 connectivity`,
    'marketplace-marketing': `The online hub where our service provider partners and enterprise customers come together and share services. On the ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE}, you can discover new resources and be discovered by new customers.`,
    'marketplace-image': `Click to select an image for your company's ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE_SHORT} profile.`,
    'get-started': `Get Started`,
    'quick-links': `Quick Links:`,
    documentation: `${import.meta.env.VITE_EN_COMPANYINFO_NAME} Documentation`,
    video: `Watch Video`,
    'video-error': `Unable to Play Video`,
    'video-error-message': `Playing videos appears to have been blocked on your browser. This has been reported to the development team for investigation. Sorry for the inconvenience.`,
  },
  menu: {
    dashboard: `Dashboard`,
    company: `Company`,
    'ix-looking-glass': `${import.meta.env.VITE_EN_PRODUCTNAME_MEGA_IX} Looking Glass`,
    'ix-telemetry': `${import.meta.env.VITE_EN_PRODUCTNAME_IX} Telemetry`,
    'marketplace-profile': `${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE} Profile`,
    'mcr-looking-glass': `${import.meta.env.VITE_EN_PRODUCTNAME_MCR} Looking Glass`,
    'more-events': `More events are available:`,
    'company-profile': `Company Profile`,
    'billing-markets': `Billing Markets`,
    'manage-users': `Manage Users`,
    tools: `Tools`,
    'api-documentation': `API Documentation`,
    'api-key-generator': `API Key Generator`,
    'event-log': `Event Log`,
    'view-event-log': `View Event Log`,
    events: `Events`,
    'bug-report': `Send Bug Report`,
    'user-profile': `User Profile`,
    'notification-settings': `Notification Settings`,
    logout: `Logout`,
    'partner-admin': `Partner Admin`,
    'company-security-settings': `Security Settings`,
    'technical-support-details': `Technical Support Details`,
    'account-contacts': `Account Contacts`,
    entitlements: `Company Entitlements`,
    feedback: `Send Feedback`,
  },
  marketplace: {
    'pluralize-provider-type': `Provider Types | Provider Type | Provider Types`,
    'pluralize-locations': `Locations | Location | Locations`,
    profile: `${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE_SHORT} Profile`,
    'currently-private': `Your ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE} profile is currently private, which means our global network of customers cannot find you and connect to your services.`,
    'no-profile': `You do not currently have a ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE_SHORT} profile set up.`,
    visible: `Your profile will be visible in the ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE}`,
    hidden: `Your profile will be hidden from others in the ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE}`,
    services: `${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE} Services`,
    'create-vxc': `Create a new ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} to this ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}`,
    'display-name': `${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE} Display Name`,
    'contact-company': `Contact {company}`,
    'email-sent-to': `Your email has been sent to {recipient}.`,
    'not-ready-publishing': `It looks like your ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE} profile isn't ready for publishing. Click Create Profile to finalise your page so our global network of customers can find you and connect to your services.`,
    'easy-get-connected': `It's easy to get connected. Click Edit Profile and make it available in our ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE}.`,
    'marketplace-intro': `The ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE} connects your business to our global network of customers. This platform enables you to tap into new markets to recognise revenue streams previously unattainable. Completing your profile and keeping it up-to-date greatly improves your selling potential and brand exposure to ready-to-buy customers.`,
    'duplicate-name-warning': `This can cause confusion for people wanting to connect to your ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS}.<br/><br/>Are you sure you want to save?`,
    sending: `Sending Message...`,
    'general-info': `General Service Information`,
    'send-copy': `Send me a copy of the message`,
    message: `Enter your message`,
    sent: `Message Sent`,
    'send-error': `Error sending your message`,
    target: `Connection Target`,
    'connection-from': `Connection From`,
    filter: `Filter by Provider Type`,
    'select-types': `Select the required types and sub-types`,
    'clear-filter': `Clear Filter`,
    'hide-message': `Hide This Message`,
    'hide-today': `Just Today`,
    'hide-week': `For a Week`,
    'hide-forever': `Forever`,
    'edit-profile': `Edit Profile`,
    'save-profile': `Save Profile`,
    'create-profile': `Create Profile`,
    'sort-alpha': `A - Z`,
    'sort-location-count': `Location Count`,
    'grid-display': `Grid display`,
    'list-display': `List display`,
    'company-name-filter': `Company Name Filter`,
    'select-speeds': `Select Speeds`,
    'select-provider-types': `Select Provider Types`,
    'select-countries': `Select Countries`,
    'loading-companies': `Loading companies`,
    'all-services': `all`,
    'selected-services': `selected services`,
    'profile-message-hidden': `Profile Message Hidden`,
    'hidden-day': `This message has been hidden for a day.`,
    'hidden-week': `This message has been hidden for a week.`,
    'hidden-message': `This message will no longer be displayed on this screen, but you can create or edit your ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE_SHORT} profile under the "Company > ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE} Profile" menu option.`,
    'hide-profile': `Hide Profile Message`,
    'hidden-permanently': `This message has been hidden permanently.`,
    'upload-logo': `Upload Logo`,
    'change-logo': `Change Logo`,
    social: `Social`,
    headquarters: `Headquarters`,
    'address-1': `Street Address 1`,
    'address-2': `Street Address 2`,
    city: `City/Suburb`,
    postcode: `Post Code`,
    'point-click': `Point, Click, Connect.`,
    'no-edit-permission': `You do not have permission to edit the profile. Contact a company admin user to edit it.`,
    'loading-editor': `Loading profile editor...`,
    'no-create-permission': `You do not have permissions to create a profile. Contact your company admin to create a profile.`,
    'profile-url': `Profile available at URL:`,
    contact: `Contact`,
    visibility: `Profile Visibility`,
    'company-bio': `Company Bio`,
    'bio-length': `Company Bio (200 words)`,
    'contact-email': `Contact Email`,
    'recently-viewed': `Recently Viewed Providers`,
    'similar-providers': `Similar Service Providers`,
    'duplicated-names': `The following service display names are duplicated:`,
    'duplicate-name': `Duplicate Display Name`,
    description: `Description of Service (200 words)`,
    'service-contact-email': `Service Contact Email`,
    'service-types': `Service Types`,
    'company-profile': `${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE} company profile for company {company}`,
    'live-time': `Live for {time}`,
    state: `State`,
    country: `Country`,
  },
  'mcr-looking-glass': {
    'no-services-message': `You don't have any ${import.meta.env.VITE_EN_PRODUCTNAME_MCR_LONG} (${import.meta.env.VITE_EN_PRODUCTNAME_MCR}) services configured.`,
    'no-bgp-sessions': `There are no BGP sessions for this ${import.meta.env.VITE_EN_PRODUCTNAME_MCR}`,
    'no-services': `No services found`,
    selection: `${import.meta.env.VITE_EN_PRODUCTNAME_MCR} Selection`,
    'routes-table': `Routes Table`,
    'bgp-table': `BGP Table`,
    'all-reachable': `All reachable networks in the ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} routing table.`,
    'all-routes': `All Routes`,
    'routes-by-ip': `Routes by IP`,
    find: `Find routes`,
    loading: `Loading routes`,
    'enter-ip': `Enter an IP or network address to find routes`,
    'no-routes': `There are no routes for this IP address`,
    'no-bgp': `There are no BGP connections attached to this interface`,
    neighbour: `Neighbour Routes`,
    'bgp-sessions': `BGP Sessions`,
    'bgp-sessions-tooltip': `All BGP sessions configured on the ${import.meta.env.VITE_EN_PRODUCTNAME_VXCS} for the selected ${import.meta.env.VITE_EN_PRODUCTNAME_MCR}.`,
    'filter-sessions': `Enter string to filter sessions`,
    'toggle-details': `Toggle additional details`,
    'total-routes': `Total routes`,
    'filter-routes': `Enter text to filter routes`,
    advertised: `Advertised`,
    received: `Received`,
    'sort-by-thing': `Sort by {thing}`,
    'clear-filter-routes': `Clear filter routes`,
    'contact-support': `Contact our Support team to discuss how ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} can benefit you.`,
    'service-could-not-be-loaded': `Service could not be loaded ({errorStatus})`,
    'service-loading-timeout': `We apologize for the inconvenience, but it seems that there was an issue loading this information. If the problem persists, please contact our support team for assistance at ${import.meta.env.VITE_EN_COMPANYINFO_SUPPORT_EMAIL}`,
  },
  'ix-flows': {
    'no-ix-found': `${import.meta.env.VITE_EN_PRODUCTNAME_IX} Service not found`,
    'no-ix-message': `You do not have any ${import.meta.env.VITE_EN_PRODUCTNAME_IX} services. Contact your sales representative if you are in interested in ${import.meta.env.VITE_EN_COMPANYINFO_NAME} ${import.meta.env.VITE_EN_PRODUCTNAME_IX} services.`,
    'date-select': `Date select`,
    'select-peer': `Select a peer`,
    'no-data': `No data is available for this peer`,
    'flow-type': `Flow type`,
    'loading-peers': `Loading peers`,
    'sort-by': `Sort by`,
    'filter-by-name': `Filter by name`,
    'total-peers': `Total peers: {count}`,
    'top-5': `Top 5 peers ({direction})`,
    'volume-in': `Volume (in)`,
    'volume-out': `Volume (out)`,
    'load-error': `There is a problem with loading this data. Please reload to try again.`,
  },
  checkout: {
    'minimum-term-months': `No Minimum Term | Month to Month | Minimum Term: {count} Months`,
    'unknown-validation-error': `Unknown validation error: '{message}'`,
    'hide-promo': `Hide`,
    'add-promo': `Add Code`,
    order: `Order Services`,
    'set-up-company': `Set up your company`,
    'register-markets': `Register markets`,
    'order-now': `Order Now`,
    'need-setup': `Before you can order services, you need to complete the account setup.`,
    'need-company': `You need to set up your company information.`,
    'need-company-permissions': `Your company information is not set up, but you do not have sufficient privileges. Contact your company's administrator.`,
    'need-markets': `You need to enable the required company markets.`,
    'need-markets-permissions': `Your company markets are not enabled but you do not have sufficient privileges. Contact your company's administrator.`,
    validating: `Validating your order`,
    deploying: `Deploying...`,
    'no-term': `No Term`,
    promo: `Promo`,
    'add-promo-code': `Add Promo Code`,
    'promo-code': `Promo Code`,
    'checking-promo': `Checking Promo Code`,
    'promo-validated': `Code Validated`,
    'no-deploy': `Note: Although you can create and save carts, your account permissions restrict you from ordering services.`,
    'invalid-code': `Invalid Code`,
    'code-not-confirmed': `Promo code could not be confirmed`,
    'save-failed': `Failed to save configured services`,
    'error-deploying': `Error deploying services.`,
    'unknown-error': `An unknown error occurred while attempting deployment`,
    deployed: `Services deployed successfully.`,
    success: `Order ID {orderId} successfully deployed services:`,
    'null-code': `Please enter a Promo Code`,
    'capture-tech-support-details': `Capture Technical Support Details`,
    'set-up-tech-support-details': `Technical Support Details`,
    'need-tech-support-details': `Technical Support Contact Details are required before orders can be submitted.`,
    'need-tech-support-details-by-admin': `Technical Support Contact Details will need to be completed by a company admin.`,
    'missing-tech-support-details': `Missing Technical Support Contact Details`,
    'deleting-configuration': `Deleting saved configurations...`,
    'refreshing-services': `Refreshing services...`,
  },
  sidebar: {
    'enable-market': `Enable {country} Market`,
    'configured-services': `Configured Services`,
    'save-configured-services': `Save Configured Services`,
    'save-configured-services-later': `Save these configured services for later.`,
    'saved-configurations-alert': `Saved configurations are company wide and will be visible to anyone in your organisation.`,
    'existing-configuration-warning': `A configuration with this name already exists. Saving with the same name overwrites the previously saved configuration.`,
    'clear-configured-services': `Clear all configured services`,
    'save-configured-name': `Enter a name for the configuration`,
    'saved-configurations': `Saved Configurations`,
    'config-saved-title': `Configuration {name} has been saved`,
    'config-saved-message': `Configuration {name} ({serviceOrder}) successfully saved to server.`,
    'config-save-error-title': `Error saving configuration {name}.`,
    'config-deleted-title': `Configuration deleted`,
    'config-delete-error-title': `Unable to delete configuration {name}.`,
  },
  'billing-markets': {
    'vat-characters': `Enter a VAT {length} characters long`,
    'purchase-order-market': `Invoice Purchase Order number will appear on Invoice header for the {country} market when invoice is generated`,
    'due-date-date': `Due Date: {date}`,
    'remove-card-ending': `Remove card ending in {numbers}`,
    'edit-card-ending': `Edit options for card ending in {numbers}`,
    'pay-card-ending': `Make a payment using your registered card ending in {numbers}.`,
    'remove-card-confirmation': `Do you really want to remove the credit card ending in {numbers} from the ${import.meta.env.VITE_EN_COMPANYINFO_NAME} {marketName} billing market?`,
    'dont-accept-currency': `We don't currently accept {currency} payments via credit card in this market. Refer to your invoice for other payment methods.`,
    title: `Company Billing Markets`,
    enabled: `Enabled Markets`,
    none: `No markets enabled`,
    options: `Options`,
    'view-invoices': `View Invoices`,
    payment: `Make Payment`,
    edit: `Edit Market`,
    view: `View Market`,
    'download-invoices': `Download All Invoices`,
    'download-json': `Download as JSON`,
    'download-csv': `Download CSV`,
    'enable-bm': `Enable a Billing Market`,
    'none-available': `No markets available`,
    enable: `Enable`,
    market: `Market`,
    invoices: `Invoices`,
    'next-invoice': `Next Invoice`,
    disclaimer: `(assuming no speed changes, excl. Taxes)`,
    balance: `Account Balance`,
    'inc-tax': `(inc. Taxes)`,
    'download-statement': `Download Statement`,
    'no-bills': `No Services have been billed in this market yet.`,
    'invoice-no': `Invoice #`,
    'issue-date': `Issue Date`,
    total: `Total`,
    payments: `Payments`,
    'no-stripe': `Stripe payments are currently not available`,
    'cc-history': `Credit Card Payment History`,
    'no-payments': `No payments recorded`,
    description: `Description`,
    'payment-date': `Payment Date`,
    'unavailable-market': `Credit card payments are not accepted for this market. Refer to your invoice for other payment methods`,
    'billing-currency': `Billing Currency`,
    'currency-fixed': `Billing currency cannot be changed`,
    'no-currency-change': `Billing currency cannot be changed once the Market has been set up`,
    language: `Invoice Language`,
    'language-future-invoices': `Note: Changes to invoice language only affect future invoices.`,
    'billing-name': `Billing Contact Name`,
    'billing-phone': `Billing Contact Phone`,
    'phone-format': `International phone number. Example +61 4321 1234`,
    'billing-email': `Billing Contact Email`,
    'email-format': `Either a single email address or a comma-separated list of email addresses`,
    'billing-address': `Billing Address`,
    'address-2': `Address Line 2`,
    city: `City`,
    province: `Province`,
    state: `State`,
    postcode: `Postcode / Zip Code`,
    'select-or-type': `Select or type to filter options`,
    'company-registration': `Company Registration No.`,
    'company-tooltip': `Company Registration Number associated with the purchasing entity.`,
    'vat-registration': `Are you registered for VAT?`,
    'vat-id': `VAT ID No.`,
    'vat-number': `Registered VAT ID number.`,
    po: `Invoice Purchase Order Number`,
    currency: `Market currency`,
    'company-identifier': `Company identifier`,
    'contact-name': `Contact name`,
    'phone-number': `Phone number`,
    address: `Address`,
    'due-unavailable': `Unavailable`,
    vat: `VAT`,
    'register-card': `Register Card`,
    'register-card-status': `Card setup status: {status}`,
    'register-card-edit': `Edit Registered Card`,
    'register-card-fail': `Card Registration Failed`,
    'register-card-success': `Card was registered successfully.`,
    'register-card-fail-message': `Failed to register your card.`,
    'register-card-message': `Register a card with a ${import.meta.env.VITE_EN_COMPANYINFO_NAME} billing market to make automatic payments (after invoice is issued) or one-off manual payments.`,
    'registering-card': `Registering Card...`,
    'remove-card': `Remove Card`,
    'removing-card': `Removing card...`,
    'remove-card-fail': `Error removing card`,
    'remove-card-fail-message': `Remove card failed: {error}`,
    'update-card': `Update Card`,
    'update-card-success': `Card was updated successfully.`,
    'update-card-fail': `Error updating card`,
    'update-card-fail-message': `Update card failed: {error}`,
    'remove-card-success': `Card was removed successfully.`,
    'save-card-fail': `Card Save Failed`,
    'save-card-fail-message': `Unable to save card information: {error}`,
    'payment-once': `Make One-off Payment`,
    'payment-once-make': `Make single one-off payment to your account.`,
    'payment-processing': `Processing Payment`,
    'payment-confirm-fail': `Failed to confirm payment setup`,
    'payment-error': `Unexpected payment error`,
    'payment-success': `Payment successful.`,
    'payment-success-message': `{amount} {currency} was paid to market [{market}]`,
    'card-number': `Card Number`,
    'card-expiry': `Expiry`,
    'card-cvc': `CVC`,
    'auto-payments-on': `Automatic Payments On`,
    'auto-payments-off': `Automatic Payments Off`,
    'payment-amount': `Payment Amount`,
    'payment-description': `Payment Description`,
    'payment-failed': `Payment Failed`,
    'payment-create-failed': `Create Payment Method Failed`,
    'payment-create-failed-message': `Unable to create the Stripe payment method: {error}`,
    'payment-attempt-failed': `Payment attempt failed: {error}`,
    'payment-market-fail': `No payment was made to market [{market}]. {error}`,
    'processing-fees': `Credit Card processing fees are added to the payment amount and reflected on your next invoice. ${import.meta.env.VITE_EN_COMPANYINFO_NAME} passes these fees directly from our service provider.`,
    'account-balance': `Your current account balance is ~ (inc. taxes).`,
    'enable-markets': `Enable Markets`,
    'services-no-market': `You have configured services for markets that have not been enabled yet.`,
    'enable-markets-admin': `The following billing markets will need to be enabled by a company admin:`,
    'no-payment-method': `No payment method data received.`,
    'stripe-error': `Stripe Error`,
    'update-success': `Supply for market "{country}" was successfully updated.`,
    supply: `market supply`,
    'create-success': `Supply for market "{country}" was successfully created.`,
    'payment-docs-html': `You can get additional information on credit card transaction fees from our service provider, Stripe, {link}`,
    'cc-autopay': `Automatic payments will be processed on the 20th of each month for the outstanding balance on your account.`,
    'cc-support-html': `If you have any questions relating to credit card payments, please contact {email}`,
    download: `Download`,
    'due-date': `Due Date`,
    'invoice-date': `Invoice Date`,
    'invoice-amount': `Invoice Amount`,
    'open-balance': `Open Balance`,
    'date-range': `Date Range`,
    'download-pdf': `Download PDF`,
    'download-invoices-info': `Please note: The Download All Invoices function is no longer supported. Please download individual invoices from the View Invoices icon above and selecting a date range.`,
    'billing-address-entry-rules': `This address should be the business location where the key decision makers with regard to procuring ${import.meta.env.VITE_EN_COMPANYINFO_NAME}'s services and putting them into use are based`,
    UK_MOBQ: `Do you use our services in the UK?`,
    UK_MOBQ_TOOLTIP: `Please confirm whether you have personnel in the UK who use these services`,
    AU_MOBQ: `Are you registered for Australian GST?`,
    AU_MOBQ_TOOLTIP: `Please confirm whether the legal entity or branch that uses the services (this could be an affiliate of the entity invoiced by ${import.meta.env.VITE_EN_COMPANYINFO_NAME}) is registered for Australian GST`,
    SG_MOBQ: `Are you registered for Singapore GST?`,
    SG_MOBQ_TOOLTIP: `Please confirm whether the legal entity or branch that uses the services (this could be an affiliate of the entity invoiced by ${import.meta.env.VITE_EN_COMPANYINFO_NAME}) is registered for Singapore GST`,
    'company-type-mexico': `Company Type`,
    'company-type-mexico-tooltip': `Type of taxpayer (individual, legal person or foreign entity)`,
    'tax-regime-code-mexico': `Tax Regime`,
    'tax-regime-code-mexico-tooltip': `Tax Regime - Select the current Fiscal Regime code registered with the Mexican TAX Administration Service (SAT)`,
    'sat-purpose-mexico': `Tax Purpose`,
    'sat-purpose-mexico-tooltip': `Tax Purpose`,
    'rfc-mexico': `RFC Number`,
    'rfc-mexico-tooltip': `Assign the current Federal Taxpayer Registry number - as issued by the Mexican Tax Administration Service (SAT)`,
    'fusf-interstate-traffic-certification': `Federal USF Interstate Traffic Certification *`,
    'fusf-recommended-text': `* It is strongly recommended to read the tooltip for further context`,
    'fusf-tooltip-content': `For data transmission products* with service end points within the same state, Megaport is obligated by the Federal Communications Commission (FCC) to assume that the customer’s traffic ultimately crosses state lines as part of the customer’s wider network unless the customer confirms otherwise.<br/><br/>
      By checking the box, the customer certifies that <strong>more than 10%</strong> of the traffic is interstate in nature in relation to all such products purchased from Megaport. Where this is the case, the services will be subject to Federal Universal Service Fund (FUSF) charges.<br/><br/>
      By not selecting the check-box, the customer certifies that <strong>10% or less</strong> of the traffic transmitted through such products crosses state lines, meaning no FUSF will be applied.<br/><br/>
      An electronic record of the name and title of the account user who has provided the above certification will be generated for Megaport’s records and may be used for audit purposes.<br /><br />
      *Megaport's data transmission products comprise of VXC, Megaport Internet and Internet Exchange`,
  },
  users: {
    'configure-destination-notifications': `Configure {destination} Notifications`,
    'editing-user-name-you': `Editing User {firstName} {lastName} {you}`,
    'user-not-found-redirect': `Could not find user {userId}. Redirecting you to Manage Users page.`,
    'position-not-found': `This user has a role ({position}), which is no longer supported by the system. Select from the available options.`,
    'invalid-email': `{email} is not a valid email address`,
    deactivated: `Show deactivated users`,
    notifications: `Notifications`,
    destinations: `Available Destinations`,
    'no-destinations': `No Destinations Available`,
    'detailed-notifications': `Show Detailed Notifications`,
    filter: `Filter`,
    'no-match': `No Matching Users`,
    active: `User account is active`,
    disabled: `User account is disabled`,
    you: `(You)`,
    new: `New User`,
    'logged-in-as-user': `You are currently logged in as this user. You can modify your user details on the User Profile page.`,
    state: `User State`,
    'technical-admin': `Technical Admin`,
    finance: `Finance`,
    'financial-contact': `Financial Contact`,
    role: `user's role`,
    'role-not-found': `Role Not Found`,
    'user-not-found': `User Not Found`,
    'missing-info': `Missing Info`,
    'change-email': `Change Email`,
    'changing-email': `Changing Email...`,
    'new-email': `New Email`,
    'email-preferences': `Email Preferences`,
    'saving-email-preferences': `Saving Email Preferences...`,
    'receive-newsletter': `Receive ${import.meta.env.VITE_EN_COMPANYINFO_NAME} Newsletter`,
    'receive-promotional-material': `Receive ${import.meta.env.VITE_EN_COMPANYINFO_NAME} Promotional Material`,
    'change-password': `Change Password`,
    'changing-password': `Changing Password...`,
    'old-password': `Old Password`,
    'new-password': `New Password`,
    'retype-new-password': `Retype New Password`,
    'saving-user-profile': `Saving User Profile`,
    'account-password': `Account Password`,
    username: `Username`,
    'loading-qr-code': `Loading QR code`,
    'account-activity': `Account Activity`,
    'account-activity-none': `No activity data`,
    'account-activity-loading': `Loading activity data...`,
    'activity-data-load-fail': `Failed to load activity data`,
    'save-failed': `Save Failed`,
    'save-user-profile-failed': `Failed to save user profile`,
    'save-email-failed': `Change email failed`,
    'save-email-preference-failed': `Failed to save email preferences`,
    'profile-saved': `Profile updated successfully.`,
    'email-change-success': `Changed email preferences.`,
    'save-password-failed': `Password Save Failed`,
    'save-password-failed-message': `Failed to save updated password`,
    'saving-user-profile-error': `Error updating profile`,
    profile: `Profile`,
    'user-name': `User {firstName} {lastName}`,
    'verification-email': `A verification email has been sent to your new address.`,
    'verification-message': `Follow the prompts on the email.`,
    'user-role': `User Role`,
    'viewing-user-name-you': `Viewing User {firstName} {lastName} {you}`,
    'cant-edit-invite-pending': `This user profile cannot be edited until the user has accepted the invite. Please delete the user and re-invite if changes are needed immediately.`,
    'deleting-user': `Deleting user...`,
    'disable-mfa': `Disable MFA`,
    'enable-mfa': `Enable MFA`,
    'reset-mfa': `Reset MFA`,
    'disable-mfa-dialog': `Disable Multi-factor Authentication`,
    'enable-mfa-dialog': `Enable Multi-factor Authentication`,
    reset: `Reset`,
    'mfa-long': `Multi-Factor Authentication`,
    'reset-mfa-dialog': `Reset Multi-factor Authentication`,
    'reset-mfa-success': `Multi-factor Authentication reset successfully`,
    'reset-mfa-failed': `Error resetting Multi-factor Authentication`,
    'reset-mfa-dialog-desc': `You are about to reset your Multi-factor Authentication secret. Once you have scanned the QR code and confirmed it, the new secrets will become active and all old secrets on devices will be invalidated.`,
    'mfa-enforced-disable-tooltip': `Your company Administrator has set a global policy enforcing MFA. You are unable to disable MFA while this is in place.`,
    'position-header': `Role`,
    'mfa-header': `MFA`,
    'mfa-tooltip-not-set': `User has not set up Multi-Factor Authentication`,
    'mfa-tooltip-set': `User has set up Multi-Factor Authentication`,
    set: `Set`,
    'not-set': `Not Set`,
    enforced: `Enforced`,
    optional: `Optional`,
    'partner-only': `Partner Only`,
    'partner-only-tooltip': `Configure email notifications associated to only your Partner account`,
    'partner-and-managed': `Partner and Managed`,
    'partner-and-managed-tooltip': `Configure email notifications associated with both your Partner & all Managed accounts`,
    'company-admin-reset-mfa-confirm-title': `Are you sure you want to reset Multi-Factor Authentication?`,
    'company-admin-reset-mfa-confirm-message': `Ensure that {username} which you are requesting the reset for is an actual person, and not an account used for Machine to Machine integrations. After MFA is reset, the user will be asked to reset their credentials at login.`,
    'company-admin-reset-help-text': `Help this user reset their account. This will require the user to enrol a new device`,
    'mfa-alt-tag': `Use your camera to scan the QR code`,
    'mfa-app-suggestion': `Google Authenticator is our recommended authentication app.`,
    'mfa-token': `Enter generated one-time token`,
    'mfa-loading-failed': `Failed to load QR code,`,
    'mfa-disabled': `Multi-factor authentication disabled`,
    'enable-mfa-success': `Multi-factor authentication enabled successfully.`,
    'disable-mfa-fail': `Error disabling multi-factor authentication`,
    'view-user-actions': `View all user actions`,
    'mfa-warning-1': `Ensure that the account registered in your multi-factor authentication app is removed.`,
    'mfa-warning-2': `You will be logged out upon disabling.`,
    'loading-users': `Loading company users...`,
    'sso-compatibility': `SSO Compatibility`,
    'sso-enabled-tooltip': `Users email domain is linked to SSO and they will utilise SSO by default for logins`,
    'sso-enforced-no-access-tooltip': `SSO is enforced and user is NOT SSO compatible. Access Prohibited`,
    'sso-optional-can-login-tooltip': `Users email domain is NOT SSO compatible though the user can login via other methods`,
    'active-api-keys-found': `Active API keys found`,
    'active-api-keys-inactive-if-continue': `Active API Keys have been found for this user; these will become inactive if you continue. All API Keys can be reviewed though Tools - API Key Generator`,
    'active-api-keys-input-label': `To proceed, type Inactivate and then click Continue, or click Cancel to abort.`,
    'active-api-keys-error': `Type Inactivate to proceed`,
    'active-api-keys-review': `It is recommended to review if the API Keys generated by this user are used in a system critical integration between your company and ${import.meta.env.VITE_EN_COMPANYINFO_NAME}.`,
  },
  'api-keys': {
    'description-intro': `If you need direct access to the ${import.meta.env.VITE_EN_COMPANYINFO_NAME} API, you need an API key.`,
    'description-html': `API keys are only valid in the environment they were generated in. You are currently logged in to the {environment} environment, so any keys created and listed here will work in the {environment} environment. If you need keys to access a different environment, log into the ${import.meta.env.VITE_EN_COMPANYINFO_PORTAL_SHORT} in that environment and generate keys there.`,
    'learn-more-html': `Learn more about environments in the {link}`,
    forbidden: `You can not create or manage API keys unless you are an administrator of a company set up with a billing market enabled.`,
    'key-description': `The API key is a unique identifier that authenticates requests for usage and billing purposes. Use the key to generate a token to authenticate API requests.`,
    'key-generated-message': `API key {key} was generated`,
    'key-updated-message': `API key {key} was updated`,
    'key-deleted-message': `API key {key} was deleted`,
    'key-regenerated-message': `New secret was generated for API key {key}`,
    'key-dialog-description': `Your API key has been created successfully. Copy your new API Key and API Key Secret and use them to generate an API token.`,
    'role-change': `Are you sure you want to change the <strong>{oldRole}</strong> role to <strong>{newRole}</strong>?<br/><br/>Company Admin has full API access and can create, manage, and cancel services. Read Only can see usage data and configuration details, but cannot make any changes.`,
    'delete-key': `Are you sure you want to delete this key? Once deleted, you cannot use the key to generate new tokens.`,
    'regenerate-secret': `Regenerating your secret will cancel all sessions using the old secret. You will need to use the new secret to generate tokens to use this key. Are you sure you want to regenerate your secret?`,
    'api-keys': `API Keys`,
    'read-only': `Read Only is the most restrictive role. Note that a Read Only user can view service details which you may want to keep secure and private.`,
    admin: `Company Admin`,
    'unknown-role': `Unknown role`,
    'your-api-key': `Your API Key`,
    'api-key': `API Key`,
    secret: `API Key Secret`,
    'secret-message': `You won't be able to regenerate or see this secret again. Make sure to store it in a safe place.`,
    history: `API Key History`,
    'history-order': `Most recent changes are at the top`,
    'edited-by': `Edited By`,
    'update-date': `Last Update`,
    role: `Role`,
    created: `Key created`,
    updated: `Key updated`,
    regenerated: `Secret regenerated`,
    'unknown-action': `Unknown key action`,
    'unknown-user': `Unknown user`,
    'unable-to-read': `Unable to read API keys`,
    key: `Key`,
    'generate-key': `Generate Key`,
    'generate-label': `Generate API Keys`,
    generating: `Generating API key`,
    'read-only-impersonate': `Since you are impersonating another user, you have read only access to any keys on the account.`,
    'success-generate': `API key generated successfully.`,
    'failed-generate': `API key generation failed`,
    'success-update': `API key updated successfully.`,
    'failed-update': `API key update failed.`,
    'success-delete': `API key deleted successfully.`,
    'failed-delete': `API key deletion failed.`,
    'active-keys': `Active API Keys`,
    'no-active-keys': `You currently have no active keys. Generate a key to get started.`,
    'active-tooltip': `You will need the key and the secret to generate the access token. You would have been shown the secret once when you generated the key or generated a new secret for the key.`,
    'date-created': `Date Created`,
    'added-by': `Added by`,
    'loading-user-data': `Loading user data`,
    'loading-api-keys': `Loading existing API keys`,
    'deleting-api-keys': `Deleting API key`,
    'regenerating-secret': `Regenerating secret`,
    'saving-changes': `Saving changes`,
    'regenerate-secret-label': `Regenerate Secret`,
    'view-history': `View History`,
    'confirm-role-change': `Confirm Role Change`,
    'delete-key-label': `Delete Key`,
    'company-admin': `Company Admin users have access to all user privileges. We recommend limiting the number of Company Admin users to only those who require full access, but defining at least two for redundancy.`,
    'technical-admin': `This role is for technical users who know how to create and approve orders.`,
    'technical-contact': `This role is for technical users who know how to design and modify services but don’t have the authority to approve orders.`,
    finance: `Finance users should have a financial responsibility within the organization while also understanding the consequences of their actions if they delete or approve services. {note}`,
    'financial-contact': `This user role is similar to the Finance role without the ability to place and approve orders, delete services, or administer service keys.`,
    'read-only-short': `Read Only`,
    'token-expiry': `Token Expiry (Minutes)`,
    'expiry-min': `Minimum 5 minutes`,
    'expiry-max': `Maximum 24 hours (1440 minutes)`,
    'api-resource': `API Resource`,
    action: `Action`,
    'name-min': `Minimum 3 characters`,
    'name-max': `Maximum 90 characters`,
    'name-chars': `No special characters except dashes and underscores`,
    'name-invalid': `Name is invalid`,
    'api-documentation': `${import.meta.env.VITE_EN_COMPANYINFO_NAME} API Documentation`,
    'finance-note': `Note: While creating services, direct customers can see the cost of the service before deploying. It is also possible to see the cost of deployed services by selecting a service in the ${import.meta.env.VITE_EN_COMPANYINFO_PORTAL_SHORT} and viewing the Details tab.`,
    'api-role': `API`,
    'key-active-tooltip': `Key is active`,
    'key-inactive-tooltip': `Key is inactive due to user deactivation. Unless the user who created this key is made active again, this key cannot be used. You may consider deleting this key and creating a new API Key against an active user.`,
  },
  debug: {
    information: `Debug Information`,
    'include-info': `Include the above information if you plan on creating a ticket.`,
  },
  pricebook: {
    product: `Product`,
    'monthly-rate': `Monthly Rate`,
    'excludes-tax': `Price Excludes Tax`,
    'contact-sales': `Contact Sales for custom pricing.`,
    failed: `Failed to obtain price for service {product}`,
    'monthly-charges-excl-taxes': `Monthly charges excluding taxes`,
    'price-change-1': `The current monthly recurring charge for this service is {currentMonthlyPrice} (excl. tax). Provided there are no further speed changes, this service will be charged at {totalPrice} (excl. tax) for the current billing period. This is {changeDirectionText} of {deltaPrice} (excl. tax). The new estimated monthly recurring charge for this service will be {longTermMonthlyPrice} (excl. tax).`,
    'price-change-2': `The changes to this service will result in a price change. If there are no further speed changes, the cost for this service will be {totalPrice} (excl. Taxes) for this billing period. This is {changeDirectionText} of {deltaPrice} (exc. Taxes).`,
    'price-change-3': `The new monthly charge for this service will be {longTermMonthlyPrice} (excl. Taxes). Confirm these changes.`,
  },
  'price-change': {
    detected: `Rate Change Detected`,
    cancel: `No, cancel changes`,
    accept: `Yes, accept changes`,
    'current-month': `Current Month Charge`,
    'new-monthly': `New Monthly Charge`,
    'this-period': `This Billing Period`,
    change: `Change`,
    'pricing-model': `Making changes to this service will result in a price alteration in accordance with our new pricing model update.`,
    'confirm-model-change': `Once this change is accepted, it will not be possible to revert to the old pricing and the next new invoice issued will reflect this. Confirm that you want to accept these changes.`,
    increase: `an <strong>increase</strong>`,
    decrease: `a <strong>decrease</strong>`,
    'current-monthly': `Current Monthly Charge`,
  },
  cancel: {
    immediate: `Immediate Cancellation`,
    'remove-configured': `Remove Configured Services`,
    terminate: `Terminate Services`,
    'keep-configured-service': `No, Keep Configured Service`,
    'keep-configured-services': `No, Keep Configured Services`,
    'remove-configuration': `Yes, Remove Configuration`,
    'keep-services': `No, Keep Services`,
    'clear-configured': `Are you sure you want to clear all your configured services?`,
    'remove-from-configured': `Are you sure you want to remove this service from your configured services?`,
    'end-of-contract': `Terminate at the end of contract`,
    later: `Later`,
    'terminate-immediately': `Terminate immediately`,
    now: `Now`,
    'now-confirmation': `Selecting "Terminate Services" will confirm the termination process. These services will be removed immediately. An early termination fee (ETF) will apply to the following service(s):`,
    'later-confirmation': `Selecting "Terminate Services" will confirm the termination process. These services will continue to operate as normal until the date listed on the service below. An early termination fee (ETF) will apply to the following service(s):`,
    unable: `Unable to cancel:`,
    better: `Anything we could have done better?`,
    'no-permission': `You do not have permission to delete services`,
    error: `Error loading cancellation data`,
    diversity: `Diversity Relationship Found`,
    'issues-terminating': `0 | Issues terminating 1 Service. | Issues terminating {count} Services.`,
    terminating: `0 | Terminating 1 Service. | Terminating {count} Services.`,
    terminated: `0 | Terminated 1 Service. | Terminated {count} Services.`,
    pending: `Pending Termination`,
    'do-not-restore': `No, Do Not Restore Service`,
    restore: `Yes, Restore Service`,
    restoring: `Restoring Service...`,
    'cancel-termination-confirmation': `This service has been scheduled for termination. Would you like to cancel this termination request and restore the service?`,
    restored: `Restored pending terminated Service.`,
    'error-uncancelling': `Error occurred uncancelling service.`,
  },
  canada: {
    ab: `Alberta`,
    bc: `British Columbia`,
    mb: `Manitoba`,
    nb: `New Brunswick`,
    nl: `Newfoundland and Labrador`,
    nt: `Northwest Territories`,
    ns: `Nova Scotia`,
    nu: `Nunavut`,
    on: `Ontario`,
    pe: `Prince Edward Island`,
    qc: `Quebec`,
    sk: `Saskatchewan`,
    yt: `Yukon`,
  },
  company: {
    'view-markets': `View Company Markets`,
    'company-legal-name': `Company Legal Name`,
  },
  alibaba: {
    'connection-details': `Connection details for Alibaba Service`,
    'account-id': `Alibaba Cloud Account ID`,
    'account-tooltip': `Obtain this from your Alibaba account`,
    connection: `Alibaba Connection`,
    'connection-id': `Alibaba Connection ID`,
  },
  amsix: {
    'connection-details': `Connection details for AMS-${import.meta.env.VITE_EN_PRODUCTNAME_IX} Service`,
    peer: `Peer with Route Servers?`,
    selective: `Selective`,
    'noc-contact': `NOC Contact`,
    'url-tooltip': `AMS-${import.meta.env.VITE_EN_PRODUCTNAME_IX} services require a web URL for advertising company information on the ${import.meta.env.VITE_EN_PRODUCTNAME_IX}`,
    'email-tooltip': `AMS-${import.meta.env.VITE_EN_PRODUCTNAME_IX} services require an email for advertising company information on the ${import.meta.env.VITE_EN_PRODUCTNAME_IX}`,
    'select-country': `Select Country`,
    'admin-phone-tooltip': `A phone number can be added by a company admin on the Manage Users screen, or by the user to their user profile.`,
    'phone-tooltip': `You can update your phone number in your user profile; otherwise contact your company admin.`,
  },
  aws: {
    'connection-details': `Connection details for AWS Service`,
    'edit-warning': `Editing the AWS details on this service initiates a rebuild and requires acceptance as a new VIF connection request through the AWS console of the receiving account. Confirm before making changes.`,
    'warning-button': `I Understand`,
    'start-editing': `Click the button above to start editing`,
    'no-edit-hosted': `Note: Hosted Connection AWS details are not editable.`,
    'connection-name': `AWS Connection Name`,
    'connection-name-tooltip': `Label the service in AWS`,
    'account-id': `AWS Account ID`,
    'account-id-tooltip': `Obtain this from your AWS account setting in the AWS console`,
    'bgp-asn': `Public or Private BGP ASN`,
    'amazon-asn': `Amazon ASN`,
    'default-amazon-asn': `Leave blank to use default`,
    'bgp-key': `BGP Auth Key`,
    'bgp-key-tooltip': `Enter a BGP Auth Key / Password (6-24 characters, no whitespace). If you don't provide this, we'll generate one for you`,
    'amazon-prefixes': `Amazon Prefixes`,
    'amazon-prefixes-tooltip': `Prefixes to announce to AWS, RIR-assigned IPv4 address ranges only`,
    'connection-type': `AWS Connection Type`,
    'hosted-vif': `Hosted VIF`,
    'hosted-vif-description': `Hosted VIFs (Hosted Virtual Interfaces) can connect to public or private AWS cloud services.`,
    'hosted-connection': `Hosted Connection`,
    'documentation-html': `For further information about AWS connection options, click here: {link}`,
    'diversity-html': `For details, see the {link}`,
    'vif-connection': `AWS VIF Connection`,
    'vif-id': `VIF ID`,
    label: `AWS Label`,
    'customer-address': `Customer Address`,
    'amazon-address': `Amazon Address`,
    'aws-hosted-connection': `AWS Hosted Connection`,
    'aws-vif': `AWS VIF`,
    'connection-id': `AWS Connection ID`,
    'manual-intervention': `Note: Public VIF's require manual intervention from Amazon and could take up to 48 hours.`,
    'hosted-connection-description': `Each Hosted Connection ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} supports a single AWS VIF to either a public, private, or transit gateway.`,
    'key-features': `Key features include:`,
    'aws-vif-feature-list-1': `AWS port fee is included with the ${import.meta.env.VITE_EN_COMPANYINFO_NAME} connection.`,
    'aws-vif-feature-list-2': `Managed and monitored shared customer bandwidth.`,
    'aws-vif-feature-list-3': `Ability to change the ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} speed.`,
    'aws-vif-feature-list-4': `Configurable in 1 Mbps ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} increments between 1 Mbps - 5 Gbps.`,
    'aws-vif-feature-list-5': `Automatic configuration of attached ${import.meta.env.VITE_EN_PRODUCTNAME_MCR_LONG}.`,
    'aws-hosted-feature-list-1': `AWS ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} fee is billed through Amazon.`,
    'aws-hosted-feature-list-2': `Dedicated bandwidth.`,
    'aws-hosted-feature-list-3': `Set ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} speeds increments from 50 Mbps to 10 Gbps.`,
    'aws-hosted-feature-list-4': `No ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} speed changes.`,
    'aws-hosted-feature-list-5': `Support for diverse ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} for resiliency. Colour coding highlights diverse ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS}.`,
    'documentation-link': `AWS Connection Overview - ${import.meta.env.VITE_EN_COMPANYINFO_NAME} Documentation`,
    'readonly-warning': `Editing the AWS details on this service initiates a rebuild and requires acceptance as a new VIF connection request through the AWS console of the receiving account.`,
    onboarding: `AWS Onboarding`,
    'onboarding-reachout': `A member of ${import.meta.env.VITE_EN_COMPANYINFO_NAME} will reach out to you to assist with onboarding in the next 1-2 business days.`,
    'onboarding-questions': `If you have any questions in the meantime we can be contacted at {email}`,
    'onboarding-info': `Please provide as much information as possible, including AWS offer or agreement ID.`,
    'onboarding-success-title': `AWS Onboarding Successful`,
    'onboarding-success': `Thank you for submitting. A member of ${import.meta.env.VITE_EN_COMPANYINFO_NAME} will reach out to you to assist with onboarding in the next 1-2 business days.`,
  },
  azure: {
    'primary-subnet': `Primary Subnet`,
    'primary-subnet-placeholder': `Primary Subnet, for example 192.0.1.32/30`,
    'secondary-subnet': `Secondary Subnet`,
    'secondary-subnet-placeholder': `Secondary Subnet, for example 192.0.1.32/30`,
    'public-prefixes': `Public Prefixes`,
    'public-prefixes-placeholder': `Public Prefixes, for example 192.0.2.0/24,192.0.2.0/30`,
    'shared-key': `Shared Key`,
    'update-peer': `Update Peer`,
    'editing-message': `The connection details for this service need to be edited from the Azure end.`,
    'no-peers': `No Peers Defined`,
    'peer-type': `Peer Type`,
    'disable-peering-type': `Are you sure you want to disable this peering type?`,
    'disable-peer-type': `Disable Peer Type`,
    configuration: `Azure Configuration`,
    'express-route-html': `${import.meta.env.VITE_EN_COMPANYINFO_NAME} delivers ExpressRoute services to primary or secondary Microsoft ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} as Q in Q (double tagged, or 802.1ad). Ensure that you are familiar with {link} at the Microsoft site.`,
    'peering-html': `Public, Private, and Microsoft peering types are available through this method using a single ExpressRoute service key. Refer to {link} for more information on these peering types and availability.`,
    'get-service-key': `How to get an Azure ExpressRoute service key.`,
    'azure-service-key': `Microsoft Azure Service Key`,
    'peering-type': `Peering Type`,
    'peering-type-explanation': `Peering Type has already been set for this service key on another service.`,
    microsoft: `Microsoft`,
    'back-to-service-key': `Return to Service Key to enter your key and create the connection.`,
    interface: `Azure Interface`,
    'q-in-q': `Q-in-Q interface definitions`,
    'ms-doc': `Microsoft documentation`,
  },
  google: {
    'cloud-configuration': `Google Cloud Configuration`,
    'gci-configuration': `GCI Configuration`,
    'partner-peering-key': `Google Partner Pairing Key`,
    'show-all-locations': `View all Google Partner locations`,
    'back-to-enter-peering': `Back to enter Pairing Key`,
    interface: `GCI Interface`,
    connection: `Google Connection`,
  },
  ix: {
    'ecix-asn-fixed': `Note: ASN cannot be modified for ECIX services after deployment.`,
    'bgp-password-tooltip': `Enter a BGP Password if you want to set one.`,
    'bgp-password-warning': `Note: BGP password cannot be modified after deployment.`,
    'looking-glass-graphs': `Usage graphs will be visible in Looking Glass.`,
    'no-looking-glass-graphs': `Usage graphs will not be visible in Looking Glass.`,
  },
  'mcr-config': {
    'enable-qinq': `Enable QinQ?`,
    invalid: `Invalid`,
    'add-vlan': `Add VLAN`,
    'virtual-lan': `Virtual LAN`,
    'unique-id': `Provide a unique ID for the VLAN`,
    'vlan-id': `VLAN ID`,
    'delete-vlan': `Delete VLAN`,
    'interface-ip-addresses': `Interface IP Addresses`,
    'ip-subnet': `IP address and subnet mask`,
    nat: `Network Address Translation (NAT)`,
    'nat-source': `NAT Source IP address`,
    'nat-source-tooltip': `Select an IPv4 address to enable source NAT on this interface. All packets leaving this interface will have their source address rewritten to the selected address.`,
    'nat-enabled': `NAT Enabled`,
    'bgp-connections-description': `BGP peering relationships for this interface - maximum of five. Requires an Interface IP Address to be created.`,
    'bgp-add': `Add BGP Connection`,
    'bfd-settings': `Bidirectional Forwarding Detection (BFD) Settings`,
    'no-bfd-enabled': `No BGP Connections are BFD enabled`,
    'transmit-interval': `Transmit Interval`,
    'receive-interval': `Receive Interval`,
    multiplier: `Multiplier`,
    'bgp-connection': `BGP Connection`,
    'disable-qinq-message': `Disabling Q-in-Q will remove all settings.`,
    confirm: `Are you sure you want to continue?`,
    'delete-vlan-message': `This will delete this VLAN and all its settings. Continue?`,
    'nat-is-enabled': `NAT is enabled`,
    'no-prefix-filter': `No Prefix Filter`,
  },
  nutanix: {
    configuration: `Nutanix Configuration`,
    'service-key': `Nutanix Service Key`,
    'service-key-notice': `You will need a Nutanix service key to connect to a Nutanix ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}. If you do not have a service key, visit {link} to obtain one.`,
    interface: `Nutanix Interface`,
    'accept-speed-change': `Note: The speed setting on your Nutanix service key has been updated. Click Save to accept the new rate limit.`,
    nutanix: `Nutanix`,
  },
  oracle: {
    configuration: `Oracle Configuration`,
    'virtual-circuit-id': `Oracle Virtual Circuit ID`,
  },
  salesforce: {
    configuration: `Connection details for Salesforce Service`,
    approvals: `Salesforce connection approvals can take up to two US business days.`,
    'provide-password': `Enter a BGP Password. If you don't provide one, we'll generate one for you.`,
    prefixes: `Prefixes`,
    'prefixes-tooltip': `Prefixes to announce to Salesforce, RIR-assigned IPv4 address ranges only.`,
    connection: `Salesforce Connection`,
    'permitted-prefixes': `Permitted BGP Prefixes`,
  },
  'target-select': {
    'no-bio': `No company bio available.`,
    'marketplace-html': `View more information on their {link}.`,
    'hide-info': `Hide information panel`,
    'show-info': `Show information about the options`,
  },
  'target-service-key': {
    'destination-found': `Destination Found`,
    'key-name': `Key Name`,
    'max-speed': `Max Speed`,
  },
  events: {
    session: `Session Event Log`,
    clear: `Clear All Events`,
    cleared: `The session event log has been cleared.`,
  },
  graph: {
    private: `This company's data is private`,
    'load-error': `Failed to load graph data`,
    'no-data': `No graph data available`,
    'in-mbps': `In Mbps`,
    'out-mbps': `Out Mbps`,
    'billing-month': `Billing Month:`,
    'a-end-not-owned': `No billing information since the A-End of this connection is owned by another company.`,
    'load-failed': `Failed to load required data`,
    'no-billing-data': `No billing data available`,
    estimated: `Estimated Next Bill (excl. Taxes) for this service.`,
    calculated: `Bill (excl. Taxes) for this service.`,
    'too-many-changes': `Too many speed changes to graph. Download the CSV to view the data.`,
    'configured-speed': `Configured Speed`,
    'billing-details': `Billing Details`,
    'estimated-next': `Estimated next bill (excl. taxes), assuming no changes to the service.`,
    speed: `Speed (Mbps)`,
    download: `Download CSV`,
    'service-graph': `Service Graph`,
    'date-range': `Date Range:`,
    'last-12-h': `Last 12 Hours`,
    'last-24-h': `Last 24 Hours`,
    'last-7-d': `Last 7 Days`,
    'last-4-w': `Last 4 Weeks`,
    metrics: `Metrics:`,
    source: `Source:`,
    timezone: `Timezone:`,
    'nothing-selected': `Nothing selected to graph`,
    'date-time': `Date / Time`,
    in: `In`,
    out: `Out`,
    'last-hour': `N/A | Last hour | Last {count} hours`,
    'last-week': `N/A | Last week | Last {count} weeks`,
    'last-month': `N/A | Last month | Last {count} months`,
    // Coordinated Universal Time
    utc: `UTC`,
    'export-csv': `Export CSV`,
    'downloading-csv': `Downloading CSV`,
  },
  'looking-glass': {
    'select-ix': `Select ${import.meta.env.VITE_EN_PRODUCTNAME_IX}`,
    title: `Looking Glass`,
    select: `Select Internet Exchange`,
    filter: `Type to filter by name or ASN`,
    'no-peers': `No available peers`,
    'loading-peers': `Loading Peer List...`,
    rs1: `RS1`,
    'route-server-1': `Route Server 1`,
    rs2: `RS2`,
    'route-server-2': `Route Server 2`,
    'extended-details': `Extended peer details`,
    routing: `Routing Information`,
    routes: `Routes`,
    imported: `Imported`,
    best: `Best`,
    filtered: `Filtered`,
    loading: `Loading graph data...`,
    'loading-routes': `Loading routes...`,
    'route-load-failed': `Failed to load route data`,
    'no-routes': `No available routes`,
    'local-pref': `Local Pref`,
    'origin-asn': `Origin ASN`,
    'asn-path': `ASN Path`,
    since: `Since`,
    'route-data-load-failed': `Failed to load the route data`,
    'detail-data-load-failed': `Failed to load details data`,
    'ip-unavailable': `IPv4 and IPv6 unavailable.`,
    'protocol-unavailable': `{protocol} is unavailable.`,
    'ip-available': `IPv4 and IPv6 are available.`,
    asn: `ASN:`,
  },
  'image-upload': {
    select: `Select Image`,
    crop: `Crop Image`,
    'drop-image': `Drop image file here or click to upload`,
    clear: `Clear Image`,
  },
  'no-services': {
    message: `Fast, flexible interconnectivity is just a few clicks away.`,
    action: `Let's get you connected`,
    instructions: `To get started, create a ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} from the buttons above.`,
  },
  'google-login': {
    unavailable: `Google Login Unavailable`,
    loading: `The Google services are still loading - please try again shortly.`,
    problem: `There was a problem loading the Google authorization services. This issue has been reported to our developers. Please use username and password authentication for the moment.`,
    cancelled: `Google Login Cancelled`,
    'cancelled-message': `Google login attempt was cancelled by you.`,
    blocked: `Google Login Blocked`,
    'blocked-message': `Google login requires a popup window that is blocked by your browser. Please allow popups on this site.`,
    'cookies-blocked-message': `Your browser is set to block third-party cookies. To use Google login, allow third party cookies, then reload the page.`,
    'browser-blocked-message': `Google login could not run on your browser. Details are as follows: {details}`,
    failed: `Google Login Failed`,
    'failed-message': `Failed to log you in using Google.`,
    'token-received': `Google token received, trying to log in...`,
    'no-details': `No additional details.`,
    'token-failed-message': `There was an error verifying your Google login token. Please try logging in again or clear your browser cookies if the problem persists.`,
  },
  'whitelabel-dlr': {
    welcome: `Welcome to DLR`,
    'uptimes-blog': `Uptimes Blog`,
    twitter: `Follow us on Twitter`,
    linkedin: `Follow us on Linkedin`,
    conditions: `See our terms and privacy conditions`,
    contact: `Contact and support`,
    careers: `Careers - We're hiring`,
  },
  tooltips: {
    'route-prefix': `An IPv4 or IPv6 network address in CIDR notation.`,
    'route-next-hop': `The IP address of the next-hop router.`,
    'required-item-start': `Items marked with a`,
    'required-item-end': `are required fields.`,
    'local-ip-address': `The IPv4 or IPv6 address on this interface to use for communication with the BGP peer.`,
    'peer-ip-address': `The IP address of the BGP peer.`,
    'peer-asn': `The ASN of the remote BGP peer. 4-byte AS numbers are supported in the asplain format.`,
    'bgp-password': `A shared key used to authenticate the BGP peer, up to 25 characters.`,
    'bgp-description': `A description for the BGP connection, up to 100 characters.`,
    'bgp-state': `By default, BGP connections are enabled and will actively attempt to connect to the peer. Select shutdown to temporarily disable the BGP session without removing it. This may be useful for troubleshooting or testing fail over scenarios.`,
    'med-inbound': `The MED will be applied to all routes received on this BGP connection. Leave blank to use the value received from the BGP peer. The route with the lowest value will be preferred.`,
    'med-outbound': `The MED will be applied to all routes transmitted on this BGP connection. The neighbouring autonomous system may prefer the lowest value at their discretion.`,
    bfd: `Bidirectional Forwarding Detection allows for rapid fault detection on this BGP connection. When connectivity is disrupted, the BGP session is torn down without waiting for the BGP hold timer to expire. Use of BFD can improve failover time when multiple routes are available. Note: These settings will be used for all BGP connections on this VXC, where BFD is enabled.`,
    'bfd-group': `Note: These settings will be used for all BGP connections on this interface where BFD is enabled.`,
    'bfd-transmit': `The minimum time between sending BFD packets to the neighbour. The supported range is 300ms to 9000ms.`,
    'bfd-receive': `The minimum time between BFD packets that a neighbour should send. The supported range is 300ms to 9000ms.`,
    'bfd-multiplier': `The BGP session will be torn down if this many consecutive BFD packets are not received from the neighbour.`,
    med: `Multi-Exit Discriminator can be used to influence route preference when multiple routes to the same destination are available. It is commonly used to preference specific primary and secondary paths. Values between 0 and 4,294,967,295 are supported.`,
    'bgp-config-description': `Routes received from this BGP peer will be advertised to other BGP peers on this ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} <br /> You can advertise routes to all peers by default and filter routes to specific peers. Or, you can filter routes to all peers by default and advertise routes to specific peers.`,
    'bgp-prefix-filter': `<p>Apply a predefined prefix filter to this BGP peer to limit the set of routes that will be received from, or advertised to the peer.</p>
      <ul>
      <li>No Prefix Filter allows all routes. No routes will be filtered.</li>
      <li>A Permit List allows only routes that match the prefix list. Other routes will be filtered.</li>
      <li>A Deny List allows all routes except those that match the selected prefix list.</li>
      </ul>
      <p>To define prefix filter lists, select the Prefix Filter Lists tab on the ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} Configuration page.</p>
      `,
    'bgp-prefix-editor': `
          <p>
          The ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} Prefix Filter List is a named list of IP network addresses. Create a prefix filter list to match and filter routes based on the route’s prefix and apply the filter to
          routes received from or advertised to BGP peers on this ${import.meta.env.VITE_EN_PRODUCTNAME_MCR}.
          The ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} compares each route prefix against the conditions in the list, one match at a time, from the top down to deny or advertise the route.
          </p>
          <ul>
          <li>A prefix filter list can be applied multiple times to different BGP peers.</li>
          <li>Up to 50 prefix filter lists may be configured on each ${import.meta.env.VITE_EN_PRODUCTNAME_MCR}.</li>
          <li>Each list is able to contain up to 200 entries.</li>
          </ul>
         `,
    'as-path-prepend-tooltip': `The Local ASN will be prepended to the AS path of outgoing BGP route announcements. Enter a number from 0 to the maximum of 10 prepends.`,
    'shutdown-state': `Update the configured state to Shut Down to test how the service would behave on the ${import.meta.env.VITE_EN_COMPANYINFO_NAME} network if the service was down.`,
    'move-connection-limits': `Only ports of the same product type and within the same metro are available for selection`,
    'move-connection-vnic': `${import.meta.env.VITE_EN_PRODUCTNAME_VXC} A-end or B-end designation was determined when the ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} was created.`,
  },
  time: {
    min: `min`,
    s: `s`,
    ms: `ms`,
    µs: `µs`,
  },
  'page-titles': {
    login: `Login`,
    'user-profile': `User Profile`,
    'user-notifications': `User Notifications`,
    services: `Services`,
    dashboard: `Dashboard`,
    'create-port': `Create ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}`,
    mve: `${import.meta.env.VITE_EN_PRODUCTNAME_MVE}`,
    'create-connection': `Create Connection`,
    'edit-port': `Edit ${import.meta.env.VITE_EN_PRODUCTNAME_PORT}`,
    'edit-connection': `Edit Connection`,
    'company-profile': `Company Profile`,
    'company-users': `Company Users`,
    'email-confirmation': `Email Confirmation`,
    'marketplace-profile': `${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE} Profile`,
    marketplace: `${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE}`,
    debug: `Debugging Information`,
    'looking-glass': `Looking Glass`,
    'mcr-looking-glass': `${import.meta.env.VITE_EN_PRODUCTNAME_MCR} Looking Glass`,
    'event-log': `Session Event Log`,
    'ix-flows': `${import.meta.env.VITE_EN_PRODUCTNAME_IX} Flows`,
    'api-keys': `API Keys`,
    'partner-admin': `Partner Admin`,
    'managed-account': `Managed Account`,
    'billing-markets': `Billing Markets`,
    onboard: `Onboard`,
    signup: `Signup`,
    'signup-google': `Google Signup`,
    admin: `Admin`,
    'logging-in': `Logging in`,
    'company-security-settings': `Company Security Settings`,
    'company-support': `Technical Support Contact Details`,
    'setup-mfa': `Multi-Factor Authentication Setup`,
    mfa: `Multi-Factor Authentication`,
    'create-mcr': `Create ${import.meta.env.VITE_EN_PRODUCTNAME_MCR}`,
    'edit-mcr': `Edit ${import.meta.env.VITE_EN_PRODUCTNAME_MCR}`,
    'create-mve': `Create ${import.meta.env.VITE_EN_PRODUCTNAME_MVE}`,
    'edit-mve': `Edit ${import.meta.env.VITE_EN_PRODUCTNAME_MVE}`,
    'move-connections': `Move Connections`,
    'aws-entitlements': `AWS Entitlements`,
    'aws-onboarding': `AWS Onboarding`,
    'service-status': `Service Status`,
  },
  partner: {
    'learn-more': `Learn more about Managed Account Administration in our Partner Documentation`,
    'view-documentation': `View Documentation`,
    'managed-accounts': `Managed Accounts`,
    'create-managed-account': `Create Managed Account`,
    'view-services': `View Services`,
    edit: `Edit`,
    filter: `Filter Accounts`,
    'no-managed-accounts': `There are no managed accounts. Click the 'Create Managed Account' button to create your first account.`,
    'invalid-company-uid': `No managed account found matching the UID.`,
    'no-match': `No Matching Accounts.`,
    'account-name': `Account Name`,
    'company-uid': `Company UID`,
    'managed-account-ref': `Managed Account UID`,
    'managed-account-ref-placeholder': `Unique Company Reference`,
    actions: `Actions`,
    'managed-account': `Managed Account`,
    'managed-learn-more': `Learn more about Managed Account Creation in our Partner Documentation`,
    save: `Save`,
    create: `Create`,
    cancel: `Cancel`,
    'account-created': `Managed account created.`,
    'account-saved': `Managed account saved.`,
    'create-account-error': `Error creating managed account.`,
    'update-account-error': `Error updating managed account.`,
    error: `Error`,
    'error-retrieving-accounts': `Could not retrieve managed accounts.`,
    'managed-accounts-info': `View a list of accounts, set permissions, and manage services.`,
    'edit-users': `Edit Users`,
    'edit-users-text': `View and manage a full list of users and their permissions for this company.`,
    'new-managed': `New Managed Account`,
    'view-services-text': `Set up, view and order new services for this company.`,
    'manage-services': `Manage Services`,
    'diversity-unavailable-location': `Diversity is not currently available for this configuration. Contact your ${import.meta.env.VITE_EN_COMPANYINFO_NAME} Partner to discuss your diversity requirements.`,
  },
  'partner-admin': {
    'partner-account': `Partner Account`,
    'managed-account': `Managed Account | Managed Accounts`,
    'managed-accounts-uid': `Managed Account UID`,
    'select-account': `Select Account`,
    'account-filter': `Filter accounts by name or reference`,
    partner: `Partner`,
    'viewing-as': `Viewing As:`,
  },
  'partner-vantage': {
    subscription: `Subscription`,
    'subscription-tooltip-ports': `Subscription will commence once service goes live or 14 days after service configuration`,
    'subscription-tooltip': `Subscription will commence upon ordering`,
    'no-subscription': `No Subscription`,
    'change-subscription': `Change Subscription`,
    'subscription-title': `Set {productType} Subscription`,
    'subscription-choose': `Choose {productType} Subscription`,
    'confirm-new-subscription': `Start New Subscription`,
    'subscription-months': `No Subscription | Subscription: 1 Month | Subscription: {count} Months`,
    'subscription-reterm': `The term for this Order (the Minimum Term) is effective immediately and the term discount will be applied to the next calendar month's bill run. Early termination of the term will incur early termination fees in accordance with the agreement between us.`,
    'cancel-subscription': `Click Cancel if you wish to take no action and roll over to no subscription at the list price at the end of the current subscription.`,
    'subscription-term': `Subscription Term`,
  },
  outscale: {
    connection: `Outscale Connection`,
    'connection-details': `Connection details for Outscale Service`,
    'connection-name': `Outscale Connection Name`,
    'connection-name-tooltip': `Label the service in Outscale`,
    'account-id': `Outscale Account ID`,
    'account-id-tooltip': `Enter the Outscale owner account ID`,
  },
  'company-security-settings': {
    'security-settings': `Security Settings`,
    'mfa-long': `Multi-Factor Authentication`,
    'info-enforced': `Once enforced, all users of your company will be asked to set up MFA during login.`,
    'info-optional': `Once optional, all users of your company will still have the option of enabling MFA in their user profile.`,
    'mfa-now-enforced': `MFA is now enforced`,
    'mfa-now-optional': `MFA is now optional`,
    'enforce-mfa-all-users': `Enforce Multi-Factor Authentication (MFA) for all users in your company`,
    'error-updating-mfa': `Error updating MFA setting`,
    'enforce-mfa-tooltip-on': `If MFA is enforced (ON), all users must use MFA when logging in to their account. If a user doesn't currently have MFA enabled, they will be prompted to set up MFA at next login. Users will not be able to access your company within ${import.meta.env.VITE_EN_COMPANYINFO_NAME} until they have set up MFA.`,
    'enforce-mfa-tooltip-off': `If MFA is optional (OFF), users can choose whether or not they want to enable MFA on their account.`,
    'enforce-mfa-tooltip-title': `Enforcing MFA`,
    mfa: `MFA`,
    on: `ON`,
    off: `OFF`,
    sso: `SSO`,
    'sso-long': `Single Sign-On`,
    'provider-identity': `Provider Identity`,
    type: `Type`,
    'identity-provider-info': `Identity provider information`,
    'provider-name': `Provider Name`,
    domains: `Domains`,
    metadata: `Metadata URL`,
    'integration-details': `Integration details`,
    'audience-uri': `Audience URI`,
    'idp-response-url': `IdP Response URL`,
    'sso-fail': `Failed to retrieve SSO configurations.`,
    'sso-no-idps': `No configured identity providers.`,
    'sso-attribute-mapping': `Attribute mapping`,
    'sso-user-pool-attribute': `User pool attribute`,
    'sso-saml-attribute': `SAML attribute`,
    'integration-details-desc': `To connect a SAML 2.0 provider to ${import.meta.env.VITE_EN_COMPANYINFO_NAME}, you will need to provide the Audience URI and IdP Response URL to your identity provider`,
    'details-for': `Details for {thing}`,
    'accepts-csv-values': `Accepts a comma separated list of values`,
    'enforce-sso-all-users': `Enforce Single Sign-On (SSO) for all users in your company`,
    'enforce-sso-tooltip-title': `Enforcing SSO`,
    'enforce-sso-tooltip-on': `If SSO enforcement is ON, all users must authenticate using the default configured IdP. Any users not associated with the IdP user pool will have access blocked. (Exception: Company Admins will still be allowed to login with other methods, including Company Admins who do not have an SSO associated domain.)`,
    'enforce-sso-tooltip-off': `If SSO enforcement is OFF, users are allowed to use different authentication methods including username/password, Google or SSO.`,
    'enforce-sso-info-enforced': `Once SSO enforcement is ON, all users must authenticate using the default configured IdP. Any users not associated with the IdP user pool will have access blocked. (Exception: Company Admins will still be allowed to login with other methods, including Company Admins who do not have an SSO associated domain.)`,
    'enforce-sso-info-optional': `Once SSO enforcement is OFF, users are allowed to use different authentication methods including username/password, Google or SSO. All active users in your company will now be able to login again.`,
    'enforce-sso': `Enforce SSO`,
    'sso-connections': `SSO connections`,
    'error-fetching-sso': `Unexpected error when fetching SSO configuration`,
    'error-updating-sso': `Unexpected error when updating SSO configuration`,
    'configure-saml-idp': `Configure a SAML 2.0 identity provider (IdP)`,
    'configure-saml-description': `You can configure your ${import.meta.env.VITE_EN_COMPANYINFO_NAME} account to use single sign-on (SSO). When you enable SSO, users are redirected to an external identity provider (IdP) to authenticate, instead of being prompted to enter a password when they try to access the ${import.meta.env.VITE_EN_COMPANYINFO_PORTAL}. SSO simplifies the login experience and eliminates the need for users to remember multiple sets of login credentials, making it more user-friendly and easier for administrators to manage.`,
    'add-saml-connection': `Add SAML Connection`,
    'register-saml-provider': `Register ${import.meta.env.VITE_EN_COMPANYINFO_NAME} with your SAML provider`,
    'register-saml-provider-description': `Configure your SAML identity provider with ${import.meta.env.VITE_EN_COMPANYINFO_NAME} by providing the following details (including the metadata url used by your identity provider)`,
    'sso-update-success': `SSO settings updated for {name}`,
    'maximum-domains-error': `Maximum number of domains is {max}`,
    'invalid-domains': `Invalid domains found {domains}`,
    'duplicate-domains': `Duplicate domains found {domains}`,
    'idp-delete-confirmation-title': `Delete Provider`,
    'idp-delete-confirmation-message': `<strong>By proceeding with this action you acknowledge that:</strong><ul><li>All users associated with this connection will no longer be able to log in using your external IdP.</li><li>If SSO enforcement is enabled, it will become disabled.</li><li>All active users of your company will now be able to log in with other authentication methods.</li></ul>`,
    'sso-now-enforced': `SSO is now enforced`,
    'sso-now-optional': `SSO is now optional`,
    'new-user-non-sso-domain-warning': `Please be aware that Single Sign-On (SSO) is currently enforced for your account. Although you can invite any user to your account, invited users will be unable to use their credentials without being associated with an SSO domain. As an exception, if the user is a Company Admin, they will be able to access your account even if they are not associated with an SSO domain.`,
    'sso-enforced-inactive-warning': `Please be aware that Single Sign-On (SSO) is currently enforced on your account. If you are re-enabling a user account that is not associated with an SSO domain, then the user will still be blocked and unable to login. As an exception, if the user is a Company Admin, they will be able to access their account even if they are not associated with a SSO domain.`,
    'sso-new-docs-message': `Before adding SSO to this account please review the {docs} page. This provides guidance on setup, and important information regarding Single Sign-On.`,
    'sso-features-and-instructions-for-use': `SSO Features and Instructions for Use`,
    'sso-add-settings-for-account': `You have added SSO settings for this account.`,
    'sso-ensure-config-complete': `To ensure that your configuration is complete, you will now need to integrate your IdP with the Audience URI and Response URL below. These are essential components that are required for SAML authentication to work correctly:`,
    'sso-info-accessed-again-at-any-time': `This information can be accessed again at any time by clicking the Edit button beside your SSO Connection`,
    'can-also-be-known-as': `Can also be known as:`,
    'entity-id': `Entity ID`,
    audience: `Audience`,
    'assertion-consumer-service': `Assertion Consumer Service (ACS) URL`,
    'single-sign-on-url': `Single Sign On URL`,
    'application-callback-url': `Application Callback URL`,
    'reply-url': `Reply URL`,
  },
  tscd: {
    'tech-supp-phone': `Technical Support Phone`,
    'tech-supp-phone-num': `Technical Support Team Phone Number`,
    'tech-supp-phone-placeholder': `Customers' internal support teams' phone number`,
    'tech-supp-email': `Technical Support Team Email`,
    'tech-supp-email-placeholder': `Customers' internal support teams' email address`,
  },
  'company-support': {
    'tech-supp-phone': `Technical Support Team Phone Number`,
    'tech-supp-phone-placeholder': `Contact phone number of your internal support team`,
    'tech-supp-email': `Technical Support Team Email`,
    'tech-supp-email-placeholder': `Contact email of your internal support team`,
    'admin-warning': `Company Admin users can enter and edit technical support team details`,
    'customer-supp-heading': `Customer Contacts`,
    'megaport-supp-heading': `${import.meta.env.VITE_EN_COMPANYINFO_NAME} Contacts`,
    'account-manager': `Account Manager`,
    'customer-success-manager': `Customer Success Manager`,
    'click-chat-icon-msg': `Click on the chat icon to start a conversation`,
  },
  images: {
    'datacentre-logo': `Datacentre logo`,
    'megaport-logo': `${import.meta.env.VITE_EN_COMPANYINFO_NAME} logo`,
    'megaport-cloud-providers-logo': `${import.meta.env.VITE_EN_COMPANYINFO_NAME} cloud providers`,
    'loading-logo': `${import.meta.env.VITE_EN_COMPANYINFO_NAME} loading logo`,
    'role-icon': `Role icon`,
    'docs-icon': `Documentation icon`,
    'company-logo': `Company logo`,
    'resize-handle-icon': `Resize handle icon`,
    'marketplace-logo': `${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE} logo`,
    'marketplace-profile-logo': `${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE} profile logo`,
    'provider-logo': `Provider logo`,
    'copy-icon': `Copy value icon`,
    'partner-vantage-logo': `Partner Vantage logo`,
    'profile-logo': `Profile logo`,
    'sx-logo': `Service exchange logo`,
  },
  ibm: {
    connection: `IBM Connection`,
    'connection-details': `Connection details for IBM Cloud Service`,
    'account-id': `IBM Account ID`,
    'account-id-tooltip': `Obtain this from your IBM account setting in IBM portal`,
    'customer-asn-tooltip': `Public or private BGP ASN`,
    'account-id-validation': `32 digit hexadecimal number`,
    'modal-title': `IBM Portal Connection`,
    'order-request-sent': `The request has been sent to your IBM portal account.`,
    'order-request-reminder': `This is a reminder to accept/reject the connection request via the IBM portal.`,
    'visit-portal': `Visit IBM Portal`,
    'edit-modal-updated-api': `${import.meta.env.VITE_EN_COMPANYINFO_NAME} has updated API integrations with IBM.`,
    'edit-modal-recommendation': `We recommend users reorder an IBM Cloud ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} to access updated features and terminate the existing connection.`,
    'edit-modal-docs': `For more information - refer to the {link}.`,
  },
  'mexico-billing-market': {
    'company-type-mexico-01-legal-entity': `01: Legal Entity`,
    'company-type-mexico-02-legal-person': `02: legal person`,
    'company-type-mexico-03-foreign-company': `03: foreign company`,
    'tax-regime-code-mexico-601': `601 - General of Law Moral Persons`,
    'tax-regime-code-mexico-603': `603 - Non-Profit Legal Entities`,
    'tax-regime-code-mexico-605': `605 - Wages and Salaries and Income Assimilated to Wages`,
    'tax-regime-code-mexico-606': `606 - Lease`,
    'tax-regime-code-mexico-607': `607 - Regime of Alienation or Acquisition of Goods`,
    'tax-regime-code-mexico-608': `608 - Other income`,
    'tax-regime-code-mexico-610': `610 - Residents Abroad without Permanent Establishment in Mexico`,
    'tax-regime-code-mexico-611': `611 - Dividend Income (partners and shareholders)`,
    'tax-regime-code-mexico-612': `612 - Individuals with Business and Professional Activities`,
    'tax-regime-code-mexico-614': `614 - Interest income`,
    'tax-regime-code-mexico-615': `615 - Regime of income from obtaining prizes`,
    'tax-regime-code-mexico-616': `616 - No tax obligations`,
    'tax-regime-code-mexico-620': `620 - Cooperative Production Societies that choose to defer their income`,
    'tax-regime-code-mexico-621': `621 - Tax Incorporation`,
    'tax-regime-code-mexico-622': `622 - Agricultural, Livestock, Forestry and Fishing Activities`,
    'tax-regime-code-mexico-623': `623 - Optional for Groups of Companies`,
    'tax-regime-code-mexico-624': `624 - Coordinated`,
    'tax-regime-code-mexico-625': `625 - Regime of Business Activities with income through Technological Platforms`,
    'tax-regime-code-mexico-626': `626 - Simplified Trust Regime`,
    'sat-purpose-mexico-cn01': `CN01 - Payroll`,
    'sat-purpose-mexico-cp01': `CP01 - Payments`,
    'sat-purpose-mexico-d01': `D01 - Medical, dental and hospital expenses`,
    'sat-purpose-mexico-d02': `D02 - Medical expenses due to incapacity or disability`,
    'sat-purpose-mexico-d03': `D03 - Funeral expenses`,
    'sat-purpose-mexico-d04': `D04 - Donations`,
    'sat-purpose-mexico-d05': `D05 - Real interest effectively paid for mortgage loans (dwelling house)`,
    'sat-purpose-mexico-d06': `D06 - Voluntary contributions to the SAR`,
    'sat-purpose-mexico-d07': `D07 - Medical expense insurance premiums`,
    'sat-purpose-mexico-d08': `D08 - Compulsory school transportation expenses`,
    'sat-purpose-mexico-d09': `D09 - Deposits in savings accounts, premiums based on pension plans`,
    'sat-purpose-mexico-d10': `D10 - Payments for educational services (school fees)`,
    'sat-purpose-mexico-g01': `G01 - Acquisition of merchandise`,
    'sat-purpose-mexico-g02': `G02 - Returns, discounts or bonuses`,
    'sat-purpose-mexico-g03': `G03 - Expenses in general`,
    'sat-purpose-mexico-i01': `I01 - Buildings`,
    'sat-purpose-mexico-i02': `I02 - Office furniture and equipment for investments`,
    'sat-purpose-mexico-i03': `I03 - Transportation equipment`,
    'sat-purpose-mexico-i04': `I04 - Computer equipment and accessories`,
    'sat-purpose-mexico-i05': `I05 - Dies, dies, molds, dies and tooling`,
    'sat-purpose-mexico-i06': `I06 - Telephone communications`,
    'sat-purpose-mexico-i07': `I07 - Satellite communications`,
    'sat-purpose-mexico-i08': `I08 - Other machinery and equipment`,
    'sat-purpose-mexico-s01': `S01 - No tax effects (No tax obligations)`,
  },
  'survey-banner': {
    'customer-survey-link': `We'd love your feedback! Click here to complete our customer survey.`,
  },
  'mp-one': {
    'platform-orchestrate-network-services': `ONE Platform to Orchestrate, Network, and Enable Cloud Services.`,
    'try-megaport-one': `Try Megaport ONE`,
    'learn-more': `Learn more`,
    'click-here-to-try-mp-one': `Click here to test Megaport ONE Beta. A platform to orchestrate, connect and launch cloud solutions.`,
    'terms-and-conditions': `You are now entering the MegaportONE Portal.nnBy entering the MegaportONE Portal, you agree to be bound by the following terms and conditions.nnThe information and services provided in the MegaportONE Portal are currently available to users at no charge.nnMegaport reserves the right to introduce fees or charges for certain features or services in the future.nnAny such fees or charges will be clearly communicated to users in advance.nnPlease note that the MegaportONE Portal is currently in beta testing and not all features or services may be available at all times.nnMegaport reserves the right to modify, suspend, or discontinue any part of the website or its functionality without prior notice.nnWe do not guarantee uninterrupted or error-free access to the MegaportONE Portal.`,
  },
  'view-mode': {
    tooltip: `View your services in Tabular or Map view`,
    tabular: `Tabular`,
    map: `Map`,
  },
  map: {
    'all-locations': `Show all locations`,
    'occupied-locations': `Show only locations where I have services`,
    'filtered-locations': `Filter display of locations where I don't have services`,
    'thing-available': `{thing} Available`,
    'dc-provider': `Data Center Provider`,
    'locations-filtered': `The displayed locations are filtered`,
    'zoom-in': `Zoom in`,
    'zoom-in-lots': `Zoom in lots`,
    'zoom-out': `Zoom out`,
    'zoom-out-lots': `Zoom out lots`,
    settings: `Settings`,
    key: `Map Key`,
    help: `Help and Instructions`,
    'help-action': `Action`,
    'help-description': `Description`,
    'help-items': {
      'zoom-in-title': `Zoom in`,
      'zoom-in-action-1': `Double click`,
      'zoom-in-action-2': `= key`,
      'zoom-in-action-3': `+ key`,
      'zoom-in-action-4': `Hold ⌘/CTRL key and Mouse wheel`,
      'zoom-in-action-5': `Hold Alt/Option key and drag down`,
      'zoom-in-action-6': `Pinch`,
      'double-zoom-in-title': `Double speed zoom in`,
      'double-zoom-in-action-1': `Shift = key`,
      'double-zoom-in-action-2': `Shift + key`,
      'zoom-out-title': `Zoom out`,
      'zoom-out-action-1': `Shift double click`,
      'zoom-out-action-2': `- key`,
      'zoom-out-action-3': `Hold ⌘/CTRL key and Mouse wheel`,
      'zoom-out-action-4': `Hold Alt/Option key and drag up`,
      'zoom-out-action-5': `Pinch`,
      'double-zoom-out-title': `Double speed zoom out`,
      'double-zoom-out-action-1': `Shift - key`,
      'area-zoom-in-title': `Zoom in to an area`,
      'area-zoom-in-action-1': `Shift drag area`,
      'pan-title': `Pan the map`,
      'pan-action-1': `Drag`,
      'pan-action-2': `Arrow keys`,
      'orientation-title': `Change map orientation`,
      'orientation-action-1': `Two finger drag`,
      'orientation-action-2': `Shift ←, → to rotate, Shift ↑ to reset`,
    },
    'ix-count': `0 IXs | 1 IX | {count} IXs`,
    'location-cluster': `This cluster represents {locationString}.`,
    'location-ix-cluster': `This cluster represents {locationString} and {ixString}.`,
    'click-expand': `Click to expand.`,
    'click-empty-location': `Click to see details and add services`,
    'supported-products': `This location supports the following products:`,
    'products-location': `You have the following products at this location:`,
    'click-details': `Click to see details`,
    'line-connections-count': `- | This line represents 1 connection: | This line represents {count} connections`,
    'location-deployment': `This location will go live soon. You can pre-order your services now.`,
    'view-in-map': `View this service on a map`,
    'navigate-to': `Navigate to {serviceName}`,
    'search-tips': `You can search for:<ul><li>Any product type e.g. VXC</li><li>Any service name</li><li>Product ID</li><li>Megaport enabled locations (if you do not have this turned off in your map settings)</li><li>Or any other place around the world</li></ul>`,
    'key-items': {
      'occupied-location': `A single location where you have services. You can click these pins to see details of the services you have at that location, and to order additional services there.`,
      spiderified: `When you click on a location where you have services, it will expand to show all the ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS}, ${import.meta.env.VITE_EN_PRODUCTNAME_MCRS} and ${import.meta.env.VITE_EN_PRODUCTNAME_MVES} you have at that location as well as the location itself. The icons are color coded to indicate whether the service is up or down. Third party ports that you are connected to are represented with black icons. Click on an icon to see the details.`,
      'occupied-location-cluster': `A cluster of locations where you have services. The ring around it is colored to indicate what proportion of the connections starting in the cluster are in up or down states. Click to expand.`,
      'unoccupied-location': `A single location that ${import.meta.env.VITE_EN_COMPANYINFO_NAME} services are available, but you do not currently have any services. Click to see details of the location and order services there. You can turn off the display of unoccupied locations in the map settings.`,
      'unoccupied-location-cluster': `A cluster of locations where you have no services. Click to expand. You can turn off the display of unoccupied locations in the map settings.`,
      'ix-pin': `A virtual pin representing a ${import.meta.env.VITE_EN_PRODUCTNAME_IX} in that metro area.`,
      'mid-connection': `A marker representing connections between two locations, or location clusters. A single line may represent multiple connections. The marker is green if all connections are up, red if all connections are down, and half and half if only some are up. Click on the marker to see all the represented connections.`,
      connections: `Lines between locations represent connections. The color of the line indicates whether the connection is up or down. In this case there are three up connections and one down connection.`,
    },
    'partner-count': `- | You have 1 third party ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} connection at this location. | You have {count} third party ${import.meta.env.VITE_EN_PRODUCTNAME_PORT} connections at this location.`,
    'ix-incoming-count': `- | You have 1 incoming ${import.meta.env.VITE_EN_PRODUCTNAME_IX} connection. | You have {count} incoming ${import.meta.env.VITE_EN_PRODUCTNAME_IX} connections.`,
    fullscreen: `Full screen mode`,
    big: `Large display (service widgets not visible)`,
    small: `Small display (service widgets visible)`,
    'fullscreen-error-title': `Failed to enter fullscreen mode`,
    'fullscreen-error-message': `Error attempting to enable fullscreen mode: {errorMessage} ({errorName})`,
    'not-ready-title': `Map Not Ready`,
    'not-ready-message': `Some map elements are still loading. Please retry in a few seconds`,
    'initialization-error': `Unable to show services map: {message}`,
    globe: `View as globe`,
    mercator: `View as flat (Mercator) projection`,
  },
  'cancel-reason': {
    label: `Cancellation Reason`,
    'moved-dc': `Moved out of Data Center`,
    'order-error': `Ordered in Error or Reordering`,
    'switch-provider': `Switching to Alternative Provider`,
    'proof-of-concept': `Proof of Concept / test`,
    other: `Reason Not Listed`,
    'project-ended': `Project Ended`,
    'cloud-native-move': `Moving to a Cloud-Native Solution`,
    pricing: `Pricing`,
    'product-performance': `Product Performance`,
    'feedback-prompt': `Please help us improve by providing further detail about your cancellation reason`,
  },
  entitlements: {
    description: `This page displays AWS Entitlements that have been purchased through the AWS marketplace. You can filter this list to show/hide any Entitlements that have expired. Note that only entitlements that are {inUse} have a service associated with them.`,
    'in-use': `In Use`,
    loading: `Loading entitlements`,
    'no-match': `No Matching Entitlements`,
    'service-id': `Service ID`,
    'agreement-id': `AWS Agreement ID`,
    'vendor-agreement-id': `Vendor Agreement ID`,
    'vendor-account-id': `Vendor Account ID`,
    sku: `SKU`,
    'start-date': `Contract Start Date`,
    'end-date': `Contract End Date`,
    status: `Status`,
    'product-type': `Product Type`,
    failed: `Failed to load entitlements`,
    available: `Available`,
    expired: `Expired`,
  },
  'service-status': {
    maintenance: `Maintenance`,
    outages: `Outages`,
    timezone: `Timezone`,
    sort: `Sort`,
    filter: `Filter`,
    'search-event-placeholder': `Enter text to filter events`,
    status: `Status`,
    'start-date': `Start Date`,
    ascending: `Ascending`,
    descending: `Descending`,
    ongoing: `Ongoing`,
    scheduled: `Scheduled`,
    completed: `Completed`,
    cancelled: `Cancelled`,
    resolved: `Resolved`,
    'in-last-7-days': `in last 7 days`,
    'no-data-text': `No results found matching selected criteria. Try adjusting your filter criteria.`,
    'start-utc': `Start (UTC):`,
    'end-utc': `End (UTC):`,
    duration: `Duration:`,
    'resolution-details': `Resolution Details`,
    'root-cause': `Root cause:`,
    resolution: `Resolution:`,
    'mitigation-actions': `Mitigation Actions:`,
    'update-notifications': `Update Notifications`,
    'impacted-services': `Impacted Services`,
    'hide-updates': `Hide Previous Updates`,
    'show-updates': `Show Previous Updates`,
    more: `See More`,
    less: `See Less`,
    'incident-notification': `Incident Notification`,
    'status-update': `Next - Incident Notification`,
    'resolved-status': `Resolved - Incident Notification`,
    'latest-status': `Latest - Incident Notification`,
    'next-update-etr': `Next Update/ETR:`,
    'notification-description': `Please be advised that an outage is currently impacting your service. We are actively working to restore services as our highest priority.`,
    'next-update-text': `Updates will be provided as soon as they become available. We thank you for your patience while we work to resolve this outage.`,
    'maintenance-outages': `Maintenance & Outages`,
    'refresh-error': `Unable to refresh events`,
    'type-label': `Type`,
    'name-label': `Name`,
    'id-label': `ID`,
    'actions-label': `Actions`,
    details: `Details`,
    map: `Map`,
  },
}
