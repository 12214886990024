import { array, enum as zodEnum, nullable, number, object, string, optional, boolean, union } from 'zod'

import { apiResponse, apiResponseArray, diversityZoneEnum, mcrConnectionDetails, vlan } from '../../megaport/schemas'

const megaports = array(
  object({
    companyId: number().positive(),
    companyName: string(),
    companyUid: string().uuid(),
    country: string(),
    description: string(),
    locationId: number().positive(),
    nServiceId: optional(number().positive()),
    name: string(),
    port: number().positive(),
    portSpeed: number().positive(),
    productId: number().positive(),
    productUid: string().uuid(),
    state: nullable(string()),
    vxc: nullable(string().or(number().positive())),
  }),
)

export const azureProductSchema = apiResponse({
  bandwidth: number().nonnegative(),
  megaports,
  // NOTE: This array is empty in non-prod environments
  peers: array(
    object({
      type: string(),
      peerAsn: optional(number().nonnegative()),
      vlan,
      primarySubnet: optional(string()),
      secondarySubnet: optional(string()),
    }),
  ),
  vlan,
})

export const googleProductSchema = apiResponse({
  bandwidths: array(number().nonnegative()),
  megaports,
})

export const nutanixProductSchema = apiResponse({
  bandwidth: number().nonnegative(),
  megaports,
  vlan: optional(vlan), // Field is referenced in the portal but isn't returned by API :/
})

export const oracleProductSchema = apiResponse({
  bandwidth: number().nonnegative(),
  megaports,
})

// Redefine vlan as non-nullable for checkVlan endpoint (only time it is)
const vlanRequired = number().min(0).max(4095)

/**
 * The following is returned when passing in a vlan only in the params
 * Returns data as a raw array of numbers instead of the standard object/array of objects
 */
export const checkVlanSchema = object({
  data: array(vlanRequired),
})

/**
 * The following is returned when passing in vlan and replacingVlan in the params
 */
export const checkVlanReplacingSchema = apiResponse({
  vlan: vlanRequired,
  suggested_vlans: array(vlanRequired),
})

export const mveImagesSchema = apiResponse({
  mveImages: array(
    object({
      id: number().positive(),
      productCode: zodEnum([
        '6wind-mve',
        'aruba-ecv',
        'aviatrix',
        'c8000',
        'ftdv',
        'flexvnf',
        'fortigate',
        'meraki-vmx',
        'palo-vm',
        'prisma-3108',
        'prisma-7108',
        'vmware',
      ]),
    }),
  ),
})

const serviceKeyObject = {
  key: string().uuid(),
  createDate: number().positive(),
  companyName: string(),
  description: string(),
  productUid: string().uuid(),
  vlan: number().nonnegative().nullable(),
  maxSpeed: number().positive(),
  preApproved: boolean(),
  singleUse: boolean(),
  active: boolean(),
  validFor: object({
    start: number().positive(),
    end: number().positive(),
  }),
  expired: boolean(),
  valid: boolean(),
  bEnd: object({
    isValid: boolean().optional(),
    partnerConfig: mcrConnectionDetails.optional(),
  }),
}

// The response will depend on if a key gets passed in or not
// If no key is provided, you'll get an array, otherwise
// you'll get an object with the productDto object attached
export const getKeySchema = union([
  apiResponseArray({ ...serviceKeyObject }),
  apiResponse({
    ...serviceKeyObject,
    productDto: object({
      productUid: string().uuid(),
      productName: string(),
      portSpeed: number().positive().nullable(),
      locationId: number().positive(),
      vxcPermitted: boolean(),
      companyUid: string().uuid(),
      companyName: string(),
      connectType: string(),
      diversityZone: diversityZoneEnum.nullable(),
    }),
  }),
])

export const createUpdateKeySchema = apiResponse({
  description: string(),
})

const resourceTags = array(
  object({
    key: string(),
    value: string(),
  }),
)

export const allResourceTagsSchema = apiResponse({
  serviceTags: array(
    object({
      serviceUid: string().uuid(),
      resourceTags,
    }),
  ),
})

export const productResourceTagsSchema = apiResponse({
  resourceTags,
})
