import type MegaportAPI from '../megaport/api'
import type { APISettings } from '../megaport/types'

/**
 * MCR Prefix lists
 */
export default class MCRPrefixListsResource {
  #api: MegaportAPI
  #productUid: string

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   * @param productUid MCR product UID
   */
  constructor(api: MegaportAPI, productUid: string) {
    this.#api = api
    this.#productUid = productUid
  }

  /**
   * List all the prefix lists specific for a given MCR
   *
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async list(settings?: APISettings) {
    const response = await this.#api.get(`/v2/product/mcr2/${this.#productUid}/prefixLists`, settings)
    return response.body.data || response.body
  }

  /**
   * Retrieve a prefix list for a given prefix list id
   * @param prefixListId Prefix list id
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async get(prefixListId: number, settings?: APISettings) {
    const response = await this.#api.get(`/v2/product/mcr2/${this.#productUid}/prefixList/${prefixListId}`, settings)
    return response.body.data || response.body
  }

  /**
   * Update a given prefix list with new list
   * Replace an existing prefix list and all associated prefix entires.
   * AddressFamily can not change The value of addressFamily in the JSON body
   * must match the existing prefix list's addressFamily
   * The order of the prefix list entries in maintained
   * @param prefixListId Prefix list id
   * @param data Update payload, including required fields: description, addressFamily, entries
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async update(prefixListId: number, data: any, settings?: APISettings) {
    const response = await this.#api.put(`/v2/product/mcr2/${this.#productUid}/prefixList/${prefixListId}`, settings, {
      data,
    })
    return response.body.data || response.body
  }

  /**
   * Delete a specific prefix list
   * @param prefixListId Prefix list id
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async delete(prefixListId: number, settings?: APISettings) {
    const response = await this.#api.delete(`/v2/product/mcr2/${this.#productUid}/prefixList/${prefixListId}`, settings)
    return response.body.data || response.body
  }

  /**
   * Create a new prefix list specific for a given MCR
   * Max 50 prefix lists per MCR Max 500 entries in a prefix list
   * @param {object} data Create payload, including required fields: description, addressFamily, entries
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async create(data: any, settings?: APISettings) {
    const response = await this.#api.jpost(`/v2/product/mcr2/${this.#productUid}/prefixList/`, settings, { data })
    return response.body.data || response.body
  }
}
