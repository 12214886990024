import { string, number } from 'zod'

import { apiResponse, apiResponseArray } from '../../megaport/schemas'

export const mfaQrcodeSchema = apiResponse({
  secret: string(),
  qrcode: string().url(),
})

export const activitySchema = apiResponseArray({
  personName: string(), // user name of the person who performed the action
  personId: number(), // user ID of the person who performed the action
  description: string(), // details of the action
  shortDescription: string(), // name of the action
  eventDate: number(), // API returns the datetime as a number
  userType: string(), // type of the user who performed the action
})
