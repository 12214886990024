import { array, boolean, nullable, object, string } from 'zod'

import { apiResponse } from '../../megaport/schemas'

export const partnerBrandingSchema = apiResponse({
  companyName: nullable(string()),
  logoKey: nullable(string()),
  web: nullable(string()),
})

export const getCompanySettingsSchema = apiResponse({
  consolidatedSettings: array(
    object({
      key: string(),
      value: boolean(),
    }),
  ),
})
