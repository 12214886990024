import { ElLoading, ElNotification } from 'element-plus'

import integrations from '@/third-party-integrations/integrations.js'
import store from '@/store/Store.js'
import { isFeatureEnabled } from '@/providers/runtime.js'
import buildProviders from '@/providers/build.js'
import { resolveServicesPage } from '@/utils/MapDataUtils'
import { setActiveLanguage } from '@/i18n/SetupI18n.js'
import { mpApp_$t } from '@/mpApp.js'

async function switchToManagedCompany({ managedCompanyUid, companyName }) {
  const loadingManagedCompany = ElLoading.service({
    lock: true,
    text: companyName,
    background: 'rgba(255, 255, 255, 0.9)',
  })
  // See https://stackoverflow.com/questions/62223195/vue-router-uncaught-in-promise-error-redirected-from-login-to-via-a
  try {
    await store.dispatch('ApplicationContext/setCompanyContext', managedCompanyUid)
  } catch (error) {
    // TODO: Add error processing
    console.error(error)
  } finally {
    loadingManagedCompany.close()
  }
}

function gotoHomePage(next) {
  next(store.getters['Auth/homePage'])
}

/** Captures the final destination for deep linking if the user is unauthenticated */
function setRedirect(to) {
  store.commit('Auth/setRedirect', to.fullPath)
}

function handleFailedAuth(next) {
  if (import.meta.env.VITE_BYPASS_AUTHENTICATION === 'true') {
    // This should never happen since the token is supplied, but in case there is
    // an error at our end or something, we should at least show a message and
    // abort rather than constantly reloading. Since the customer has no direct
    // login we can't really tell them what to do or anything.
    const props = {
      title: mpApp_$t('authentication.log-in'),
      message: mpApp_$t('authentication.failed'),
      type: 'error',
      duration: 0,
    }
    ElNotification(props)

    next(false)
  } else {
    const props = {
      title: mpApp_$t('authentication.log-in'),
      message: mpApp_$t('authentication.login-failed-token'),
      type: 'error',
      duration: 10000,
    }
    ElNotification(props)

    next('/login')
  }
}

const history = []

/**
 * Adds global route guards to the provided router
 * @param {import("vue-router").default} router The router to add the route guards to
 */
function routeGuards(router) {
  router.beforeEach(async (to, _from, next) => {
    await setActiveLanguage()
    const {
      meta: { runtimeFlag, accountTypes, requiresAdminCompany },
    } = to

    // Remove the page title logic from here
    if (!buildProviders.disabledFeatures.pageTitles) {
      // Dynamically set page titles for specific routes
      if (to.name === 'CreatePort') {
        const serviceTypeParam = to.params.serviceType
        const serviceUidParam = to.params.productUid

        if (!serviceUidParam) {
          to.meta.title = serviceTypeParam === 'port' ? 'page-titles.create-port' : 'page-titles.create-mcr'
        } else {
          to.meta.title = serviceTypeParam === 'port' ? 'page-titles.edit-port' : 'page-titles.edit-mcr'
        }
      }

      if (to.name === 'CreateMve') {
        const serviceUidParam = to.params.productUid
        to.meta.title = serviceUidParam ? 'page-titles.edit-mve' : 'page-titles.create-mve'
      }
    }

    // check if route needs reload
    if (store.state.needsReload && to.meta.reloadSafe) {
      store.commit('needsReload', false) // Just to be extra sure
      window.location.href = `${window.location.origin}${to.path}?nocache=${new Date().getTime()}`
    }

    // remove the token to prevent the user from redirecting to the login page
    if (to.path.indexOf('/passwordReset') >= 0) {
      localStorage.removeItem('_accessToken')
    }

    // check if route has a feature flag and redirect if its disabled
    if (runtimeFlag && !isFeatureEnabled(runtimeFlag.name)) {
      if (to.path !== runtimeFlag.redirect) {
        return next(runtimeFlag.redirect)
      }
    }

    // separately to account type, users in the megaport and megaport lab companies
    // are classified as admins. Check permissions
    if (requiresAdminCompany && !store.getters['Auth/isAdminCompanyAccount']) {
      return next(resolveServicesPage())
    }

    // Won't be there on initial load for DLR but will be there for subsequent page loads, so we know
    // that the data must be loaded
    const sessionToken = store.getters['Auth/sessionToken']
    let accessToken = store.getters['Auth/accessToken']

    // check if route has the correct account type to access the path
    if (accountTypes && (accessToken || sessionToken)) {
      const isManagedContext = store.getters['ApplicationContext/isManagedContext']
      const authAccountType = store.getters['Auth/accountType']

      const hasAuthAccountType = accountTypes.includes(authAccountType)

      if (isManagedContext && !hasAuthAccountType) {
        return next(resolveServicesPage())
      }

      if (!hasAuthAccountType) {
        return next(resolveServicesPage())
      }
    }

    // execute switching of application context based on route queries managedCompanyUid
    if (Object.hasOwn(to.query, 'managedCompanyUid')) {
      const {
        query: { managedCompanyUid, companyName, ...otherQueries },
        ...rest
      } = to
      await switchToManagedCompany({ managedCompanyUid, companyName })
      const { checkManagedAccountSwitch = false } = rest.meta

      if (checkManagedAccountSwitch) {
        return next(resolveServicesPage())
      } else {
        return next({ ...rest, query: otherQueries })
      }
    }

    // If admin user has reset MFA for this user, redirect to setup MFA page first
    if (!to.path.includes('/setup-mfa') && store.getters['Auth/requiresMfaSetup']) {
      return next('/setup-mfa')
    }

    /* As part of the signup flow, you need to accept the terms. This is a FE only checkbox which isn't saved
     * to the database, so we check if we're in that flow using the localStorage key _requiresTerms. If set
     * always redirect back to signup-google when attempting to visit a requiresLogin route.
     */
    const signupTermsRedirect = to.meta.requiresLogin && localStorage.getItem('_requiresTerms')
    if (!to.path.includes('/signup-google') && signupTermsRedirect) {
      // this is set again on signup-google route, removed here to prevent any sort of potential redirect loops
      localStorage.removeItem('_requiresTerms')
      return next('/signup-google')
    }

    /* As part of the *new* cognito signup flow, if user is trying to navigate anywhere and has no company name set,
     * redirect them to enforce setting trading name. This must be done before they can use the portal.
     */
    if (!to.path.includes('/account-name') && !store.getters['Auth/hasCompany']) {
      return next('/account-name')
    }

    // The /g2 route is a special case that always redirects to either the /login page
    // for unauthenticated users or to the route determined by resolveServicesPage() for authenticated users.
    // The `isUrlTriggeredG2Review` flag is set to true here and handled by activateG2() in the g2Review integration.
    if (to.redirectedFrom === '/g2') {
      store.commit('setIsUrlTriggeredG2Review', true)
    }

    // check if requires authentication but we are not already authenticated
    if (to.meta.requiresLogin && !accessToken && !sessionToken) {
      // If we are bypassing the authentication, then the token should already be in the x-auth-token header
      // of the api call (as added by the DLR proxy). Pass false as the accessToken to API-SDK to tell it not
      // to add an auth header to it's request
      const skipAuth = import.meta.env.VITE_BYPASS_AUTHENTICATION === 'true'

      accessToken = localStorage.getItem('_accessToken')
      // If they were reloading the page, then we will try to login again and only if it fails will we boot them back to login
      if (accessToken || skipAuth) {
        let loggingInSpinner = null
        loggingInSpinner = ElLoading.service({
          lock: true,
          text: skipAuth ? '' : mpApp_$t('authentication.logging-in'),
          background: skipAuth ? '#f8f8f8' : 'rgba(255, 255, 255, 0.9)',
        })

        try {
          await store.dispatch('Auth/login', { payload: { accessToken: skipAuth ? false : accessToken } })

          // If admin user has reset MFA for this user, redirect to setup MFA page first
          if (store.getters['Auth/requiresMfaSetup']) {
            loggingInSpinner?.close()
            return next('/setup-mfa')
          }

          /* If no company, force redirect to account name page */
          if (!store.getters['Auth/hasCompany']) {
            loggingInSpinner.close()
            return next('/account-name')
          }
          // In the normal login process we would display the message that
          // we are loading the services and wait for that to complete
          // but here we just need to kick off the process since we are
          // reloading a page.
          store.dispatch('Services/getMyServices')

          if (loggingInSpinner) {
            loggingInSpinner.close()
          }

          next(to)
        } catch (error) {
          loggingInSpinner?.close()
          setRedirect(to)
          handleFailedAuth(next)
        }
      } else {
        setRedirect(to)
        next('/login')
      }
    } else if (!to.meta.requiresLogin && accessToken) {
      // If the user is already logged in, take them to an authorised page
      gotoHomePage(next)
    } else if (to.meta.auth && accessToken && !store.getters['Auth/hasAuth'](to.meta.auth)) {
      // If they are logged in, but don't have permission to access that page, don't let them go there
      gotoHomePage(next)
    } else {
      // Send a virtual page view to dataLayer to be picked up by GTM
      integrations.googleTagManager.push('event', 'virtualPageview', {
        pageTitle: mpApp_$t(to.meta.title || 'companyInfo.portal', 'en'),
        pageUrl: to.path,
      })

      if (store.state.Auth.data.t) {
        integrations.googleTagManager.push('event', 'ut_event', {
          ut: store.state.Auth.data.t,
        })
      }

      next()
    }
  })

  router.afterEach((to, from) => {
    // Set page title if feature is enabled
    if (!buildProviders.disabledFeatures.pageTitles) {
      if (to.name !== 'CreateConnection') {
        const megaportPortal = mpApp_$t('companyInfo.portal')
        document.title = to.meta.title ? `${megaportPortal} - ${mpApp_$t(to.meta.title)}` : megaportPortal
      }
    }

    // Track analytics
    integrations.postHog.recordPageView()
    integrations.segment.recordPageView(to, from)
    history.push(from.path)
  })
}

export { routeGuards, history }
