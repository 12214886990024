import { ElLoading } from 'element-plus'

import sdk from '@/api-sdk'
import { mpApp_$t } from '@/mpApp.js'
import eventBus from '@/utils/eventBus.js'

const coreState = {
  data: {
    companyUid: null,
  },
  isLoading: false,
}

const getters = {
  companyUid: state => {
    return state.data.companyUid
  },
  // Managed context refers to partner call context usage, when moving into child company context
  isManagedContext: (state, _getters) => {
    return !!state.data.companyUid && !_getters.isAdminContext
  },
  companyContextLoading: state => {
    return state.isLoading
  },
  // Admin context is whenever a user from an admin company account uses the parent-child functionality.
  // Currently that is Megaport Admin or Megaport Lab users.
  isAdminContext: (state, _getters, rootState, rootGetters) => {
    return !!state.data.companyUid && rootGetters['Auth/isAdminCompanyAccount']
  },
}

const actions = {
  async setCompanyContext(context, companyUid) {
    context.commit('setLoading', true)
    context.commit('setCompanyUid', companyUid)

    // clear current store before rehydrating a new context
    await context.dispatch('cleanup')

    // This must be set after cleanup, as cleanup sets it to null
    sdk.instance.effectiveUid = companyUid

    // Request for the managed company details
    try {
      await context.dispatch('updateCompany')
      await context.dispatch('refreshLocalState')
    } catch (error) {
      handleErrors(context, error)
    } finally {
      context.commit('setLoading', false)
    }
  },

  /*
   * Fetch company via Call-Context header, using the appropriate method depending
   * on admin or partner usage
   */
  async updateCompany(context) {
    const companyUid = context.getters.companyUid
    // companyUID is null when returning to the context of your own company
    if (!companyUid) {
      await context.dispatch('exitCompanyContext')
      return
    }
    const companySDK = sdk.instance.company(companyUid)
    const company = context.getters.isAdminContext ? await companySDK.get(true) : await companySDK.getManagedCompany()

    context.commit('Company/setCompany', company, { root: true })
  },

  // Refresh local store with current company
  async refreshLocalState(context) {
    eventBus.emit('changeContext', true)

    await context.dispatch('Services/getMyServices', false, { root: true })
    await context.dispatch('onLogin', null, { root: true })
  },

  // Clear all Call-Context state and refresh as users company
  async exitCompanyContext(context) {
    const loadingSpinner = ElLoading.service({
      lock: true,
      text: mpApp_$t('admin.exit-loading'),
      background: 'rgba(255, 255, 255, 0.9)',
    })

    context.commit('setLoading', true)

    try {
      await context.dispatch('cleanup')

      const company = await sdk.instance.company().get()
      context.commit('Company/setCompany', company, { root: true })
      await context.dispatch('refreshLocalState')

      context.commit('setCompanyUid', null)
    } catch (error) {
      handleErrors(context, error)
    } finally {
      context.commit('setLoading', false)
      loadingSpinner.close()
    }
  },

  cleanup(context) {
    const mutations = [
      'Company/logout',
      'Marketplace/logout',
      'Markets/logout',
      'Services/logout',
      'Users/logout',
      'IXTypes/logout',
      'IXFlows/logout',
    ]

    mutations.forEach(mutation => context.commit(mutation, null, { root: true }))
  },
}

const mutations = {
  setCompanyUid(state, companyUid) {
    state.data.companyUid = companyUid
  },
  setLoading(state, tf) {
    state.isLoading = tf
  },
  logout(state) {
    state.data.companyUid = null
  },
}

const handleErrors = async (context, error) => {
  /* For admin context display error notification */
  if (context.getters.isAdminContext) {
    await context.dispatch('exitCompanyContext')
    context.commit(
      'Notifications/notifyMessage',
      {
        title: mpApp_$t('admin.error-company-context'),
        message: error.data?.message || error || mpApp_$t('general.unknown-error'),
        type: 'error',
      },
      { root: true },
    )
  }
  console.warn(error)
}

export default {
  namespaced: true,
  state: coreState,
  getters,
  actions,
  mutations,
}
