<template>
  <div class="pricebook-sidebar">
    <el-input
      v-if="priceDraw"
      :model-value="priceDraw"
      size="small"
      disabled
      class="disabled-price" />
    <!-- The geniuses at element plus have decided the row attribute is the amount of additional rows to the initial first row displayed -->
    <el-skeleton
      :loading="!priceDraw"
      :rows="0"
      animated
      class="skeleton" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { moneyFilter } from '@/helpers.js'
import { scopedPriceBook } from '@/utils/priceBook.js'

export default {
  name: 'PriceBook',

  inject: ['disabledFeatures', 'isFeatureEnabled'],

  props: {
    service: {
      type: Object,
      default: null,
    },
    portUid: {
      type: String,
      default: null,
    },
    lagPortCount: {
      type: Number,
      default: null,
    },
  },
  emits: ['price'],

  data() {
    return {
      price: {},
      customPrice: false,
      throttle: null,
    }
  },

  computed: {
    ...mapGetters('Auth', ['hasFeatureFlag']),
    ...mapState('Services', ['partnerPorts']),
    ...mapState('Company', { companyUid: state => state.data.companyUid }),
    ...mapGetters('Services', ['findPort', 'findService']),

    showPricing() {
      return !this.disabledFeatures.showPrices && this.isFeatureEnabled('PRICING_VISIBLE')
    },
    display() {
      return !isNaN(this.price.monthlyRate) && (this.price.monthlyRate === 0 || this.price.currency)
    },
    hasCrossConnectEnabled() {
      return this.hasFeatureFlag('cross_connect_mvp') && this.service.crossConnectRequested
    },
    priceDraw() {
      if (!this.display) return ''

      const currency = this.price.currency || 'USD'
      let multiplier = 1
      if (this.lagPortCount) {
        multiplier = this.lagPortCount
      }
      if (this.hasCrossConnectEnabled) {
        return moneyFilter((this.price.monthlyRate + this.price.crossConnectRecurringCharge) * multiplier, currency)
      } else {
        return moneyFilter(this.price.monthlyRate * multiplier, currency)
      }
    },
  },

  watch: {
    service: {
      handler() {
        clearTimeout(this.throttle)
        this.throttle = setTimeout(() => {
          if (this.showPricing) {
            this.fetchPrice()
          }
        }, 500)
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    scopedPriceBook,

    fetchPrice() {
      if (!this.service || !this.partnerPorts || this.partnerPorts.length <= 0) return

      if (this.service.productType === this.G_PRODUCT_TYPE_MCR2) {
        if (!this.service.locationId) {
          return this.setPrice(false)
        }
        this.scopedPriceBook()
          .mcr(
            this.service.locationId,
            this.service.portSpeed,
            this.service.provisioningStatus !== this.G_PROVISIONING_DESIGN ? this.service.productUid : undefined,
            this.service.term,
          )
          .then(priceObj => {
            this.setPrice(priceObj)
          })
          .catch(() => this.handleFetchPriceError())
      } else if (this.service.productType === this.G_PRODUCT_TYPE_MEGAPORT) {
        if (!this.service.locationId) {
          return this.setPrice(false)
        }
        this.scopedPriceBook()
          .megaport(
            this.service.locationId,
            this.service.portSpeed,
            this.service.term,
            this.service.buyoutPort,
            this.service.provisioningStatus !== this.G_PROVISIONING_DESIGN ? this.service.productUid : undefined,
            true, // time-being crossConnectRequested is always true
          )
          .then(priceObj => {
            this.setPrice(priceObj)
          })
          .catch(() => this.handleFetchPriceError())
      } else if (this.service.productType === this.G_PRODUCT_TYPE_MVE) {
        this.scopedPriceBook()
          .mve(
            this.service.locationId,
            this.service.vendorConfig._vendor,
            this.service.vendorConfig.productSize,
            this.service.productUid,
            this.service.term,
          )
          .then(priceObj => {
            this.setPrice(priceObj)
          })
          .catch(() => this.handleFetchPriceError())
      } else if (this.service.productType === this.G_PRODUCT_TYPE_VXC) {
        if (!this.service.aEnd.locationId || !this.service.bEnd.locationId) return

        // we don't want to display the price if we're the bEnd party - no bEnd charges for the service
        if (this.service.aEnd.ownerUid !== this.service.bEnd.ownerUid && this.service.bEnd.ownerUid === this.companyUid)
          return this.setPrice({
            bEndOwner: true,
          })

        const buyoutPort = this.findPort(this.service.aEnd.productUid).buyoutPort
        const aEndPort = this.findPort(this.service.aEnd.productUid)
        let speed = null
        if (!this.service.rateLimit) {
          const bEndPort = this.findPort(this.service.bEnd.productUid)
          speed = Math.min(aEndPort.portSpeed || aEndPort.speed, bEndPort.portSpeed || bEndPort.speed)
        }

        if (!this.service.rateLimit && !speed) return this.setPrice({})

        this.scopedPriceBook()
          .vxc(
            this.service.aEnd.locationId,
            this.service.bEnd.locationId,
            this.service.rateLimit || speed,
            this.service.term,
            aEndPort.productType,
            this.service.connectType,
            buyoutPort,
            this.service.provisioningStatus !== this.G_PROVISIONING_DESIGN ? this.service.productUid : undefined,
          )
          .then(priceObj => {
            this.setPrice(priceObj)
          })
          .catch(() => this.handleFetchPriceError())
      } else if (this.service.productType === this.G_PRODUCT_TYPE_IX) {
        if (!this.service.networkServiceType || !this.service.locationId) return
        this.scopedPriceBook()
          .ix(
            this.service.networkServiceType,
            this.service.locationId,
            this.service.rateLimit,
            // this.service.term, // Uncomment when term functionality has been extended to IXs
            this.service.buyoutPort,
            this.service.provisioningStatus !== this.G_PROVISIONING_DESIGN ? this.service.productUid : undefined,
          )
          .then(priceObj => this.setPrice(priceObj))
          .catch(() => this.handleFetchPriceError())
      }
    },
    setPrice(priceObj) {
      // If the pricebook request canceled skip processing
      if (priceObj == null) return

      this.price = priceObj
      this.$emit('price', this.price)
      this.customPrice = false
    },
    handleFetchPriceError() {
      // Error handled by the pricebook module.
      this.customPrice = true
    },
  },
}
</script>

<style lang="scss" scoped>
.pricebook-sidebar {
  display: grid;
  margin-right: 4px;
}

:deep(.disabled-price.el-input.is-disabled .el-input__inner) {
  color: var(--color-text-regular);
  -webkit-text-fill-color: var(--color-text-regular);
  text-align: right;
}

.skeleton {
  width: 175px;

  :deep(.el-skeleton__item) {
    padding-block: 0.8rem;
    width: 101%;
  }
}
</style>
