<template>
  <div
    data-testid="missing-tech-support-details"
    class="content">
    <h4>{{ $t('checkout.set-up-tech-support-details') }}</h4>
    <p>{{ $t('checkout.need-tech-support-details') }}</p>
    <p>{{ $t('checkout.need-tech-support-details-by-admin') }}</p>
    <el-button
      class="full-width white-space-normal"
      data-testid="missing-tech-support-details-button"
      type="primary"
      @click="$router.push('/settings/company/support', () => {})">
      {{ $t('checkout.capture-tech-support-details') }}
    </el-button>
    <hr class="form-hr" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MissingTechSupportDetails',
})
</script>

<style lang="scss" scoped>
.content {
  h4 {
    text-align: center;
    background-color: var(--color-info-light-9);
    padding: 10px 0;
  }
}
</style>
