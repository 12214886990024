import cidrRegex, { v4, v6 } from 'cidr-regex'
import { array, boolean, enum as zodEnum, nullable, number, object, optional, record, string, tuple, any } from 'zod'
import type { ZodRawShape } from 'zod'

//
// Generic Schemas
//
export const apiResponse = <T extends ZodRawShape>(shape: T) => object({ data: object(shape) })
export const apiResponseArray = <T extends ZodRawShape>(shape: T) => object({ data: array(object(shape)) })
export const apiMessage = object({ message: string() })

// Classless Inter-Domain Routing (CIDR) IP Address Definitions
export const ipAddressCIDR = string().refine(value => cidrRegex({ exact: true }).test(value))
export const ip4AddressCIDR = string().refine(value => v4({ exact: true }).test(value))
export const ip6AddressCIDR = string().refine(value => v6({ exact: true }).test(value))

export const vlan = nullable(number().min(0).max(4095))
export const booleanAsNumber = number().min(0).max(1)

// Enums
export const accountTypeEnum = zodEnum(['DIRECT_CUSTOMER', 'PARTNER', 'MANAGED_ACCOUNT', 'ADMIN'])
export const diversityZoneEnum = zodEnum(['red', 'blue', 'pair'])
// Despite MCR 1 no longer existing on production, there are still certain endpoints (e.g. telemetryDataTypes) returning it.
export const productTypeEnum = zodEnum(['MEGAPORT', 'MCR', 'MCR2', 'MVE', 'VXC', 'CXC', 'IX'])
// This is what is returned from the locations endpoint (netAuto API)
export const vendorEnum = zodEnum([
  '6WIND',
  'Aruba',
  'Aviatrix',
  'Cisco',
  'Fortinet',
  'Meraki',
  'Palo Alto',
  'VMware',
  'Versa',
])
// This is what is returned from the products endpoint (megalith)
export const vendorEnumUpperCase = zodEnum([
  '6WIND',
  'ARUBA',
  'AVIATRIX',
  'CISCO',
  'FORTINET',
  'MERAKI',
  'PALO_ALTO',
  'VERSA',
  'VMWARE',
])
export const provisioningStatusEnum = zodEnum([
  'LOADING',
  'DESIGN',
  'DESIGN_DEPLOY',
  'NEW',
  'CONFIGURED',
  'DEPLOYABLE',
  'LIVE',
  'CANCELLED',
  'CANCELLED_PARENT',
  'DECOMMISSIONED',
  'PENDING_INTERNAL',
  'PENDING_EXTERNAL',
  'FAILED',
])

export const ensEventStateEnum = zodEnum(['Scheduled', 'Running', 'Completed', 'Cancelled', 'Ongoing', 'Resolved'])

//
// Shared endpoint schemas
//
export const credentialsSchema = apiResponse({
  username: string(),
  firstName: string(),
  lastName: string(),
  session: string(), // Usually is a UUID but not always
  personId: number().positive(),
  personUid: string().uuid(),
  supportOverride: boolean(),
  featureFlags: optional(array(string())),
  email: string(), // For now skip validating as email due to Zod bug. TBD if we just skip email validation in the long term
  companyName: string(),
  companyId: number().positive(),
  companyUid: string().uuid(),
  oAuthToken: optional(
    object({
      idToken: optional(string()),
      accessToken: string(),
    }),
  ),
  source: optional(string()),
  authorities: record(string().uuid(), record(boolean())),
  token: string(), // Usually is a UUID but not always
  fullName: string(),
  requireTotp: boolean(),
  rootAccountType: optional(accountTypeEnum),
  accountType: accountTypeEnum,
  companyConfiguration: object({
    consolidatedSettings: array(
      object({
        key: string(),
        value: boolean(),
      }),
    ),
  }),
  t: number(),
  mfaEnabled: boolean(),
  companyEnforcedMFA: boolean(),
  mfaReset: boolean(),
  companyHasOrderedServices: optional(boolean()),
})

export const enabledMarketsSchema = apiResponseArray({
  id: number().positive(),
})

export const federateSchema = apiResponse({
  status: string(),
  hasExternalProviderIdentity: optional(boolean()),
  idp: optional(
    object({
      name: string(),
      id: number().positive(),
    }),
  ),
})

export const ixPeersSchema = array(
  object({
    serviceUid: string(), // Not actually a UUID, just the first part of one
    asn: number().positive(),
    companyName: string(),
    volume: object({
      in: number().nonnegative(),
      out: number().nonnegative(),
    }),
    peerAddresses: tuple([object({ address: ip4AddressCIDR }), object({ address: ip6AddressCIDR })]),
  }),
)

export const ixTypesSchema = apiResponseArray({
  name: string(),
  description: optional(string()),
  ecix: boolean(),
  group_metro: string(),
})

export const locationsRttSchema = apiResponseArray({
  srcLocation: number(),
  dstLocation: number(),
  medianRTT: number(),
})

export const marketsListSchema = apiResponseArray({
  id: number().positive(),
  country: string(),
  market: string(),
  availableCurrencies: array(string()),
  availableInvoiceLanguages: array(string()),
  marketCurrency: string(),
})

export const locationsListSchema = apiResponseArray({
  id: number().positive(),
  name: string(),
  metro: string(),
  country: string(),
  networkRegion: string(),
  address: object({
    street: string(),
    suburb: string(),
    city: string(),
    state: string(),
    country: string(),
  }).partial(),
  dc: object({
    id: number().positive(),
    name: string(),
  }),
  latitude: number(),
  longitude: number(),
  market: string(),
  status: string(),
  diversityZones: object({
    megaport: array(
      object({
        name: nullable(diversityZoneEnum),
        speed: number().positive(),
      }),
    ),
    mcr2: array(
      object({
        name: diversityZoneEnum,
        maxAvailableBandwidth: number().positive(),
      }),
    ),
    mve: array(
      object({
        name: diversityZoneEnum,
        maxAvailableCpuCores: number().positive(),
      }),
    ),
  }).partial(),
  products: object({
    megaport: nullable(array(number().positive())),
    mve: array(
      object({
        sizes: array(string()),
        details: optional(
          array(
            object({
              size: string(),
              label: string(),
              cpuCoreCount: number().positive(),
              ramGB: number().positive(),
            }),
          ),
        ),
        version: string(),
        product: string(),
        vendor: vendorEnum,
        vendorDescription: optional(string()),
        id: number().positive(),
        releaseImage: boolean(),
      }),
    ),
    mcr2: array(number().positive()),
  }).partial(),
})

export const partnerPortsListSchema = apiResponseArray({
  connectType: string(),
  productUid: string().uuid(),
  vxcPermitted: boolean(),
  companyUid: string().uuid(),
  companyName: string(),
  title: string(),
  locationId: number().positive(),
  speed: nullable(number().positive()),
  rank: number().positive(),
  lag_id: nullable(number().positive()),
  lag_primary: boolean(),
  aggregation_id: nullable(number().positive()),
  diversityZone: nullable(diversityZoneEnum),
  maxVxcSpeed: optional(nullable(number().positive())),
})

const locationDetail = object({
  name: string(),
  city: string(),
  country: string(),
  metro: string(),
})

// Schema to be used by the A-End and B-End of an associated VXC
const vxcEndObjectSchema = object({
  innerVlan: vlan,
  location: string(),
  locationDetail,
  locationId: number().positive(),
  ownerUid: string().uuid(),
  productName: string(),
  productUid: string().uuid(),
  vNicIndex: optional(number().min(0)), // We (apparently) support up to 5 vNICs
  vlan,
  diversityZone: nullable(diversityZoneEnum), // Only used for third party connections
})

// Schema defined here to simplify the productsSchema as service.associatedVxcs[n].csp_connection
// is sometimes returned as an object and sometimes as an array of equally formatted objects
const cspConnectionObjectSchema = object({
  amazonAsn: optional(number().nonnegative()),
  amazonIpAddress: optional(ipAddressCIDR),
  amazon_address: optional(ipAddressCIDR),
  asn: optional(number().nonnegative()),
  authKey: optional(string().min(6)),
  auth_key: optional(string().min(6)),
  bandwidths: optional(array(number().positive())),
  // An optional object containing any number of keys not restricted by names and of numeric value
  bgp_status: optional(record(number())),
  connectType: optional(string()),
  customerIpAddress: optional(ipAddressCIDR),
  customer_address: optional(ipAddressCIDR),
  customer_ip4_address: optional(ip4AddressCIDR),
  customer_ip6_address: optional(ip6AddressCIDR),
  ipv4_gateway_address: optional(string().ip({ version: 'v4' })),
  ipv6_gateway_address: optional(string().ip({ version: 'v6' })),
  interfaces: optional(
    array(
      optional(
        object({
          bgpConnections: optional(
            array(
              object({
                bfdEnabled: boolean(),
                description: nullable(string()),
                localIpAddress: string().ip(),
                peerAsn: number().nonnegative(),
                peerIpAddress: string().ip(),
                shutdown: boolean(),
              }),
            ),
          ),
          ipAddresses: optional(array(ipAddressCIDR)),
          vlan: optional(nullable(number().positive())),
        }),
      ),
    ),
  ),
  name: optional(string().max(100)),
  ownerAccount: optional(string().regex(/^[0-9]{12}$/)),
  peerAsn: optional(number().nonnegative()),
  resource_name: string(),
  type: optional(string()),
  vif_id: optional(string()),
  virtualRouterName: optional(string()),
  csp_name: optional(string()),
})

export const productBandwidthsSchema = apiResponse({
  bandwidths: array(number().nonnegative()),
})

export const productsSchema = apiResponseArray({
  adminLocked: boolean(),
  aggregationId: nullable(number().positive()),
  locationDetail,
  associatedIxs: array(
    object({
      adminLocked: boolean(),
      asn: number().nonnegative(),
      contractTermMonths: number().positive(),
      costCentre: optional(string()),
      createDate: number().nonnegative(),
      createdBy: nullable(string().uuid()),
      ecix: boolean(),
      ixPeerMacro: nullable(string()),
      locationId: number().positive(),
      locked: boolean(),
      macAddress: string(),
      networkServiceType: string(),
      productName: string(),
      productType: productTypeEnum,
      productUid: string().uuid(),
      provisioningStatus: provisioningStatusEnum,
      publicGraph: boolean(),
      rateLimit: number().nonnegative(),
      // This should always be required however there are some cases where BE is not returning
      // this object so making this optional to suppress the sentry errors until a fix comes
      resources: optional(
        object({
          interface: object({
            demarcation: string(),
            id: optional(number().positive()),
            media: string(),
            up: optional(booleanAsNumber), // Rarely returns as undefined, depends on env, check with BE at a later date
          }),
          bgp_connection: array(
            object({
              asn: number().nonnegative(),
              customer_asn: number().nonnegative(),
              customer_ip_address: ipAddressCIDR,
              id: optional(number().positive()),
              isp_asn: number().nonnegative(),
              isp_ip_address: string().ip(),
              password: optional(string()),
              resource_name: string(),
              up: optional(booleanAsNumber), // Rarely returns as undefined, depends on env, check with BE at a later date
            }),
          ),
          ip_address: array(
            object({
              address: ipAddressCIDR,
              id: optional(number().positive()),
              resource_name: string(),
            }),
          ),
          vpls_interface: object({
            id: optional(number().positive()),
            mac_address: string(),
            rate_limit_mbps: optional(number().positive()),
            shutdown: boolean(),
          }),
        }),
      ),
      secondaryName: optional(nullable(string())),
      term: nullable(number()),
      up: nullable(boolean()),
      vlan,
    }),
  ),
  associatedVxcs: array(
    object({
      aEnd: vxcEndObjectSchema,
      adminLocked: boolean(),
      bEnd: vxcEndObjectSchema,
      contractTermMonths: number().positive(),
      costCentre: optional(string()),
      createDate: number().nonnegative(),
      createdBy: string().uuid(),
      locked: boolean(),
      productId: optional(number()),
      productName: string(),
      productType: productTypeEnum,
      productUid: string().uuid(),
      provisioningStatus: provisioningStatusEnum,
      // API: Rate Limit is returned by the vast majority of VXC's, but there are some very old ones that don't have it
      // Eventually BE will clean up the old data, but until then we will allow null to suppress sentry errors
      rateLimit: nullable(number().nonnegative()),
      // API: This should always be required however there are some cases where BE is not returning
      // this object so making this optional to suppress the sentry errors until a fix comes
      resources: optional(
        object({
          csp_connection: optional(array(cspConnectionObjectSchema).or(cspConnectionObjectSchema)),
          interface: optional(
            array(
              object({
                demarcation: string(),
                id: number().positive(),
                media: string(),
                up: optional(booleanAsNumber), // Rarely returns as undefined, depends on env, check with BE at a later date
              }),
            ),
          ),
          virtual_router: optional(
            object({
              bgpShutdownDefault: boolean(),
              id: optional(number().positive()),
              mcrAsn: number().nonnegative(),
            }),
          ),
          vll: object({
            rate_limit_mbps: optional(number().positive()),
            up: optional(booleanAsNumber), // Rarely returns as undefined, depends on env, check with BE at a later date
            shutdown: boolean(),
          }),
        }),
      ),
      secondaryName: optional(nullable(string())),
      up: nullable(boolean()),
      vxcApproval: object({
        message: nullable(string()),
        status: nullable(string()),
        type: nullable(string()),
      }),
    }),
  ),
  buyoutPort: boolean(),
  companyName: string(),
  companyUid: string().uuid(),
  contractEndDate: nullable(number().nonnegative()),
  contractTermMonths: number().positive(),
  crossConnectRequested: optional(boolean()),
  costCentre: optional(string()),
  createDate: number().nonnegative(),
  createdBy: string().uuid(),
  dealUid: optional(string()),
  diversityZone: optional(nullable(diversityZoneEnum)),
  lagId: nullable(number().positive()),
  lagPrimary: boolean(),
  locationId: number().positive(),
  locked: boolean(),
  market: string(),
  maxVxcSpeed: optional(nullable(number().positive())),
  marketplaceVisibility: boolean(),
  mveLabel: optional(string()),
  mveSize: optional(string()),
  portSpeed: nullable(number().positive()),
  productId: optional(number()),
  productName: string(),
  productType: productTypeEnum,
  productUid: string().uuid(),
  provisioningStatus: provisioningStatusEnum,
  resources: optional(
    object({
      interface: optional(
        object({
          demarcation: string(),
          id: optional(number().positive()),
          media: string(),
          up: optional(booleanAsNumber), // Rarely returns as undefined, depends on env, check with BE at a later date. Used incorrectly in the FE since this only pertains to MEGAPORTs.
        }),
      ),
      virtual_machine: optional(
        array(
          object({
            // API: This will always be a single-item array so a request has been made to the BE to change this into an object
            up: boolean(), // API: This will need to be removed when Mitch moves the up field to the top level for it to be used by all service types
            vnics: array(
              object({
                description: optional(string()),
                vlan,
              }),
            ),
          }),
        ),
      ),
      virtual_router: optional(
        object({
          bgpShutdownDefault: boolean(),
          id: optional(number().positive()),
          mcrAsn: number().nonnegative(),
        }),
      ),
    }),
  ),
  secondaryName: nullable(string()),
  terminateDate: nullable(number().nonnegative()),
  // usageAlgorithm // Check if we can remove the bit from the Portal that checks this
  up: nullable(boolean()),
  vendor: optional(vendorEnumUpperCase),
  virtual: boolean(),
  vnics: optional(
    array(
      object({
        description: optional(string()),
        vlan,
      }),
    ),
  ),
  vxcAutoApproval: boolean(),
  vxcPermitted: boolean(),
})

export const promoCodeSchema = apiResponse({
  code: string(),
  description: string(),
  productTypes: array(productTypeEnum),
})

export const supplierTaxRulesSchema = apiResponse({
  taxFieldRelations: array(
    object({
      id: string(),
      taxRegimeList: array(
        object({
          id: string(),
          rfcSpecification: object({
            pattern: string().optional(),
            defaultValue: string().optional(),
          }),
          satPurposeList: array(string()),
        }),
      ),
    }),
  ).optional(),
  companyTypeList: array(
    object({
      id: string(),
      name: string(),
    }),
  ).optional(),
  taxRegimeList: array(
    object({
      id: string(),
      name: string(),
    }),
  ).optional(),
  satPurposeList: array(
    object({
      id: string(),
      name: string(),
    }),
  ).optional(),
})

export const telemetryDataTypesSchema = array(
  object({
    productType: productTypeEnum,
    metrics: array(
      object({
        name: string(),
        displayName: string(),
        subtypes: array(string()),
        outputUnit: object({
          name: string(),
        }),
      }),
    ),
  }),
)

export const entitlementsSchema = apiResponse({
  entitlements: array(
    object({
      companyId: number().positive(),
      productId: nullable(number()),
      uid: string().uuid(),
      vendorAgreementId: string(),
      vendorCustomerId: string(),
      sku: string(),
      entitlementStartDate: number().nonnegative(),
      entitlementEndDate: number().nonnegative(),
      productType: productTypeEnum,
      status: zodEnum(['EXPIRED', 'IN_USE', 'AVAILABLE']),
    }),
  ),
})

// TODO: This object contains the saved MCR B-End configuration details.
// This is that step in the create connection flow that handles setting up IP addresses and BGP connections
// and consists entirely of mega-ui components. The shape of this object will need to be explicitly defined.
export const mcrConnectionDetails = any()
